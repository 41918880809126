import { useContext, useMemo } from 'react';
import { InfiniteData, useInfiniteQuery, UseInfiniteQueryOptions } from '@tanstack/react-query';

import FormattingUtils from '../../../ui-components/utils/FormattingUtils';
import { BrokerContactFilters, EmployerContactFilters } from 'common.model/src/types/common/search/filters/ContactFilters';
import { ISimpleContact } from 'common.model/src/types/common/contact/ISimpleContact';
import { ContactSearchResponse } from 'common.model/src/types/common/contact/ContactSearchResponse';
import { APIContext } from 'new-beginning/services/api/Api.context';

export const useContactsQuery = ({
	tab,
	filters,
	options,
}:
	| {
			tab: 'broker';
			filters: BrokerContactFilters;
			options: UseInfiniteQueryOptions<PagedContactSearchResponse, unknown, ContactsDTO>;
	  }
	| {
			tab: 'employer';
			filters: EmployerContactFilters;
			options: UseInfiniteQueryOptions<PagedContactSearchResponse, unknown, ContactsDTO>;
	  }) => {
	const { brokerContactApi, employerApi } = useContext(APIContext);
	const queryFn = useMemo(() => {
		if (tab === 'broker') return brokerContactApi.useSearch.bind(brokerContactApi);
		if (tab === 'employer') return employerApi.useSearchContacts.bind(employerApi);
	}, [tab, employerApi, brokerContactApi]);
	const query = useInfiniteQuery<ContactSearchResponse, unknown, ContactsDTO>(
		['broker-contacts', filters],
		async (params) =>
			queryFn({
				...filters,
				page: params.pageParam ?? 0,
			}),
		{ ...options, select },
	);

	return query;
};

export const select = (data: InfiniteData<PagedContactSearchResponse>): InfiniteData<ContactsDTO> => {
	const pages = data.pages.map((data) => {
		const purchasedContacts = new Map<string, ISimpleContact>(data.selectedContacts.map((contact) => [contact.profile_url, contact]));
		const brokerContacts: ContactDTO[] = data.anonymizedContacts.map(
			({
				employee_profile_url,
				profile_pic,
				employee_name,
				has_email,
				has_mobile_phone_1,
				experience_title,
				company_master_id,
				company_name,
				company_profile_url,
				broker_office_locations,
				total_months_at_role,
				total_months_at_company,
				broker_contact_carrier_appointments,
				broker_contact_licenses,
				employee_city,
				employee_state_code,
			}) => {
				const appointments = (() => {
					if (!broker_contact_carrier_appointments || broker_contact_carrier_appointments.length < 1) return [];
					const appointmentsMap = new Map<string, number>();
					broker_contact_carrier_appointments.forEach(({ company_name, first_appointment_date }) => {
						const companyName = FormattingUtils.formatUpperCasing(company_name);
						const year = new Date(first_appointment_date).getFullYear();
						const previous = appointmentsMap.get(companyName);
						if (!previous) appointmentsMap.set(companyName, year);
						else if (year < previous) appointmentsMap.set(companyName, year);
					});
					const appointments: [string, string][] = [];
					appointmentsMap.forEach((year, companyName) => {
						appointments.push([companyName, year.toString()]);
					});
					appointments.sort(([a], [b]) => a.localeCompare(b));

					return appointments.map(([carrier, since]) => ({ carrier, since }));
				})();
				const licenses = (() => {
					const lines = ['Health', 'Life', 'Property', 'Casualty'];
					if (!broker_contact_licenses || broker_contact_licenses.length < 1) return [];
					const rowsMap = new Map<string, { name: string; since?: string }>([
						['Life', { name: 'Life' }],
						['Health', { name: 'Health' }],
						['Property', { name: 'Property' }],
						['Casualty', { name: 'Casualty' }],
					]);
					broker_contact_licenses.forEach(({ line, first_active_date }) => {
						lines.forEach((lineName) => {
							if (line?.includes(lineName)) {
								const currentRow = rowsMap.get(lineName);
								rowsMap.set(lineName, {
									name: lineName,
									since: (() => {
										const since = currentRow.since && currentRow.since < first_active_date.split('-')[0] ? currentRow.since : first_active_date.split('-')[0];
										return since;
									})(),
								});
							}
						});
					});

					return lines.map((line) => rowsMap.get(line));
				})();

				return {
					id: employee_profile_url,
					imageSrc: profile_pic,

					name: employee_name,
					linkedinUrl: employee_profile_url,
					location: (() => {
						if (!employee_city || !employee_state_code) return;
						return `${employee_city}, ${employee_state_code}`;
					})(),
					licenses,
					isVerified: licenses.some(({ since }) => since),
					brokerId: company_master_id,
					brokerName: FormattingUtils.formatUpperCasing(company_name),
					brokerLinkedinUrl: company_profile_url,
					brokerLocation: (() => {
						if (!broker_office_locations?.length) return;
						const { broker_office_city, broker_office_state_code } = broker_office_locations[0];
						if (!broker_office_city || !broker_office_state_code) return;
						return `${broker_office_city}, ${broker_office_state_code}`.toLowerCase();
					})(),
					timeWorkingInRole: getDurationLabel({ months: total_months_at_role, sufix: 'in role' }),
					timeWorkingAtCompany: getDurationLabel({ months: total_months_at_company, sufix: 'at company' }),
					title: experience_title,
					appointments,

					phone: purchasedContacts.get(employee_profile_url)?.mobile_phone_1 ?? undefined,
					email: purchasedContacts.get(employee_profile_url)?.work_email ?? undefined,
					hasEmail: has_email,
					hasPhone: has_mobile_phone_1,
				};
			},
		);

		return { data: brokerContacts, page: data.page, next: data.next, previous: data.previous };
	});

	return {
		...data,
		pages,
	};
};

export interface ContactsDTO {
	data: ContactDTO[];
	next: number | null;
	previous: number | null;
	page: number;
}

export interface ContactDTO {
	id: string;
	imageSrc: string;
	name: string;
	linkedinUrl: string;
	location: string;
	title: string;

	isVerified: boolean;

	timeWorkingInRole: {
		value: string;
		label: string;
	};
	timeWorkingAtCompany: {
		value: string;
		label: string;
	};
	brokerName: string;
	brokerId: string;
	brokerLocation: string;
	brokerLinkedinUrl: string;

	email: string;
	phone: string;
	hasEmail: boolean;
	hasPhone: boolean;

	appointments: {
		carrier: string;
		since: string;
	}[];
	licenses: {
		name: string;
		since?: string;
	}[];
}

export interface PagedContactSearchResponse extends ContactSearchResponse {
	page: number;
	next: number | null;
	previous: number | null;
}

const getDurationLabel = ({
	sufix,
	months,
}: {
	/**
	 * Number of months
	 */
	months?: number | string;
	/**
	 * Sufix to be added to the label
	 * @example 'at Company'
	 */
	sufix: string;
}) => {
	const value = Number(months);
	if (!value) return;
	if (value < 12) return { value: value.toString(), label: `Mo. ${sufix}` };
	if (value < 23) return { value: (1).toString(), label: `Year ${sufix}` };
	return { value: Math.floor(value / 12).toString(), label: `Yrs. ${sufix}` };
};
