import FormattingUtils from '../../../../../../../../ui-components/utils/FormattingUtils';
import { IEmployerSearch } from 'common.model/src/types/services/employer-service/IEmployerSearch';

export const employerSummaryApiAdapter = (
	data: Partial<IEmployerSearch>,
): {
	name?: string;
	location?: string;
	address?: string;
	linkedinUrl?: string;
	websiteUrl?: string;
	description?: string;
	fundingType?: string;
	imageSrcs?: string[];
	totalEmployees?: string;
	broker?: string;
	numberOfContacts?: string;
	renewalDate?: string;
	benefitsRating?: string;
} => ({
	name: data?.employer_master_name && FormattingUtils.formatUpperCasing(data.employer_master_name),
	address: (() => {
		if (data?.employer_state && data?.employer_city) return `${FormattingUtils.formatUpperCasing(data?.employer_city)}, ${data?.employer_state}`;
	})(),
	linkedinUrl: data?.employer_linkedin_url,
	websiteUrl: data?.employer_website,
	description: data?.employer_naics_description,
	fundingType: data?.funding_type,
	imageSrcs: (() => {
		const stage = import.meta.env.VITE_STAGE?.trim() === 'prod' ? 'prod' : 'qa';
		const srcs: string[] = [];
		if (data?.logo_image_s3_path) srcs.push(`https://benefit-flow-data-linkedin-logo-image-${stage}.s3.amazonaws.com/${data.logo_image_s3_path}`);
		if (data?.employer_website) srcs.push(`https://logo.clearbit.com/${data.employer_website}`);

		return srcs.length ? srcs : undefined;
	})(),
	totalEmployees: data?.total_employees && (FormattingUtils.formatNumberAbbrev(data.total_employees) as string),
	broker: (() => {
		if (data?.primary_broker && data?.primary_broker_closest_office_city && data?.primary_broker_closest_office_state)
			return `${FormattingUtils.formatUpperCasing(data.primary_broker)} (${FormattingUtils.formatUpperCasing(data.primary_broker_closest_office_city)}, ${
				data.primary_broker_closest_office_state
			})`;
	})(),
	numberOfContacts: undefined,
	renewalDate: data?.renewal_date ? FormattingUtils.formatMM_DD_YYYYtoMM_DD(data.renewal_date) : undefined,
	benefitsRating: undefined,
});
