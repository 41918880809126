import { useContext, useMemo } from 'react';
import { APIContext } from 'new-beginning/services/api/Api.context';
// import { Policy } from 'common.model/src/types/services/broker-service/BrokerService';
import EmployerDataUtils from 'app/containers/EmployerPage/EmployerDataUtils';
import { ListUtils } from 'ui-components';

export const usePolicies = ({ employerMasterId, selectedFilingYear }: { employerMasterId: string, selectedFilingYear: number }) => {
	const { brokerApi } = useContext(APIContext);

    const { data, loading } = brokerApi.useGetEmployerPolicies({
        employer_master_id: employerMasterId,
        selected_filing_year: selectedFilingYear
    }, {
        enabled: !!selectedFilingYear
    });

    const brokerPolicies = EmployerDataUtils.getBrokerPolicies(data ?? []);
  
    const brokerNameToColor = EmployerDataUtils.getColorMapping(
      brokerPolicies,
      x => x.brokerName,
      x => x.brokerCommission,
    );
  
    const carrierNameToColor = EmployerDataUtils.getColorMapping(
      ListUtils.distinctBy(brokerPolicies, x => x.carrierUniqueKey),
      x => x.carrierName,
      x => x.carrierPremium,
    );


	const options = useMemo<{ 
        data: any, 
        loading: boolean,
        brokerNameToColor: any,
        carrierNameToColor: any
    }>(
		() => ({ 
            data: brokerPolicies, 
            loading: loading,
            brokerNameToColor,
            carrierNameToColor
        }),
		[data],
	);

	return options;
};
