import { useState } from 'react';
import { AutoComplete } from '@benefitflow/designsystem';

import { useOnSearch, useOnSelect, useOptions } from './hooks';
import { mask } from './logic/mask';

export const NumericAutoComplete = ({
	setValue,
	value,
	defaultOptions,
}: {
	value: number | string;
	setValue: (value: number | string) => void;
	defaultOptions: { value: number | string; label: string }[];
}) => {
	const [search, setSearch] = useState('');
	const onSearch = useOnSearch({ search, setSearch });
	const options = useOptions({ search, defaultOptions });
	const onSelect = useOnSelect({ setValue });

	return (
		<AutoComplete
			options={options.map((option) => ({ ...option, data: option, value: String(option.value) }))}
			onSearch={onSearch}
			onSelect={onSelect}
			mode="single"
			mask={mask}
			selectedValues={[String(value)]}
		/>
	);
};
