import { useState } from 'react';
import serviceUrl from 'utils/serviceUrl';
import axios from 'axios';
import { useNotificationContext, NotificationType } from 'new-beginning/context/NotificationContext';
import { ContactListFilter } from 'common.model/src/db/model-decorators/type-extensions';
import { useBasicApiRequestState, BasicApiRquestState, BaseApiReponse } from '../useBasicApiRequestState';
import { delayResolve } from 'new-beginning/utilities';

interface ItemRefresh {
	netNewContacts: [];
	existingContacts: [];
	listItemsMissingMatchingCriteria: [];
}

export interface ListRefreshResponse extends BaseApiReponse {
	refreshedItems: ItemRefresh;
	generateRefreshItems: () => void;
}

type CallbackFunction = () => void;

const listRefreshUrl = `${serviceUrl}/crm-integration/refreshListItems`;
export const useRefreshListItems = (listId: string, callback: CallbackFunction = () => null): ListRefreshResponse => {
	const {
		baseHeaders,
		loading,
		tokenLoading,
		setLoading,
		requestApiSuccess,
		setApiRequestSuccess,
		requestErrorMessage,
		setRequestErrorMessage,
	}: BasicApiRquestState = useBasicApiRequestState();
	
	const [listItemRefresh, setListItemRefresh] = useState<ItemRefresh | null>(null);
	const { setNotification, clearNotification } = useNotificationContext();

	const generateRefreshItems = (): Promise<void> => {
		if (loading) {
			return null;
		}

		// NOTE: Partial Refresh is Disabled and may be Deprecated fully
		// 			 --> Setting values for listItemIds or contactFilter will allow for Partial Refreshes
		const listItemIds = [];
		const contactFilter = ContactListFilter.ALL;

		setListItemRefresh(null);
		setLoading(true);
		clearNotification();
		return axios
			.post(`${listRefreshUrl}/${listId}`, { listId, contactFilter, listItemIds }, { headers: { ...baseHeaders } })
			.then(async (res) => {
				const response = res?.data?.res;
				const refreshError = response?.success === false && response?.message;
				if (refreshError && response?.statusCode === 500) {
					await delayResolve(12500);
				} else if (refreshError) {
					throw new Error(response.message);
				}

				setListItemRefresh(response?.data || null);
				setNotification('Your list has been fully refreshed with data from your CRM.', NotificationType.INFO);
				setApiRequestSuccess(true);
			})
			.catch((err: Error) => {
				setNotification(err.message, NotificationType.ERROR);
				setRequestErrorMessage(err.message);
				setApiRequestSuccess(false);
			})
			.finally(() => {
				callback();
				setLoading(false);
			});
	};

	return {
		generateRefreshItems,
		refreshedItems: listItemRefresh,
		loading,
		requestApiSuccess,
		requestErrorMessage,
	};
};
