import { useParams } from 'react-router-dom';
import { useCallback, useContext } from 'react';
import { addNotification } from '@benefitflow/designsystem';

import { useContactsListStore } from '../../../../../ContactsList.store';
import { ContactsListContext } from '../../../../../ContactsList.context';
import { Notifications } from '../../../../../../../../constants/notifications';
import { getApiPayloadFromState } from '../../../../../../Search/BrokerContacts/components/List/components/Header/helpers/getApiPayloadFromState';
import { APIContext } from 'new-beginning/services/api/Api.context';
import { BrokerContactFilters } from 'common.model/src/types/common/search/filters/ContactFilters';
import { BulkGetContactResponse } from 'common.model/src/types/common/contact/BulkGetContactResponse';

export const useGetSelected = () => {
	const { listsApi, brokerContactApi } = useContext(APIContext);
	const { listId } = useParams();
	const { filters } = useContext(ContactsListContext);
	const { addPurchasedContact } = useContactsListStore();
	const onError = () => addNotification(Notifications.ContactPurchase.Error);
	const options = {
		onSuccess: (data: BulkGetContactResponse) => {
			addPurchasedContact(...data.res.map(({ mobile_phone_1, profile_url, work_email }) => ({ email: work_email, phone: mobile_phone_1, id: profile_url })));
			addNotification(data?.consumption ? Notifications.ContactPurchase.Success(data.consumption) : Notifications.ContactPurchase.NoCharge);
		},
		onError,
	};
	const { checkMode, checkedMap, tab } = useContactsListStore();
	let outerLoading = false;
	let outerMutate: () => void;

	if (listId) {
		const { mutate, loading } = listsApi.useRevealSelectedListItems(
			{
				...getApiPayloadFromState({ checkedMap, checkMode }),
				filters: filters as BrokerContactFilters, // TODO: We should fix this on the backend to be able to support EmployerContactFilters as well!
			},
			options,
		);
		outerLoading = loading;
		outerMutate = mutate;
	} else if (tab === 'broker') {
		const { mutate, loading } = brokerContactApi.useBulkSelectAllGetContact(
			{
				...getApiPayloadFromState({ checkedMap, checkMode }),
				filters: filters as BrokerContactFilters, // TODO: We should fix this on the backend to be able to support EmployerContactFilters as well!
			},
			options,
		);
		outerLoading = loading;
		outerMutate = mutate;
	} else if (tab === 'employer') {
		throw new Error('Not implemented');
	} else {
		throw new Error('Invalid tab');
	}

	return { getSelected: outerMutate, loading: outerLoading };
};
