import { Skeleton, Typography } from '@benefitflow/designsystem';

export const Labels = {
	Name: ({ children, loading }: { children: string; loading?: boolean }) => {
		if (loading) return <Skeleton width="xl6" height="md" />;
		return <Typography.Text>{children}</Typography.Text>;
	},
	Title: ({ children, loading }: { children: string; loading?: boolean }) => {
		if (loading) return <Skeleton width="xl2" height="md" />;
		return <Typography.Text color="neutral-400">{children}</Typography.Text>;
	},
};
