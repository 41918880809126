import React, { useContext, useState, useEffect } from 'react';
import { terciaryColor } from 'designTokens/colors';
import { DropDown } from 'atoms/filters/drop/DropDown';
import { useOptions } from './hooks/useOptions';
import { InsurancePoliciesSummaryCard } from 'new-beginning/components/pages/EmployerProfile/Tabs/components/BenefitsTab/components/InsurancePoliciesSummaryCard';
import { BenefitsOfferedTable } from 'new-beginning/components/pages/EmployerProfile/Tabs/components/BenefitsTab/components/BenefitsOfferedTable';
import { ServiceProviderSection } from 'new-beginning/components/pages/EmployerProfile/Tabs/components/BenefitsTab/components/ServiceProviderSection';


export interface BenefitsTabProps {
    employerMasterId: string;
    navigate: (path: string) => void;
}

export const BenefitsTab = (props: BenefitsTabProps) => {
    const options = useOptions({ employerMasterId: props.employerMasterId });
    const [selectedFilingYear, setSelectedFilingYear] = useState<number | null>(null);

    useEffect(() => {
        if (options && options.length > 0) {
            setSelectedFilingYear(parseInt(options[0].option) || null);
        }
    }, [options]);

    return (
        <>
            <div
                style={{
                    position: 'absolute',
                    paddingBottom: '20px',
                    right: 0,
                    color: terciaryColor,
                    width: '100px',
                    top: '-4px',
                    zIndex: 100000000,
                }}
            >
                <DropDown isDeleteable={false} title={'Filing Year'} 
                    values={
                        selectedFilingYear
                            ? [{ value: selectedFilingYear.toString() }]
                            : []
                    }
                    onChange={values => {
                        if (values.length <= 1) {
                            return;
                        }
                        setSelectedFilingYear(values.pop());
                    }}
                    options={options}					
                />
            </div>        
            <InsurancePoliciesSummaryCard
                employerMasterId={props.employerMasterId}
                selectedFilingYear={selectedFilingYear}
            />
            <BenefitsOfferedTable
                employerMasterId={props.employerMasterId}
                selectedFilingYear={selectedFilingYear}
            />
            <ServiceProviderSection
                employerMasterId={props.employerMasterId}
                selectedFilingYear={selectedFilingYear}
                navigate={props.navigate}
            />
        </>
    );
};
