
export interface InsurancePoliciesSummaryCardConfig { 
    className?: any;
    isLoading: boolean;
    brokerColors: { [key: string]: string };
    carrierColors: { [key: string]: string };
    countMedicalPolicies: number;
    countNonMedicalPolicies: number;
    totalBrokerCommissions: number;
    brokerCommissionsMap: { [key: string]: number };
    totalCarrierPremiums: number;
    carrierPremiumsMap: { [key: string]: number };
}

export class InsurancePoliciesSummaryCardConfigModel {

    public static getConfig(data, isLoading, brokerColors, carrierColors) : InsurancePoliciesSummaryCardConfig {
        
        const totalBrokerCommissions = data
            ?.map(x => parseFloat(x.brokerCommission))
            .filter(x => x && !Number.isNaN(x))
            .reduce((a, b) => a + b, 0);

        const totalCarrierPremiums = (() => {
            const grped = data
                ?.reduce((a, b) => {
                    if (!a[b.carrierUniqueKey]) {
                        a[b.carrierUniqueKey] = [];
                    }
                    a[b.carrierUniqueKey].push(b);
                    return a;
                }, {});
            if (!grped) {
                return null;
            }
            return Object.values(grped)
                ?.map(grp => grp[0])
                .map(x => parseFloat(x.carrierPremium))
                .filter(x => x && !Number.isNaN(x))
                .reduce((a, b) => a + b, 0);
        })();

        const brokerCommissionsMap = data
            ?.map(x => ({
                brokerName: x.brokerName,
                brokerCommission: parseFloat(x.brokerCommission),
            }))
            .filter(x => x && !Number.isNaN(x.brokerCommission))
            .reduce((a, b) => {
                if (!a[b.brokerName]) {
                a[b.brokerName] = 0;
                }
                a[b.brokerName] += b.brokerCommission;
                return a;
            }, {});

        const carrierPremiumsMap = (() => {
            const grped = data?.reduce((a, b) => {
                if (!a[b.carrierUniqueKey]) {
                    a[b.carrierUniqueKey] = [];
                }
                a[b.carrierUniqueKey].push(b);
                return a;
            }, {});
            if (!grped) {
                return null;
            }

            const carrierPremiumsPieData = Object.values(grped)
                ?.map(grp => grp[0])
                ?.map(x => ({
                    carrierName: x.carrierName,
                    premium: parseFloat(x.carrierPremium),
                }))
                .filter(x => x.premium && !Number.isNaN(x.premium))
                .reduce((a, b) => {
                    if (!a[b.carrierName]) {
                    a[b.carrierName] = 0;
                    }
                    a[b.carrierName] += b.premium;
                    return a;
                }, {});
            
            return carrierPremiumsPieData;
        })();
        const medicalPolicyKeys = new Set<string>();
        const nonMedicalPolicyKeys = new Set<string>();
        data?.forEach(policy => {
            if (policy.isMedical) {
                medicalPolicyKeys.add(policy.rowUniqueKey);
            } else {
                nonMedicalPolicyKeys.add(policy.rowUniqueKey);
            }
        });

        return {
            isLoading,
            countMedicalPolicies: medicalPolicyKeys.size,
            countNonMedicalPolicies: nonMedicalPolicyKeys.size,
            totalBrokerCommissions,
            brokerCommissionsMap,
            totalCarrierPremiums,
            carrierPremiumsMap,
            brokerColors,
            carrierColors
        };
    }
}
