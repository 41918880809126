import React from 'react';
import { Avatar, Box, Description, Icon, Paper } from '@benefitflow/designsystem';
import { EmployerFilingContactCardProps } from './EmployerFilingContactCard.types';
import { useGetValueByScreenSizes } from 'new-beginning/hooks/useGetValueByScreenSizes';
import { Labels } from './components';

export const EmployerFilingContactCard = ({ filings, imageUrl, linkedinUrl, loading, name, phone }: EmployerFilingContactCardProps) => {
	const getValueByScreenSizes = useGetValueByScreenSizes();

	return (
		<Paper>
			<Box
				display="grid"
				style={{
					gridTemplateColumns: 'repeat(12,1fr)',
				}}
				gap="sm"
			>
				<Box gridSpan={getValueByScreenSizes({ sm: 12, md: 12, lg: 4 })} display="flex" justifyContent="start" alignItems="center" gap="sm">
					<Avatar variant="circle" loading={loading} fallbackIcon="user" src={imageUrl} />
					<Box display="flex" gap="xxs" justifyContent="start" alignItems="center">
						<Labels.Name loading={loading}>{name}</Labels.Name>
						{linkedinUrl && (
							<a href={`https://${linkedinUrl}`} style={{ color: '#007EBB' }} target="_blank" rel="noopener noreferrer">
								<Icon name="linkedin" size="sm" />
							</a>
						)}
					</Box>
				</Box>
				<Box
					gridSpan={getValueByScreenSizes({ sm: 12, md: 6, lg: 4 })}
					display="flex"
					flexDirection="column"
					gap="xs"
					justifyContent="center"
					alignItems="start"
				>
					<Labels.Title loading={loading}>Filings</Labels.Title>
					{(filings ?? [''])?.map((filling) => <Description loading={loading} icon="briefcase" value={filling} />)}
				</Box>
				<Box
					gridSpan={getValueByScreenSizes({ sm: 12, md: 6, lg: 4 })}
					display="flex"
					flexDirection="column"
					gap="xs"
					justifyContent="center"
					alignItems="start"
				>
					<Labels.Title loading={loading}>Contact Info</Labels.Title>
					<Description loading={loading} icon="call" value={phone} />
				</Box>
			</Box>
		</Paper>
	);
};
