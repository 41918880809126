import { Box } from '@benefitflow/designsystem';
import { APIContext } from "new-beginning/services/api/Api.context";
import { useContext, useEffect, useState } from "react";
import Title from 'atoms/Title';
import { DropDown } from 'atoms/filters/drop/DropDown';
import { EmployerFilingContactCard } from "new-beginning/components/pages/EmployerProfile/EmployerFillingContactCard/EmployerFilingContactCard";
import { getFilings } from "../../helpers/formatFilings";
import FormattingUtils from "utils/FormattingUtils";
import PhoneNumber from 'awesome-phonenumber';
import { useOptions } from './hooks/useOptions';

export interface EmployerContactCardListProps {
    employerMasterId: string;
}

export const EmployerContactCardList = (props: EmployerContactCardListProps) => {
	const { employerApi } = useContext(APIContext);

    const options = useOptions({ employerMasterId: props.employerMasterId });
    const [selectedFilingYear, setSelectedFilingYear] = useState<number | null>(null);

    useEffect(() => {
        if (options && options.length > 0) {
            setSelectedFilingYear(parseInt(options[0].option) || null);
        }
    }, [options]);

    
	const filingContacts = employerApi.useGetFilingContacts(
        {
            employer_master_id: props.employerMasterId,
            filing_year: selectedFilingYear
        }, 
        {
            enabled: !!selectedFilingYear
        }
    );

	return (
        <div style={{ marginBottom: '50px' }}>
            <div className="d-flex justify-content-between">
                <Title className="sm align-self-start" text="Filing Contacts" />
                <div className="align-self-start" style={{ width: '100px' }}>
                    <DropDown isDeleteable={false} title={'Filing Year'}
                        values={
                            selectedFilingYear
                                ? [{ value: selectedFilingYear.toString() }]
                                : []
                        }
                        onChange={ values => {
                            if (values.length <= 1) {
                                return;
                            }
                            setSelectedFilingYear(values.pop());
                        }}
                        options={options}
                    />
                </div>
            </div>
            {filingContacts?.loading ? (
                <EmployerFilingContactCard loading />
            ) : (
                <Box display="flex" flexDirection="column" gap="xs">
                    {filingContacts?.data?.map((contact) => {
                        return (
                            <EmployerFilingContactCard
                                filings={contact?.plan_types && getFilings(contact.plan_types)}
                                linkedinUrl={null}
                                name={contact?.signed_name && FormattingUtils.formatUpperCasing(contact.signed_name)}
                                phone={contact?.phones?.[0] && PhoneNumber(contact.phones[0], 'US').getNumber('national')}
                            />
                        );
                    })}
                </Box>
            )}
            </div>
	);
};
