import cn from 'classnames';
import { SuccessPill } from 'new-beginning/components/shared/Pills';

interface CreateParentAccountContainerProps {
	accountWebsite: string;
}

export const CreateParentAccountContainer = ({ accountWebsite }: CreateParentAccountContainerProps) => {
	return (
		<div className={cn('account-insert-assign-container', 'mt-1', 'p-3')}>
			<p className={cn('bf-txt', 'my-1')}>
				We could not find any potential Parent Accounts in your Salesforce for <strong>{accountWebsite}</strong>
			</p>
			<p className={cn('bf-txt', 'txt-bolder', 'txt-sm', 'ms-2')}>To Resolve this Issue</p>
			<span className={cn('d-flex', 'align-items-end', 'mb-1')}>
				<p className={cn('bf-txt', 'txt-lighter', 'txt-xs', 'ms-2')}>1. Create a new account in your Salesforce with</p>
				&nbsp;
				<SuccessPill isVisible label={`Website = ${accountWebsite}`} />
			</span>
			<p className={cn('bf-txt', 'txt-lighter', 'txt-xs', 'ms-2', 'mb-1')}>
				2. Click the <strong>"Refresh Data"</strong> button to register the new Account in BenefitFlow
			</p>
			<p className={cn('bf-txt', 'txt-lighter', 'txt-xs', 'ms-2')}>
				3. Re-attempt Syncing your contacts tied to the Accounts requiring a Parent Assignment
			</p>
		</div>
	);
};
