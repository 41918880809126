import { useMemo } from 'react';

import { ListActionUtils } from '../../../../utilities';
import { useContactsListStore } from '../ContactsList.store';

export const useNumberOfSelectedContacts = ({ totalNumberOfContacts }: { totalNumberOfContacts: number }) => {
	const { checkedMap, checkMode } = useContactsListStore();
	const numberOfSelectedContacts = useMemo(
		() => ListActionUtils.getNumberOfSelectedContacts({ checkedMap, checkMode, totalNumberOfContacts }),
		[checkedMap, checkMode, totalNumberOfContacts],
	);

	return numberOfSelectedContacts;
};
