import { Box, Collapsible, InputLabel, Typography } from '@benefitflow/designsystem';

import { Tag, End, Start } from './components';
import { useContentIsVisible, useIsOpen, useMax, useMin, useToggle } from './hooks';

export const YearsAtCompany = () => {
	const min = useMin();
	const max = useMax();
	const contentIsVisible = useContentIsVisible();
	const isOpen = useIsOpen();
	const toggle = useToggle();

	return (
		<Collapsible
			headerTestId='years-at-company-filter-header'
			isOpen={isOpen}
			toggle={toggle}
			title='Years At Company'
			icon='calendar'
			content={contentIsVisible && <Tag />}
			openContent={
				<InputLabel label='Contacts that have [<2] years at current company'>
					<Box width='full' display='flex' alignItems='center' justifyContent='between' gap='xs'>
						<Start value={min.value} setValue={min.set} />
						<Typography.Text whiteSpace='nowrap'>to</Typography.Text>
						<End value={max.value} setValue={max.set} />
					</Box>
				</InputLabel>
			}
		/>
	);
};
