import { Navigate, Outlet } from 'react-router-dom';

const PrivateRoute = ({ isAuthenticated, loadingAuth }) => {

    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    if (loadingAuth) {
        return null;
    }
    return isAuthenticated ? <Outlet /> : <Navigate to="/Login" replace={true} />;
}

export default PrivateRoute;