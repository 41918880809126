import { ReactNode } from 'react';
import { Card, Space, TextButton, Typography } from '@benefitflow/designsystem';

import { IncludeExcludeFilter, LocationFilter, RangeFilter, CustomRangeFilter, Section, CheckboxFilter } from './components';

export const Filters = ({ children, onClear }: { children: ReactNode; onClear: () => void }) => {
	return (
		<Card
			defaultPadding={false}
			title={
				<Space direction="horizontal" size="sm">
					<Typography.Text size="lg" weight="semi-bold" color="neutral-900">
						Filters
					</Typography.Text>
				</Space>
			}
			extra={<TextButton onClick={onClear}>Clear All</TextButton>}
		>
			{children}
		</Card>
	);
};

Filters.IncludeExcludeFilter = IncludeExcludeFilter;
Filters.LocationFilter = LocationFilter;
Filters.RangeFilter = RangeFilter;
Filters.CustomRangeFilter = CustomRangeFilter;
Filters.Section = Section;
Filters.CheckboxFilter = CheckboxFilter;
