import React, { useEffect, useState } from 'react';
import './style.scss';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Paper from '@material-ui/core/Paper';
import DatePicker from "react-datepicker";
import { Modal } from 'molecules/Modal';
import Title from 'atoms/Title';


export interface UpsertClientModalProps {
  isOpen: boolean;
  onAction: (client) => any;
  onClose: () => any;
  client: any;
  isUpdate: boolean;
  isLoading: boolean;
}

export const UpsertClientModal = (props: UpsertClientModalProps) => {

  const [curClient, setCurClient] = useState(null);
  
  useEffect(() => {
    setCurClient(props.client);
  }, [props.client]);

  const handleName = (e) => {
    const value = e.target.value;
    const parsed = value
      .replace(" ", "_")
      .replace(/[^a-zA-Z0-9_]/g, "")
      .toLowerCase();
    const cpy = Object.assign({}, curClient);
    cpy["tenant_id"] = parsed;
    setCurClient(cpy);
    // onUpdateClient(cpy);
  } 

  return (
    <Modal 
    onClose={() => {}}
    isOpen={props.isOpen}>
      <Paper style={{ padding: "30px", minWidth: "400px" }}>
        <Container>
          <div style={{ textAlign: "center" }}>
            <Title text={props.isUpdate ? 'Update Client' : 'Add New Client'} />
          </div>
          <Row>
            <Col lg={{ span: 12 }} xl={{ span: 12 }}>
              <Form className="dev-form">
                <div className="form-content">
                  <div style={{ marginBottom: "20px" }}>
                    <Form.Group className="form-group">
                      <Form.Label>Client Name</Form.Label>
                      <Form.Control
                        name="name"
                        type="text"
                        disabled={props.isUpdate}
                        value={curClient?.tenant_id}
                        onChange={handleName}
                      />
                    </Form.Group>
                  </div>
                  <div style={{ marginBottom: "20px" }}>
                    <Form.Group className="form-group">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        name="name"
                        type="text"
                        value={curClient?.description}
                        onChange={(e) => {
                          const cpy = Object.assign({}, curClient);
                          cpy.description = e.target.value;
                          setCurClient(cpy);
                        }}
                      />
                    </Form.Group>
                  </div>
                  <div style={{ marginBottom: "20px" }}>
                    <Form.Group className="form-group">
                      {
                        ["Trial", "Starter", "Premium", "Enterprise"].map(x => (
                          <Form.Check
                            inline
                            label={x}
                            name="userType"
                            type="radio"
                            checked={curClient?.plan_type == x.toUpperCase()}
                            onChange={(e) => {
                              const cpy = Object.assign({}, curClient);
                              cpy.plan_type = e.target.id;
                              setCurClient(cpy);
                            }}
                            id={x.toUpperCase()}
                          />
                        ))
                      }
                    </Form.Group>
                  </div>
                  <div style={{ marginBottom: "20px" }}>
                    <Form.Group className="form-group">
                      <Form.Label>Num Licenses</Form.Label>
                      <Form.Control
                        name="name"
                        type="number"
                        value={curClient?.num_licenses}
                        onChange={(e) => {
                          const cpy = Object.assign({}, curClient);
                          cpy.num_licenses = parseInt(e.target.value);
                          setCurClient(cpy);
                        }}
                      />
                    </Form.Group>
                  </div>
                  <div style={{ marginBottom: "20px" }}>
                    <Form.Group className="form-group">
                      <Form.Label>Num Credits</Form.Label>
                      <Form.Control
                        name="name"
                        type="number"
                        value={curClient?.credits}
                        onChange={(e) => {
                          const cpy = Object.assign({}, curClient);
                          cpy.credits = parseInt(e.target.value);
                          setCurClient(cpy);
                        }}
                      />
                    </Form.Group>
                  </div>
                  <div style={{ marginBottom: "20px" }}>
                    <Form.Group className="form-group">
                      <Form.Check
                            inline
                            label={"Unlimited Exports"}
                            type="checkbox"
                            checked={curClient?.allow_unlimited_bulk_export === true}
                            onChange={(e) => {
                              setCurClient({
                                ...curClient,
                                allow_unlimited_bulk_export: e?.currentTarget?.checked,
                              });
                            }}
                            id="unlimitedExport"
                            name="unlimitedExport"
                            value="enabled"
                          />
                    </Form.Group>
                  </div>
                  <div style={{ marginBottom: "20px" }}>
                    <Form.Group className="form-group">
                      <Form.Label>Trial End Date</Form.Label>
                      <DatePicker selected={curClient?.trial_end_date ? new Date(curClient?.trial_end_date) : null} onChange={(date: Date) => {
                        const cpy = Object.assign({}, curClient);
                        cpy.trial_end_date = date;
                        setCurClient(cpy);
                      }} />
                    </Form.Group>
                  </div>
                </div>
                <div className="form-footer" style={{ textAlign: "right" }}>
                  <Button disabled={props.isLoading} variant="secondary" style={{ marginRight: "12px" }} onClick={props.onClose}>Cancel</Button>
                  <Button disabled={props.isLoading} onClick={() => props.onAction(curClient)} variant="primary">{props.isUpdate ? 'Update Client' : 'Create Client'}</Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </Paper>
    </Modal>
  );
};
