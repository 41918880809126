import { useContext, useMemo } from 'react';

import { ListDetailsContext } from '../../../ListDetails.context';

export const useLoading = () => {
	const {
		loading: optionsAreLoading,
		options: { carrierAppointments },
	} = useContext(ListDetailsContext);
	const loading = useMemo(() => optionsAreLoading && !carrierAppointments, [optionsAreLoading, carrierAppointments]);

	return loading;
};
