import { call, put, select, cancelled } from 'redux-saga/effects';
import AmplifyTokenManager from './AmplifyTokenManager';
import axios from 'axios'
import { selectIdToken, selectLoginAsTenant } from 'app/selectors';
import { appActions } from 'app/slice';
import { addNotification } from '@benefitflow/designsystem';
 
 
export class ResponseError extends Error {
    public response: Response;
  
    constructor(response: Response) {
      super(response.statusText);
      this.response = response;
    }
  }
  /**
   * Parses the JSON returned by a network request
   *
   * @param  {object} response A response from a network request
   *
   * @return {object}          The parsed JSON from the request
   */
  async function parseJSON(response) {
    if (response.status === 204 || response.status === 205) {
      return null;
    }
    return response?.data?.res
  }
  
  export function* requestSecure(
    url: string,
    options?: RequestInit
  ) {
    options.credentials = 'include';
    
    try {
      const idToken = yield select(selectIdToken);
      const loginAsTenant = yield select(selectLoginAsTenant);
      const data = yield call(requestSecureInternal, url, idToken, loginAsTenant, options);
      return data;
    } catch (err) {
      console.log("requestSecure err", err);
      throw err;
    }

  }


  // https://stackoverflow.com/questions/50078589/cancel-of-requests-through-saga
  function* requestSecureInternal(
    url: string,
    idToken,
    loginAsTenant,
    options?: RequestInit,
  ) {
    const headers = {
      'Authorization': `${idToken}`,
      "Content-Type": "application/json",
    };
    if (loginAsTenant) {
      headers["bf_login_as_tenant"] = loginAsTenant;
    }
    const source = axios.CancelToken.source();
    try {

      const handleResponse = async (url, retried = false) => {
          const request = axios.post(url, options.body, { 
            headers: headers,
            cancelToken: source.token,
          });
          try {
            const fetchResponse = await request;
            // console.log("fetchResponse", fetchResponse);
            // console.log(`requestSecureInternal status: ${fetchResponse.status}, retried: ${retried}, url: ${url}`);
            const res = await parseJSON(fetchResponse);
            return res;
          } catch (err) {
            if (!retried) {
              const token = await AmplifyTokenManager.getInstance().getIdToken();
              headers["Authorization"] = `${token["idToken"]}`;
              return await handleResponse(url, true);
            } else {
              throw err;
            }
          }
      };

      const res = yield handleResponse(url);
      if (res.success == false) {
        yield put(appActions.addErrorMessage(res.message));
      }
      
      return res;

    } catch(error) {
      console.log(`err with url: ${url}`, error);
      if (error?.response?.data?.res?.message) {
        addNotification({
          type: 'error',
          message: error?.response?.data?.res?.message,
        });
      } else if (error?.message) {
        addNotification({
          type: 'error',
          message: error.message,
        });
      } else {
        addNotification({
          type: 'error',
          message: error,
        });
      }
      throw Error(error);
    } finally {
      if (yield cancelled()) {
        console.log(`Cancelling request to ${url}`); 
        yield call(source.cancel);
      }
    }
  }
