import cn from 'classnames';
import { useParams } from 'react-router-dom';
import { useState, memo, useEffect, SetStateAction } from 'react';
import { SyncActionBar } from 'new-beginning/components/pages/ListSync/SyncTable/SyncActionBar';
import { ContactTableHeader } from 'new-beginning/components/pages/ListSync/SyncTable/ContactTableHeader';
import { ScrollTable } from 'new-beginning/components/pages/ListSync/SyncTable/ScrollTable';
import { ContactRowSet } from 'new-beginning/components/pages/ListSync/SyncTable/TableRows';
import { useListContacts } from 'new-beginning/hooks/salesforce/useListContacts';
import { ListDetailsType } from 'new-beginning/hooks/salesforce/useListInfo';
import { PaginationControlSimple } from 'new-beginning/components/shared/Pagination/PaginationControlSimple';
import { SyncToggleInterface } from 'new-beginning/hooks/salesforce/useSyncModalActions';
import { SalesforceUsersById } from 'new-beginning/hooks/salesforce/useContactOwner';
import { ContactListFilter } from 'common.model/src/db/model-decorators/type-extensions';

interface ContactSyncProps {
	listJobLoading: boolean;
	listDetails: ListDetailsType;
	salesforceUsersById: SalesforceUsersById;
	syncListContacts: (callback: Function, syncContactOverride?: Record<string, boolean>) => void;
	allContactSelected: boolean;
	setAllContactSelected: (selectRow: SetStateAction<boolean>) => void;
	selectedRows: Record<string, boolean>;
	setSelectedRows: (selectRow: SetStateAction<Record<string, boolean>>) => void;
	modalConfig: SyncToggleInterface;
	contactRefetchTrigger: number;
	isAdmin: boolean;
	currentTab: ContactListFilter;
}

export const ListContactContainer = memo(
	({
		syncListContacts,
		listJobLoading,
		listDetails,
		salesforceUsersById,
		selectedRows,
		setSelectedRows,
		setAllContactSelected,
		allContactSelected,
		modalConfig,
		contactRefetchTrigger,
		isAdmin,
		currentTab,
	}: ContactSyncProps) => {
		const refreshJobId = listDetails?.syncJobStatus?.parentJob?.id;
		const syncInProgress = listDetails?.syncJobStatus?.syncInProgress || false;

		const { listId } = useParams();
		const {
			listContacts,
			refetchListContacts,
			paginationParams,
			setPage,
			setSortConfig,
			loading: listLoading,
			requestApiSuccess,
		} = useListContacts(listId);

		const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});

		useEffect(() => {
			setAllContactSelected(false);
			setSelectedRows({});
			setExpandedRows({});
			refetchListContacts();
		}, [syncInProgress, refreshJobId]);

		useEffect(() => {
			if (!!contactRefetchTrigger) {
				refetchListContacts();
			}
		}, [contactRefetchTrigger])

		const selectRow = (rowId, isSelected) => {
			setSelectedRows({
				...selectedRows,
				[rowId]: isSelected,
			});
		};

		const selectAllRows = () => {
			setSelectedRows({});
			setAllContactSelected(!allContactSelected);
		};

		const expandRow = (contactId) =>
			setExpandedRows({
				...expandedRows,
				[contactId]: !expandedRows?.[contactId],
			});

		const numSelected = allContactSelected ? paginationParams?.count : Object.values(selectedRows).filter(Boolean).length;
		const hasSelection = allContactSelected || numSelected > 0;

		const tabEmpty = (listContacts?.length || 0) === 0;
		const isLoading = listJobLoading || listLoading;
		const restrictedPermission = !isAdmin && [ContactListFilter.UPDATES, ContactListFilter.SYNCED].includes(currentTab);

		return (
			<div className={cn('my-1', 'bg-white', 'rounded')}>
				<SyncActionBar
					syncListContacts={() => syncListContacts(refetchListContacts)}
					hasSelection={hasSelection}
					allSelected={allContactSelected}
					selectAllRows={selectAllRows}
					numSelected={numSelected}
					hasSyncActions={true}
					lockCrmSync={isLoading || !syncInProgress}
					lockAllActions={isLoading || tabEmpty || restrictedPermission}
					modalConfig={modalConfig}
				>
					<PaginationControlSimple setPage={setPage} paginationState={paginationParams} isLoading={isLoading} />
				</SyncActionBar>
				<ScrollTable
					tableType='contact-sync-table'
					scrollTrigger={paginationParams?.offset}
					containerClassNames={cn('contact-sync-table-container', 'rounded', { 'content-loading': listLoading })}
				>
					<ContactTableHeader
						lockAllActions={isLoading || tabEmpty}
						setSortConfig={setSortConfig}
						paginationParams={paginationParams}
					/>
					<ContactRowSet
						listContacts={listContacts}
						salesforceUsersById={salesforceUsersById}
						syncListContacts={(itemToSync) => syncListContacts(refetchListContacts, itemToSync)}
						syncInProgress={syncInProgress}
						jobLoading={listJobLoading}
						listLoading={listLoading}
						rowCount={paginationParams?.limit}
						selectedRows={selectedRows}
						expandedRows={expandedRows}
						selectRow={selectRow}
						expandRow={expandRow}
						allContactSelected={allContactSelected}
						isAdmin={isAdmin}
					/>
				</ScrollTable>
			</div>
		);
	}
);
