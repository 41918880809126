import { useCallback, useContext } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useAddNotification } from '@benefitflow/designsystem';

import { QueryKey } from '../../../../../../../../constants/queryKey';
import { ContactTypeEnum } from 'common.model/src/types/ContactTypeEnum';
import { APIContext } from '../../../../../../../../services/api/Api.context';
import { Notifications } from '../../../../../../../../constants/notifications';
import { ContactListHeaderContext } from '../../../../../ContactListHeader.types';
import { getApiPayloadFromState } from '../../../../../../../pages/Search/BrokerContacts/components/List/components/Header/helpers/getApiPayloadFromState';

export const useCreateList = ({
	description,
	name,
	setHasError,
	checkMode,
	checkedMap,
	filters,
	setIsAddToListDialogOpen,
	type,
	useAddToNewListMutation,
}: {
	name?: string;
	description?: string;
	setHasError: (hasError: boolean) => void;
	setIsAddToListDialogOpen: (isOpen: boolean) => void;
	useAddToNewListMutation;
	type: ContactListHeaderContext['type'];
} & Pick<ContactListHeaderContext['store'], 'filters' | 'checkedMap' | 'checkMode'>) => {
	const { listsApi } = useContext(APIContext);
	const listsQuery = listsApi.useGetAllListsForUser();
	const addNotification = useAddNotification();
	const queryClient = useQueryClient();
	const onSuccess = useCallback(
		(data) => {
			setIsAddToListDialogOpen(false);
			listsQuery.query.refetch();
			const notification = Notifications.Search.ByBrokers.Contacts.CreateListDialog.Success({
				name: data.list.name,
				totalAdded: data.totalAdded,
			});
			addNotification(notification);
			queryClient.invalidateQueries({ predicate: (query) => query.queryKey?.includes(QueryKey.Lists) });
		},
		[setIsAddToListDialogOpen, listsQuery, addNotification, queryClient],
	);
	const onError = useCallback(
		(error) => {
			console.error(error);
			const notification = Notifications.Search.ByBrokers.Contacts.CreateListDialog.Error;
			addNotification(notification);
		},
		[addNotification],
	);
	const { mutate, isLoading } = useAddToNewListMutation({ onError, onSuccess });
	const createList = useCallback(() => {
		if (!name.match(/^[^\s].+/)) {
			const notification = Notifications.Search.ByBrokers.Contacts.CreateListDialog.InvalidField.Name;
			addNotification(notification);
			setHasError(true);
			return;
		}
		mutate({
			...getApiPayloadFromState({ checkedMap, checkMode }),
			filters: {
				contactType: (() => {
					if (type === 'broker') return ContactTypeEnum.BROKER;
					return ContactTypeEnum.EMPLOYER;
				})(),
				...filters,
			},
			listName: name,
			listDescription: description,
		});
	}, [setHasError, addNotification, mutate, description, name, filters, checkedMap, checkMode, type]);

	return { createList, loading: isLoading };
};
