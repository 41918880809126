import React from 'react';
import { ChevronLeft } from 'react-iconly';
import styled from 'styled-components';
import { terciaryColor } from 'designTokens/colors';
import { TerciarySpanLarge } from '../../StyledComponents';


interface Props {
    onBack: () => void;
}

const StyledDiv = styled.div`
  color: ${terciaryColor};
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const Component = ({ onBack }: Props) => {

  return (
    <StyledDiv
        onClick={() => onBack()}
    >
        <ChevronLeft
            set="light"
            primaryColor="#B7C2CF"
            style={{ marginRight: '6px' }}
        />
        <TerciarySpanLarge>{'Back'}</TerciarySpanLarge>
    </StyledDiv>
  );
}

export default Component;
