import { Box, Icon as DSIcon } from '@benefitflow/designsystem';
import { useMemo } from 'react';

export const Icon = ({ checked }: { checked: boolean }) => {
	const name = useMemo(() => (checked ? 'check' : 'multiply'), [checked]);
	const color = useMemo(() => (checked ? 'success-500' : 'danger-500'), [checked]);
	const backgroundColor = useMemo(() => (checked ? 'success-200' : 'danger-200'), [checked]);

	return (
		<Box
			backgroundColor={backgroundColor}
			borderRadius='round'
			height='lg'
			width='lg'
			display='flex'
			justifyContent='center'
			alignItems='center'
		>
			<DSIcon name={name} size='sm' color={color} />
		</Box>
	);
};
