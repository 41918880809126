import { ReactNode } from 'react';
import cn from 'classnames';
import Modal from '@material-ui/core/Modal';
import classnames from 'classnames';
import { FaXmark } from 'react-icons/fa6';

export interface BaseModalProps {
	children: ReactNode;
	headerText: string;
	isVisible: boolean;
	cancelAction: (input?: any | null) => void;
	className?: string;
	headerNav?: ReactNode;
}

export const BaseModal = ({ children, headerNav, headerText, isVisible, cancelAction, className = null }: BaseModalProps) => (
	<Modal open={isVisible} onClose={cancelAction} className={classnames('custom-modal', className)}>
		<div className={classnames('modal-container', 'rounded', 'shadow')}>
			<div className={classnames('header', 'd-flex', 'justify-content-between', 'align-items-center')}>
				<div>
					<p className={cn('bf-txt', 'txt-lg', 'txt-dark')}>{headerText}</p>
				</div>
				<div className={classnames('d-flex', 'align-items-center', 'px-1')}>
					{headerNav}
					<button className={cn('bf-btn', 'bf-btn-simple', 'ms-3')} onClick={cancelAction}>
						<FaXmark className={cn('bf-icon', 'ic-action', 'ic-cancel')} size={26} />
					</button>
				</div>
			</div>
			{children}
		</div>
	</Modal>
);
