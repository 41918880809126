import { useCallback } from 'react';
import { useAddNotification } from '@benefitflow/designsystem';

export const useNavigation = () => {
	const addNotification = useAddNotification();
	const showToBeImplementedMessage = useCallback(() => {
		addNotification({ type: 'info', message: 'This feature is not yet implemented.', autoClose: true });
	}, [addNotification]);

	return {
		onNextClick: showToBeImplementedMessage,
		onPreviousClick: showToBeImplementedMessage,
		currentPage: 1,
		totalPages: 32,
	};
};
