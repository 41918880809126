import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.searchPage || initialState;

export const selectSearchPage = createSelector(
  [selectDomain],
  searchPageState => searchPageState,
);

export const selectSearchBy = createSelector(
  [selectDomain],
  searchPageState => searchPageState.searchBy,
);
