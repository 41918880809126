import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectListsDetailPage } from 'app/containers/ListsPage/containers/ListsDetailPage/selectors';
import { useListDetailsFilterStore } from '../../../ListDetails.store';

export const useContentIsVisible = () => {
	const {
		contactFilters: { yearsAtCompany },
	} = useSelector(selectListsDetailPage);

	const {
		collapsible: {
			map: { yearsAtCompany: isOpen },
		},
	} = useListDetailsFilterStore();

	const contentIsVisible = useMemo(() => {
		if (isOpen) return false;
		if (yearsAtCompany.length < 2) return false;
		if (yearsAtCompany[0] == 0 && yearsAtCompany[1] == 21) return false;

		return true;
	}, [yearsAtCompany, isOpen]);

	return contentIsVisible;
};
