import { List } from '../../components/pages/List/lib/entities/list';
import { useContext, useMemo, useState } from 'react';
import { PaginationParams, SortKey, SortOrder } from 'common.model/src/types/Pagination';
import { SetSortParams } from 'new-beginning/hooks/salesforce/useListContacts';
import { APIContext } from 'new-beginning/services/api/Api.context';

export const useGetList = () => {
	const { listsApi } = useContext(APIContext);
	const [currentSorting, setCurrentSorting] = useState<PaginationParams>(undefined);

	const handleSorting = (args: SetSortParams) => {
		setCurrentSorting({ sortKey: args.sortKey, sortOrder: args.sortOrder, offset: 0, limit: 0 });
	};

	const { data, loading } = listsApi.useGetAllListsForUser({
		// refetchOnMount: true,
		// refetchOnReconnect: true,
		refetchInterval: Infinity,
		refetchOnWindowFocus: true,		
		// staleTime: 0,
		// cacheTime: 1000
	});

	const sortedData = useMemo(() => {
		const listData = data?.map((row) => new List(row));
		switch (currentSorting?.sortKey) {
			case SortKey.ListName:
			case SortKey.ListCreatedAt:
			case SortKey.ListCreatedBy:
			case SortKey.ListContacts:
				if (currentSorting.sortOrder === SortOrder.ASC) {
					return listData?.sort((a, b) => (a[currentSorting.sortKey] < b[currentSorting.sortKey] ? 1 : -1));
				}
				return listData?.sort((a, b) => (a[currentSorting.sortKey] < b[currentSorting.sortKey] ? -1 : 1));
			case SortKey.ListType:
				if (currentSorting.sortOrder === SortOrder.ASC) {
					return listData?.sort((a, b) => (a.getListType < b.getListType ? 1 : -1));
				}
				return listData?.sort((a, b) => (a.getListType < b.getListType ? -1 : 1));
			default:
				return listData;
		}
	}, [currentSorting, data]);

	return { data: sortedData, isLoading: loading, handleSorting, currentSorting };
};
