import { useState, useEffect } from 'react';
import serviceUrl from 'utils/serviceUrl';
import axios from 'axios';
import { useBasicApiRequestState, BasicApiRquestState, BaseApiReponse } from '../useBasicApiRequestState';
import { ListAttributes } from 'common.model/src/db/models';
import { ContactTabCounts, ListSyncStatus } from 'common.model/src/db/model-decorators/type-extensions';

export interface ListDetailsType {
	list: ListAttributes;
	syncJobStatus: ListSyncStatus;
	contactCountsByType: ContactTabCounts;
}
interface ListDetailsResponse extends BaseApiReponse {
	listDetails: ListDetailsType;
	refreshListDetails: () => Promise<void>;
}

const listDetailsUrl = `${serviceUrl}/crm-integration/getListDetails`;
export const useListDetails = (listId: string): ListDetailsResponse => {
	const {
		baseHeaders,
		loading,
		tokenLoading,
		setLoading,
		requestApiSuccess,
		setApiRequestSuccess,
		requestErrorMessage,
		setRequestErrorMessage,
	}: BasicApiRquestState = useBasicApiRequestState();

	const [listDetails, setListDetails] = useState<ListDetailsType>(null);

	const fetchListDetails = () => {
		setLoading(true);
		return axios
			.get(`${listDetailsUrl}/${listId}`, { headers: { ...baseHeaders } })
			.then((res) => {
				const response = res?.data?.res?.data;
				setListDetails(response);
				setApiRequestSuccess(true);
			})
			.catch((err: Error) => {
				setRequestErrorMessage(err.message);
				setApiRequestSuccess(false);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const refreshListDetails = async () => await fetchListDetails();

	useEffect(() => {
		if (baseHeaders?.Authorization && !loading) {
			fetchListDetails();
		} else if (!tokenLoading && !loading) {
			console.warn('Cannot Fetch List Details without Token');
		}
	}, [listId, baseHeaders?.Authorization]);

	return {
		listDetails,
		loading,
		refreshListDetails,
		requestApiSuccess,
		requestErrorMessage,
	};
};
