import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import './style.scss';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

export interface MultipleLineChartProps {
  className?: any;
  title?: string;
  data: any;
  loading?: boolean;
}

const SampleData = {
  labels: Array.from(Array(10).keys())
    .map(i => `${new Date().getFullYear() - i}`)
    .reverse(),
  datasets: [
    {
      label: '',
      data: Array.from(Array(10).keys()).map(() =>
        Math.floor(Math.random() * 100),
      ),
      borderColor: 'rgba(0, 0, 0, 0.11)',
      backgroundColor: 'rgba(0, 0, 0, 0.11)',
    },
    {
      label: '',
      data: Array.from(Array(10).keys()).map(() =>
        Math.floor(Math.random() * 100),
      ),
      borderColor: 'rgba(0, 0, 0, 0.11)',
      backgroundColor: 'rgba(0, 0, 0, 0.11)',
    },
  ],
};

export const MultipleLineChart = ({ className, title, data, loading }: MultipleLineChartProps) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right' as const,
      },
      title: {
        display: false,
      },
      tooltip: {
        enabled: !loading,
      },
    },
    scales: {
      x: {
        ticks: {
          display: true,
        },
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          color: '#AFBDCF',
        },
      },
    },
  };

  return (
    <div className={`multiline-chart-bloc ${className}`}>
      <div className={`chart-content ${loading ? 'loading' : ''}`}>
        <Line options={options} data={loading ? SampleData : data} />
      </div>
    </div>
  );
};
