import { Description, Typography } from '@benefitflow/designsystem';
import { useOnBrokerClick } from '../hooks';
import { useState } from 'react';

export const Broker = ({ broker }: { broker: string }) => {
	const [hovered, setHovered] = useState(false);
	const onBrokerClick = useOnBrokerClick();

	return (
		<>
			{broker && (
				<Description icon="business">
					<Typography.Text
						onMouseEnter={() => setHovered(true)}
						onMouseLeave={() => setHovered(false)}
						color="primary-500"
						onClick={onBrokerClick}
						style={{ cursor: 'pointer' }}
						decoration={hovered ? 'underline' : undefined}
						size="md"
					>
						{broker}
					</Typography.Text>
				</Description>
			)}
		</>
	);
};
