import { useMemo } from 'react';

import { useEmployeeSentiment } from './useEmployeeSentiment';

export const useIsEmpty = () => {
	const { loading, benefits, evaluation, summary } = useEmployeeSentiment();

	const isEmpty = useMemo(() => !loading && !benefits && !evaluation && !summary, [loading, benefits, evaluation, summary]);

	return isEmpty;
};
