import { createCheckboxMethods } from './createCheckboxMethods';
import { createContactSelectorMethods } from './createContactSelectorMethods';
import { createFilterVisibilityMethods } from './createFiltersVisibilityMethods';
import { createIncludeExcludeMethods } from './createIncludeExcludeMethods';
import { createLocationMethods } from './createLocationMethods';
import { createRangeMethods, createRangeMethodsLegacy } from './createRangeMethods';
import { createStringMethods } from './createStringMethods';

export const StoreUtils = {
	createIncludeExcludeMethods,
	createRangeMethods,
	createRangeMethodsLegacy,
	createLocationMethods,
	createContactSelectorMethods,
	createStringMethods,
	createFilterVisibilityMethods,
	createCheckboxMethods,
};
