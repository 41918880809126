import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Box } from '@benefitflow/designsystem';
import styled from 'styled-components';
import { FormattingUtils } from "utils/index";
import { Panel } from 'atoms/Panel';
import { Metric } from 'molecules/Metric';
import { PieChart } from 'molecules/charts/PieChart';
import { usePolicies } from '../../hooks/usePolicies';
import { InsurancePoliciesSummaryCardConfigModel } from './InsurancePoliciesSummaryCardConfigModel';

const PieChartSkeleton = styled(Skeleton)`
  padding: 15px;
  border-radius: 7px;
`;

function getPie(map, colors) {
  if (!map) {
    return null;
  }
  const keys = Object.keys(map).sort((a, b) => map[b] - map[a]);
  const values = keys.map(x => map[x]);
  const colorValues = keys.map(x => colors[x]);
  return {
    labels: keys.map(x => FormattingUtils.formatUpperCasing(x)),
    datasets: [
      {
        data: values,
        backgroundColor: colorValues,
        borderWidth: 0,
      },
    ],
  };
}

export interface InsurancePoliciesSummaryCardProps {
  employerMasterId: string;
  selectedFilingYear: number;
}

export const InsurancePoliciesSummaryCard = (props: InsurancePoliciesSummaryCardProps) => {

  const {
    data,
    loading,
    brokerNameToColor,
    carrierNameToColor
  } = usePolicies({ employerMasterId: props.employerMasterId, selectedFilingYear: props.selectedFilingYear });

  const config = InsurancePoliciesSummaryCardConfigModel
      .getConfig(
          data, 
          loading,
          brokerNameToColor,
          carrierNameToColor
      );

  const brokerCommissionsPieConfig = getPie(config.brokerCommissionsMap, config.brokerColors);
  const carrierPremiumsPieConfig = getPie(config.carrierPremiumsMap, config.carrierColors);

  return (
    <Panel>
      <Box display="flex" flexDirection="row" style={{ width: '100%', height: '100%' }}>
        <Box style={{ flex: '0 0 25%', marginRight: '16px' }}>
          <Box display="flex" flexDirection="column">
            <Box style={{ flex: 1, marginBottom: '16px' }}>
              <Metric
                loading={config.isLoading}
                title="Medical Policies"
                type="tag"
                text={config.countMedicalPolicies?.toString()}
              />
            </Box>
            <Box style={{ flex: 1 }}>
              <Metric
                loading={config.isLoading}
                title="Non-Medical Policies"
                type="tag"
                text={config.countNonMedicalPolicies?.toString()}
              />
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="row" style={{ flex: '0 0 75%' }}>
          <Box style={{ flex: 1, marginRight: '16px' }}>
            <Metric
              loading={config.isLoading}
              title="Broker Commissions"
              type="text"
              text={`\$${FormattingUtils.formatNumberAbbrev(
                config.totalBrokerCommissions,
              )} Total`}
            />
            {config.isLoading ? (
              <PieChartSkeleton variant="rect" width="100%" height={140} />
            ) : (
              <PieChart
                data={brokerCommissionsPieConfig}
                valueTitle={'Broker Commissions'}
                percentageTitle={'% of Total Commmissions'}
              />
            )}
          </Box>
          <Box style={{ flex: 1 }}>
            <Metric
              loading={config.isLoading}
              title="Carrier Premiums"
              type="text"
              text={`\$${FormattingUtils.formatNumberAbbrev(
                config.totalCarrierPremiums,
              )} Total`}
            />
            {config.isLoading ? (
              <PieChartSkeleton variant="rect" width="100%" height={140} />
            ) : (
              <PieChart
                data={carrierPremiumsPieConfig}
                valueTitle={'Carrier Premiums'}
                percentageTitle={'% of Total Premiums'}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Panel>
  );
};