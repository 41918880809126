export type CheckboxMethod = {
	toggle: () => void;
	set: (checked: boolean) => void;
};

export const createCheckboxMethods = (filterName, set) => {
	return {
		toggle: () =>
			set((state) => ({
				filters: {
					...state.filters,
					[filterName]: !state.filters[filterName],
				},
			})),
		set: (checked: boolean) =>
			set((state) => ({
				filters: {
					...state.filters,
					[filterName]: checked,
				},
			})),
	};
};
