import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useEmployerProfileStore } from '../../EmployerProfile.store';
import { Tabs as TabsEnum } from '../../EmployerProfile.types';

const SEARCH_PARAM = 'tab';

export const useTabs = () => {
	const { currentTab, setCurrentTab } = useEmployerProfileStore();
	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		if (!currentTab) {
			const persistedTab = searchParams.get(SEARCH_PARAM);
			setCurrentTab((persistedTab as TabsEnum) || TabsEnum.BENEFITS);
		}
	}, [currentTab, searchParams, setCurrentTab]);

	useEffect(() => {
		if (currentTab) setSearchParams({ [SEARCH_PARAM]: currentTab });
	}, [setSearchParams, currentTab]);

	return [currentTab, setCurrentTab] as const;
};
