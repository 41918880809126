export type StringMethod = (value: string) => void;

export const createStringMethods =
	<FilterType>(filterName: keyof FilterType, set) =>
	(value: string) => {
		set((state) => ({
			filters: {
				...state.filters,
				[filterName]: value,
			},
		}));
	};
