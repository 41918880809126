import { useContext, useMemo } from 'react';

import { ListActionUtils } from '../../../../../../../../utilities';
import { useContactsListStore } from '../../../../../ContactsList.store';
import { ContactsListContext } from '../../../../../ContactsList.context';

export const useIsDisabled = () => {
	const { contacts } = useContext(ContactsListContext);
	const { checkedMap, checkMode } = useContactsListStore();
	const isDisabled = useMemo(() => ListActionUtils.isGetSelectedButtonDisabled({ checkedMap, checkMode, contacts }), [contacts, checkedMap, checkMode]);

	return isDisabled;
};
