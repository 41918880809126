import { useContext, useState } from 'react';
import { Box, Button, Divider, Icon, Input, InputLabel, Typography } from '@benefitflow/designsystem';

import { useNameInput } from './hooks';
import { useCreateList } from '../../hooks';
import { AddToListButtonProps } from '../../../../AddToListButton.types';
import { ContactListHeaderContext } from '../../../../../../ContactListHeader.context';

export const Create = ({
	setTab,
	setIsAddToListDialogOpen,
	useAddToNewListMutation,
}: {
	setTab: (tab: 'add' | 'create') => void;
	setIsAddToListDialogOpen: (isOpen: boolean) => void;
	useAddToNewListMutation: AddToListButtonProps['useAddToNewListMutation'];
}) => {
	const {
		type,
		store: { checkedMap, checkMode, filters },
	} = useContext(ContactListHeaderContext);
	const [hasError, setHasError] = useState(false);
	const [name, setName] = useState('');
	const [description, setDescription] = useState('');
	const { createList, loading } = useCreateList({
		useAddToNewListMutation,
		name,
		type,
		description,
		setHasError,
		setIsAddToListDialogOpen,
		checkedMap,
		checkMode,
		filters,
	});
	const { inputRef } = useNameInput({ hasError, name, setHasError });

	return (
		<Box display="flex" flexDirection="column">
			<Box m="lg">
				<Box mb="lg" gap="sm" display="flex" justifyContent="start" alignItems="center">
					<Box cursor="pointer" onClick={() => setTab('add')}>
						<Icon size="lg" name="arrow-left" />
					</Box>
					<Typography.Text size="lg" color="neutral-700">
						Create New List
					</Typography.Text>
				</Box>
				<Box display="flex" flexDirection="column" gap="md">
					<InputLabel label="Name*">
						<Input
							inputRef={inputRef}
							hasError={hasError}
							placeholder="Enter a name for your list"
							disabled={loading}
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>
					</InputLabel>
					<InputLabel label="Description">
						<Input placeholder="Enter a description for your list" disabled={loading} value={description} onChange={(e) => setDescription(e.target.value)} />
					</InputLabel>
				</Box>
			</Box>
			<Divider />

			<Box m="lg" display="flex" justifyContent="end" alignItems="center" gap="sm">
				<Box cursor="pointer" onClick={() => setIsAddToListDialogOpen(false)}>
					<Typography.Text color="primary-500">Cancel</Typography.Text>
				</Box>
				<Button fetching={loading} onClick={createList}>
					Create
				</Button>
			</Box>
		</Box>
	);
};
