import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

/**
 * Hook to determine if the employer profile page is version 2 or not.
 * This should be removed once the v1 employer profile page is deprecated.
 */
export const useIsV2 = () => {
	const { employerId } = useParams();

	const isV2 = useMemo(() => {
		if (employerId) return true;
		return false;
	}, [employerId]);

	return isV2;
};
