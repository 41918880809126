import { Box, Icon } from '@benefitflow/designsystem';

import { useBenefitsRatingColor } from '../../hooks';
import { IconProps } from '@benefitflow/designsystem/dist/components/Icon/Icon.types';
import { getStarType } from './helpers/getStarType/getStarType';

export type StarType = 'half' | 'full' | 'empty';

export const BenefitsRating = ({ benefitsRating }: { benefitsRating: number }) => {
	const benefitsRatingColor = useBenefitsRatingColor(benefitsRating?.toFixed(1));

	return (
		<Box display="flex">
			{new Array(5).fill(null).map((_, index) => (
				<Star color={benefitsRatingColor} type={getStarType({ index, rating: benefitsRating?.toFixed(1) })} />
			))}
		</Box>
	);
};

const Star = ({ type, color }: { type: StarType; color: IconProps['color'] }) => {
	if (type === 'full') return <Icon name="favorite" color={color} size="xl3" />;
	if (type === 'empty') return <Icon name="favorite" color="neutral-300" size="xl3" />;
	if (type === 'half')
		return (
			<div style={{ width: '3rem', height: '3rem' }}>
				<Box position="absolute">
					<Icon name="favorite" color="neutral-300" size="xl3" />
				</Box>
				<div style={{ position: 'absolute', overflow: 'hidden', width: '1.5rem' }}>
					<div style={{ position: 'relative', width: '3rem' }}>
						<Icon name="favorite" color={color} size="xl3" />
					</div>
				</div>
			</div>
		);
};
