import { useMemo } from 'react';

import { InsightProps } from '../../../Benefit.types';

export const useIconProps = (type: InsightProps['type']) => {
	const props = useMemo(
		() =>
			({
				positive: {
					name: 'thumbs-up' as const,
					color: 'success-500' as const,
					backgroundColor: 'success-200' as const,
				},
				negative: {
					name: 'thumbs-down' as const,
					color: 'danger-500' as const,
					backgroundColor: 'danger-200' as const,
				},
				info: {
					name: 'lightbulb' as const,
					color: 'primary-500' as const,
					backgroundColor: 'primary-200' as const,
				},
			})[type],
		[type],
	);

	return props;
};
