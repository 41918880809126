import classnames from 'classnames';
import { BaseModal } from 'new-beginning/components/shared/Modals/BaseModal';
import {TenantUser} from '../../../hooks/useTenantTeamInfo';
import { useDeleteTenantUser, DeleteUserResponse } from '../../../hooks/useDeleteTenantUser';

interface ConfirmDeleteUserModalProps {
    cancelDeletion: () => void;
    refetchData: () => void;
    isVisible: boolean;
    userToDelete: TenantUser;
}

export const ConfirmDeleteUserModal = ({
    cancelDeletion,
    refetchData,
    isVisible,
    userToDelete,
}: ConfirmDeleteUserModalProps) => {
    const { loading, requestErrorMessage, requestApiSuccess, deleteUserFromTeam}: DeleteUserResponse = useDeleteTenantUser();
    const submitDeletion = () => deleteUserFromTeam(userToDelete).then(() => {
        refetchData();
    }).finally(() => {
        cancelDeletion();
    })

    return (
        <BaseModal isVisible={isVisible} headerText="Remove User" cancelAction={cancelDeletion} className="delete-user-modal" >
            <div
                className={classnames(
                    "primary-content",
                    "d-flex", "flex-column",
                    "justify-content-even",
                    "align-items-start",
                    "py-1", "px-1",
                    "mx-3", "my-3"
                )}
            >
                <div className={classnames("my-5", "text-center", "text-wrap")}>
                    <p>Are you sure you want to remove User <b>{userToDelete?.email}</b> from your Team?</p>
                </div>
                <div className={classnames("w-100", "d-flex", "justify-content-end")}>
                    <button
                        type="button"
                        onClick={cancelDeletion}
                        disabled={loading}
                        className={classnames("btn", "btn-outline-secondary", "mx-2")}
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        onClick={submitDeletion}
                        disabled={loading}
                        className={classnames("deletion-btn", "btn", "btn-danger", "mx-2")}
                    >
                        {!loading && <span>Confirm Deletion</span>}
                        {loading && (
                            <span>
                                <span className={classnames("spinner-border", "spinner-border-sm")} />
                                <span className={classnames("ms-2")}>
                                    Deleting
                                </span>
                            </span>
                        )}
                    </button>
                </div>
            </div>
        </BaseModal>
    );
};
