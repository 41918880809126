import { ContactDTO } from '../../queries';

export const onBrokerClickCallback = (contact: ContactDTO) => {
	window.open(
		`${window.location.origin}/Broker` +
			`?brokerId=${contact.brokerId}` +
			`&brokerName=${contact?.brokerName}` +
			`&brokerFilters=null` +
			`&brokerLocation=${contact.brokerLocation}`,
	);
};
