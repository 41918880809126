import { useMemo } from 'react';
import { AutoComplete, Box, Collapsible, InputLabel } from '@benefitflow/designsystem';

import { useIsOpen, useLoading, useOnDelete, useOnExclude, useOnSearch, useOnSelect, useOptions, useToggle, useValues, useRadiusSearch } from './hooks';
import { Tags } from 'new-beginning/components/shared/SidebarFilters/components/Tags/Tags';
import { RadiusSearchSelect } from 'new-beginning/components/shared/SidebarFilters/components/RadiusSearchSelect/RadiusSearchSelect';
import { LOCATION_INPUT_PLACEHOLDER } from 'app/constants';

export const ContactLocation = () => {
	const { shouldShowRadiusSearch, radius, onSelectRadius } = useRadiusSearch();
	const values = useValues();
	const options = useOptions();
	const onSearch = useOnSearch();
	const onDelete = useOnDelete();
	const loading = useLoading();
	const onSelect = useOnSelect(radius);
	const selectedValues = useMemo(() => values.map(({ value }) => value), [values]);
	const isOpen = useIsOpen();
	const toggle = useToggle();
	const onExclude = useOnExclude(radius);

	return (
		<Collapsible
			isOpen={isOpen}
			toggle={toggle}
			title="Contact Location"
			icon="location"
			content={values.length && <Tags onDelete={onDelete} values={values} />}
			openContent={
				<>
					<InputLabel label="Contacts located in [California]">
						<AutoComplete
							removable
							onDelete={onExclude}
							inputTestId="contact-location-filter-input"
							selectedValues={selectedValues}
							loading={loading}
							placeholder={LOCATION_INPUT_PLACEHOLDER}
							onSelect={onSelect}
							options={options}
							onSearch={onSearch}
						/>
					</InputLabel>
					{shouldShowRadiusSearch && (
						<Box mt="sm">
							<RadiusSearchSelect radius={radius} onSelectRadius={onSelectRadius} />
						</Box>
					)}
				</>
			}
		/>
	);
};
