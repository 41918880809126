import { useMemo } from 'react';

export const useOptions = ({
	search,
	defaultOptions,
}: {
	search: string;
	defaultOptions: { value: number | string; label: string }[];
}): { value: number | string; label: string }[] => {
	const options = useMemo(() => {
		const options: { value: number | string; label: string }[] = [];
		if (!search) return defaultOptions;
		options.push(...defaultOptions.filter((option) => String(option.value).includes(search)));
		if (options.some((option) => String(option.value) === search)) return options;
		return [{ label: search, value: Number(search) }, ...options];
	}, [search, defaultOptions]);

	return options;
};
