import {useState, useEffect} from 'react';
import classnames from 'classnames';
import { BaseModal } from 'new-beginning/components/shared/Modals/BaseModal';
import {TenantUser} from '../../../hooks/useTenantTeamInfo';
import { useUpdateUserAttributes, UpdateUserAttribueResponse } from 'new-beginning/hooks/useUpdateUserAttributes';

interface EditUserModalProps {
    userToUpdate: TenantUser;
    cancelEdits: () => void;
    isVisible: boolean;
    refetchData: () => void;
}

export const EditUserAttributesModal = ({ userToUpdate, isVisible, cancelEdits, refetchData }: EditUserModalProps) => {
    const [userChanges, setUserChanges] = useState<{ updatedCreditValue: number; permissionLevel: string}>({
        updatedCreditValue: null,
        permissionLevel: "",
    });
    const {updatedCreditValue, permissionLevel} = userChanges;

    const setUserAttribute = (key: string, value: string | number) => {
        setUserChanges({...userChanges, [key]: value});
    }

    const usetUserChanges = () => setUserChanges({updatedCreditValue: null, permissionLevel: ""});
    const cancelUnset = () => {
        cancelEdits();
        usetUserChanges();
    };
    const {
        loading,
        requestApiSuccess,
        requestErrorMessage,
        updateUserAttributes
    }: UpdateUserAttribueResponse = useUpdateUserAttributes();

    useEffect(() => {
        setUserChanges({
            updatedCreditValue: userToUpdate?.allocation_amount,
            permissionLevel: userToUpdate?.user_type,
        })
    }, [userToUpdate?.allocation_amount, userToUpdate?.user_type]);

    const submitCreditUpdate = () => {
        return updateUserAttributes(
                userToUpdate,
                {
                    creditAllocationAmount: updatedCreditValue,
                    resetLimitlessAllocation: updatedCreditValue === null,
                    permissionLevel
                    // permissionLevel: permissionLevel !== userToUpdate?.user_type ? permissionLevel : null,
                }
            )
                .then((res) => {
                    refetchData();
                    cancelUnset();
                })
                .catch((err) => {
                    console.error("Error Updating User Attributes: ", err);
                })
                .finally(() => {
                    usetUserChanges();
                });
    };
    const disabledSubmit = loading || (userToUpdate?.allocation_amount === updatedCreditValue && userToUpdate?.user_type === permissionLevel);

    return (
        <BaseModal
            isVisible={isVisible}
            headerText="Edit User Account"
            cancelAction={cancelEdits}
            className="base-form-modal"
        >
            <div
                className={classnames(
                    "primary-content",
                    "d-flex", "flex-column",
                    "justify-content-even",
                    "align-items-start",
                    "px-2", "m-3"
                )}
            >
                <div className={classnames("text-left", "text-wrap", "invite-subtext")}>
                    <p className={classnames("my-0")}> 
                        Limit the number of Contact Credits that <b>{userToUpdate?.email}</b> can consume.
                    </p>
                    <p>To lift the Contact Credit limit, simply save the value as blank.</p>
                </div>
                <div className={classnames("modal-form-container", "w-100", "d-flex", "flex-column", "my-2", "ms-1")}>
                    <div className={classnames("input-group", "username", "d-flex", "flex-column")}>
                        <label htmlFor="creditAmount">Contact Credit Limit</label>
                        <input
                            type="number"
                            name="creditAmount"
                            id="creditAmount"
                            min="0"
                            placeholder={!updatedCreditValue ? "No Limit" : ""}
                            value={updatedCreditValue !== null ? updatedCreditValue : ""}
                            onChange={(e) => {
                                if (e?.target?.value === "") {
                                    setUserAttribute("updatedCreditValue", null)
                                } else {
                                    setUserAttribute("updatedCreditValue", parseInt(e?.target?.value));
                                }
                            }}
                        />
                    </div>
                    <div className={classnames("input-group", "permissions", "d-flex", "flex-column")}>
                        <label htmlFor="permissionLevel">Role</label>
                        <div className="dropdown-container">
                            <select
                                name="permissionLevel"
                                id="permissionLevel"
                                value={userChanges.permissionLevel}
                                disabled={!(userToUpdate?.user_type === "STANDARD_USER" || userToUpdate?.user_type === "TENANT_ADMIN")}
                                onChange={(e) => setUserAttribute("permissionLevel", e?.target?.value)}
                            >
                                <option value="STANDARD_USER">Standard User</option>
                                <option value="TENANT_ADMIN">Admin</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className={classnames("button-container", "w-100", "d-flex", "justify-content-end", "mt-2", "mb-3")}>
                    <button
                        type="button"
                        onClick={cancelUnset}
                        disabled={loading}
                        className={classnames("btn", "btn-lg", "btn-outline-bf-blue", "mx-2")}
                    >
                        <span>Cancel</span>
                    </button>
                    <button
                        type="button"
                        onClick={submitCreditUpdate}
                        disabled={disabledSubmit}
                        className={classnames("btn"," btn-lg", "btn-bf-blue", "mx-2", {disabled: disabledSubmit})}
                    >
                        {!loading && <span>Update User</span>}
                        {loading && (
                            <span>
                                <span className={classnames("spinner-border", "spinner-border-sm")} />
                                <span className={classnames("ms-2")}>
                                    Updating
                                </span>
                            </span>
                        )}
                    </button>
                </div>
            </div>
        </BaseModal>
    );
};
