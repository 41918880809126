import React, { useEffect } from "react";
import { FormattingUtils } from "utils/index";
import { DropDown, DropDownControlledOpenProps } from "../DropDown";
// import StateCodeToNameFormatter from "components/utils/StateCodeToName";

export type CityStateOption = {
    searchValue: string,
    city: string,
    state: string,
    longState: string,
};

export class CityStateDropDownProps {
    title: string;
    options: CityStateOption[];
    values: any[];
    isLoading: boolean;
    onChange: (updatedValues: any[]) => void;
    onSearch: (searchText: string) => void;

    controlledOpen: DropDownControlledOpenProps;
    
    searchValue: string;
    onChangeSearchValue: (searchValue: string) => void;
}

export const CityStateDropDown = (props: CityStateDropDownProps) => {

    function updateCityState(cityState: string[]) {
        const rows = props.options.filter(x => cityState.includes(x.searchValue));
    
        const valuesWithoutDeletes = props.values
          .filter(x => cityState.includes(x.searchValue));
    
        const newValues = cityState
          .filter(x => !props.values.map(y => y.searchValue).includes(x))
          .map(x => rows.find(y => y.searchValue == x));
    
        const allValues = [
          ...valuesWithoutDeletes, 
          ...newValues
        ];
    
        // Remove cities where there is an all state
        const allStates = allValues.filter(x => !x.city).map(x => x.state);
        const updatedAllValues = allValues
          .filter(x => 
            !x.city ||
            !allStates.includes(x.state) 
          );
    
        props.onChange(updatedAllValues);
    };

    function formatCityState (city: string, shortState: string, longState: string) {
        if (city) {
          const fCity = city ? FormattingUtils.capitalizeFirstLetter(city?.toLowerCase()) : city;
          return `${fCity}, ${longState} (${shortState})`; 
        } else {
          return `${longState} (${shortState})`;
        }
    }

    function getSelectedCityStates() {
        const rows = props.values
          .map(r => ({
            value: r.searchValue,
            formattedValue: formatCityState(r.city, r.state, r.longState)
          }));
        return rows;
    }

    useEffect(() => {
      if (props.options == null && !props.isLoading) {
        props.onSearch("");
      }
    }, [props.options, props.isLoading]);

    return (
        <DropDown
            isDeleteable={true}
            values={getSelectedCityStates()}
            onChange={(e: any) => updateCityState(e)}
            options={
                props.options ?
                    props.options
                        .map(x => ({ 
                            option: x.searchValue, 
                            formattedOption: formatCityState(x.city, x.state, x.longState) 
                        })) :
                        []
            }
            title={props.title}
            asyncSearch={{
                isLoading: props.isLoading,
                onSearch: props.onSearch,
                searchValue: props.searchValue,
                updateSearchText: (searchText: string) => props.onChangeSearchValue(searchText)
            }}
            controlledOpen={props.controlledOpen}
        />
    );
};
