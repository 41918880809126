import { Box, Skeleton, Typography } from '@benefitflow/designsystem';

import { Benefit } from './components';
import { useBenefitsColumns } from './hooks/useBenefitsColumns';
import { useGetValueByScreenSizes } from 'new-beginning/hooks/useGetValueByScreenSizes';
import { useEmployeeSentiment } from 'new-beginning/components/pages/EmployerProfile/Tabs/components/EmployeeSentimentTab/hooks';

export const InsightsAcrossBenefits = () => {
	const { benefits, loading } = useEmployeeSentiment();
	const getValuesByScreenSize = useGetValueByScreenSizes();
	const numberOfColumns = getValuesByScreenSize({ lg: 3, md: 2, sm: 1 });
	const gridSpan = getValuesByScreenSize<4 | 6 | 12>({ lg: 4, md: 6, sm: 12 });
	const columns = useBenefitsColumns({ benefits, numberOfColumns });

	if (loading)
		return (
			<Box width="full">
				<Skeleton height="sm" width="xl6" />
				<Box display="grid" style={{ gridTemplateColumns: 'repeat(12,1fr)' }} gap="sm">
					{new Array(numberOfColumns).fill(null).map((_, i) => (
						<Box key={i} gridSpan={gridSpan}>
							{new Array(3).fill(null).map((_, j) => (
								<Box key={j} mb="sm">
									<Benefit.Loading
										numberOfRows={(() => {
											if (i % 2 === 0) return j % 2 === 0 ? 3 : 2;
											else return j % 2 === 0 ? 2 : 3;
										})()}
									/>
								</Box>
							))}
						</Box>
					))}
				</Box>
			</Box>
		);
	if (!benefits?.length) return null;

	return (
		<Box width="full">
			<Box alignItems="center" justifyContent="between" display="flex" mb="md">
				<Typography.Text color="neutral-600">Insights Across Benefits</Typography.Text>
			</Box>
			<Box display="grid" style={{ gridTemplateColumns: 'repeat(12,1fr)' }} gap="sm">
				{columns.map((benefits, index) => (
					<Box key={index} gridSpan={gridSpan}>
						{benefits.map((benefit, index) => (
							<Box mb="sm">
								<Benefit key={index} {...benefit} />
							</Box>
						))}
					</Box>
				))}
			</Box>
		</Box>
	);
};
