import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import MModal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import StyledBackdrop from './StyledBackdrop';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      width: "70%",
      height: "10%",
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: "20px",

    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
      width: "100%",

    },
    modal: {
      display: 'flex',
      marginTop: "150px",
      alignItems: 'top',
      justifyContent: 'center',


    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '0',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      height: "450px",
      width: "500px",
      fontFamily: "Roboto",
      borderRadius: "8px",
      textAlign: "center"
    },
    CtaButton:{
      backgroundColor: "#285ce4",
      height: "10%",
      color: "white",
      fontWeight: "bold",
      fontSize: "12pt",
      fontFamily: "Roboto",
      textTransform: "none",
      padding: "10px",
      borderRadius: "4px",
      marginLeft: "auto",
      marginRight: "auto",
      width: "70%",
      textAlign: "center",


      "&:hover": {
          backgroundColor: "#285ce4"
        }
    }
  }),
);

export interface ModalProps { 
    onClose: () => any;
    isOpen: boolean;
    children: any
}

export const Modal = (props: ModalProps) => {
  const classes = useStyles();

  return (
    <div>
      <MModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.isOpen}
        onClose={props.onClose}
        closeAfterTransition
        BackdropComponent={StyledBackdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.isOpen}>
          <div style={{outline: "none"}}>
            {props.children}
          </div>
        </Fade>
      </MModal>
    </div>
  );
}