import { Tag as DSTag } from '@benefitflow/designsystem';

import { useLabel, useOnDelete } from './hooks';

export const Tag = () => {
	const label = useLabel();
	const onDelete = useOnDelete();

	return <DSTag label={label} onDelete={onDelete} />;
};
