import { useEffect, useState } from 'react';

import { IncludeExcludeFilterProps } from '../IncludeExcludeFilter';
import { FormattingUtils } from '../../../../../../../ui-components';

export const useFormattedOptions = ({ options }: Pick<IncludeExcludeFilterProps, 'options'>) => {
	const [formattedOptions, setFormattedOptions] = useState([]);
	useEffect(() => {
		if (options?.length)
			setFormattedOptions(
				options.map(({ value, count }) => ({
					label: `${FormattingUtils.formatUpperCasing(value)} (${FormattingUtils.formatNumberAbbrev(count)})`,
					value: value,
					data: {},
				})),
			);
	}, [options, setFormattedOptions]);

	return formattedOptions;
};
