import DateUtils from 'utils/DateUtils';
import FormattingUtils from 'utils/FormattingUtils';
import uniq from 'lodash-es/uniq';
import { Policy } from 'common.model/src/types/services/broker-service/BrokerService';
import { IHistoricalPlan } from 'common.model/src/types/services/employer-service/IHistoricalPlan';

export default class EmployerDataUtils {
  
  static getBrokerPolicies = (policies: Policy[]) => {
    if (!policies) {
      return null;
    }
    const mapped = policies.map(p => {

      const isValidPepm =
        parseFloat(p.ins_prsn_covered_eoy_cnt) &&
        (parseFloat(p.wlfr_tot_charges_paid_amt) || parseFloat(p.wlfr_premium_rcvd_amt)) &&
        parseFloat(p.ins_prsn_covered_eoy_cnt) > 0;

      return {
        rowUniqueKey: `${p.plan_unique_key}-${p.form_id}-${p.row_num}`,
        carrierUniqueKey: `${p.ack_id}-${p.form_id}`,
        planUniqueKey: p.plan_unique_key,
        coveredLives: p.ins_prsn_covered_eoy_cnt,
        brokerCommission: p.broker_commissions?.toFixed(0),// ParseUtils.coalesceFloat(p.ins_broker_comm_pd_amt) + ParseUtils.coalesceFloat(p.ins_broker_fees_pd_amt),
        brokerName: p.master_broker_name,
        carrierPremium: p.carrier_premiums_original?.toFixed(0), // ParseUtils.coalesceFloat(p.wlfr_tot_charges_paid_amt) + ParseUtils.coalesceFloat(p.wlfr_premium_rcvd_amt),
        carrierName: p.carrier_master_name,
        renewalDate: DateUtils.addDays(new Date(p.ins_policy_to_date), 1),
        pepm: !isValidPepm
          ? 'N/A'
          : (p.carrier_premiums_original / parseFloat(p.ins_prsn_covered_eoy_cnt) / 12).toFixed(2),
        benefitsOffered: p.lob_categories_list,// benefitsOffered.join(', '),
        otherText: p.wlfr_type_bnft_oth_text,
        isMedical:
          p.lob_categories_list?.includes('Health') ||
          p.lob_categories_list?.includes('PPO') ||
          p.lob_categories_list?.includes('HMO'),
      };
    });
    return mapped;
  };

  static getColorMapping = (data, getName, getValue): Record<string, string> => {
    if (!data || data.length == 0) {
      return null;
    }
    const colors = ['#2CDDC7', '#FFC000', '#59A7FF', '#202C59'];
    const grey = '#849AA9';
    const nameValueSumMap = data
      ?.map(x => ({
        name: getName(x),
        value: parseFloat(getValue(x)),
      }))
      .filter(x => x && !Number.isNaN(x.value))
      .reduce((a, b) => {
        if (!a[b.name]) {
          a[b.name] = 0;
        }
        a[b.name] += b.value;
        return a;
      }, {});
    const colorsMap = Object.entries(nameValueSumMap)
      .sort((a, b) => {
        return (b[1] as number) - (a[1] as number);
      })
      .map(arr => arr[0])
      .map((name, ix) => ({
        name: name,
        color: ix < colors.length ? colors[ix] : grey,
      }))
      .reduce((a, b) => {
        a[b.name] = b.color;
        return a;
      }, {});
    return colorsMap;
  };

  static getBarChartConfig = (policies: Policy[], getName, getValue) => {
    if (!policies) {
      return;
    }
    // Get the distinct years
    const distinctYears = Object.keys(
      policies
        .map(x => x.filing_year)
        .reduce((a, b) => {
          a[b] = true;
          return a;
        }, {}),
    ).sort((a, b) => parseInt(a) - parseInt(b)); // ascending order

    const colorMapping = EmployerDataUtils.getColorMapping(
      policies,
      getName,
      getValue,
    );

    const nameValuesMap = policies.reduce((a, b) => {
      if (!a[getName(b)]) {
        a[getName(b)] = {};
      }
      if (!a[getName(b)][b.filing_year]) {
        a[getName(b)][b.filing_year] = 0;
      }
      const parsed = parseFloat(getValue(b));
      a[getName(b)][b.filing_year] += isNaN(parsed) ? 0 : parsed;
      return a;
    }, {});
    console.log('nameValuesMap', nameValuesMap);

    const dataSets = Object.keys(nameValuesMap)
      .map(x => {
        const name = x;
        const values = distinctYears.map(y => {
          if (nameValuesMap[x][y]) {
            return nameValuesMap[x][y];
          }
          return 0;
        });
        return {
          label: FormattingUtils.formatUpperCasing(name),
          data: values,
          backgroundColor: colorMapping[name],
        };
      })
      .sort((a, b) => {
        const bVal = b.data.reduce((a, b) => a + b, 0);
        const aVal = a.data.reduce((a, b) => a + b, 0);
        return bVal - aVal;
      });
    console.log('dataSets', dataSets);

    return {
      labels: distinctYears,
      datasets: dataSets,
    };
  };

  static getMultiLineChartConfig = (activeParticipants: IHistoricalPlan[], getName, getValue) => {
    if (!activeParticipants) {
      return {
        labels: [],
        datasets: {}
      };
    }
    const labels = uniq(
      Object.values(activeParticipants || {}).reduce(
        (res: any, item: any) => [...res, ...item.map(c => c.filing_year)],
        [],
      ), // @ts-ignore-next-line
    ).sort((a, b) => (a - b > 0 ? 1 : -1));

    const datasets = Object.keys(activeParticipants || {})
      .map(plan => {
        const getData = year => {
          const matched = (activeParticipants[plan] || []).find(
            item => item.filing_year === year,
          );

          return matched ? getValue(matched) : 0;
        };

        const colorMapping = EmployerDataUtils.getColorMapping(
          Object.values(activeParticipants || {}).reduce(
            (res: any, item: any) => [...res, ...item],
            [],
          ),
          getName,
          getValue,
        );

        return {
          label: getName(activeParticipants[plan][0]),
          data: labels.map(year => getData(year)),
          borderColor: colorMapping[getName(activeParticipants[plan][0])],
          backgroundColor: colorMapping[getName(activeParticipants[plan][0])],
        };
      })
      .sort((a: any, b: any) => {
        const getTotalValue = obj => obj.data.reduce((sum, i) => sum + i, 0);

        return getTotalValue(b) - getTotalValue(a);
      });

    return {
      labels,
      datasets,
    };
  };

}
