import { Box, Space, Tooltip, Typography, Icon as DSIcon, Skeleton } from '@benefitflow/designsystem';

import { Icon } from './components';
import { ContactDTO } from 'new-beginning/queries';

export const VerifiedProducerBadge = ({ licenses, isVerified, loading }: Pick<ContactDTO, 'licenses' | 'isVerified'> & { loading: boolean }) => {
	if (loading) return <Skeleton height="md" width="xl4" />;
	if (!isVerified || licenses?.length < 1) return null;
	return (
		<Tooltip
			behavior="click"
			placement="bottom-start"
			strategy="fixed"
			preventAutoPositioning
			content={
				<Box p="sm" display="flex" flexDirection="column" gap="sm" style={{ cursor: 'pointer' }}>
					<Typography.Text whiteSpace="nowrap" size="md">
						Insurance Licenses
					</Typography.Text>
					{licenses.map(({ name, since }, index) => (
						<Box display="flex" justifyContent="between" key={index}>
							<Space size="xs">
								<Icon checked={!!since} />
								<Typography.Text size="sm" weight="medium" whiteSpace="nowrap" color="neutral-600">
									{name}
								</Typography.Text>
							</Space>
							<Box width="xl3"></Box>
							{since && (
								<Typography.Text size="sm" whiteSpace="nowrap" weight="regular" color="neutral-500">
									Since {since}
								</Typography.Text>
							)}
						</Box>
					))}
				</Box>
			}
		>
			{(ref) => (
				<Box style={{ cursor: 'pointer' }} display="inline-block" containerRef={ref}>
					<Box p="xxs" display="flex" gap="xxs" alignItems="center" justifyContent="center" backgroundColor="secondary-100" borderRadius="sm">
						<DSIcon name="verified-fill" color="secondary-500" size="md" />
						<Typography.Text whiteSpace="nowrap" size="xs" color="neutral-500">
							Verified Producer
						</Typography.Text>
					</Box>
				</Box>
			)}
		</Tooltip>
	);
};
