import cn from 'classnames';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import { ContactListFilter } from 'common.model/src/db/model-decorators/type-extensions';

interface LoaderConfig {
	header: (actionType: 'SYNC' | 'REFRESH') => string;
	subText: (actionType: 'SYNC' | 'REFRESH', contactFilter: ContactListFilter, contactsSelected) => string;
}

const actionTypeText: Record<'SYNC' | 'REFRESH', string> = {
	SYNC: 'Sync',
	REFRESH: 'Refresh',
};

const ListJobLoaderLabelMap: LoaderConfig = {
	header: (actionType) => (actionTypeText?.[actionType] ? `${actionTypeText?.[actionType]} in Progress` : null),
	subText: (actionType, contactFilter, contactsSelected) => {
		if (actionType === 'REFRESH') {
			return 'Refreshing All Contacts. This may take a minute based on the number of Contacts in your List.';
		}

		const allSelected = contactsSelected?.['ALL'] === true;
		const numSelectedContacts =
			Object.entries(contactsSelected)?.filter(([key, selected]) => key !== 'ALL' && selected === true)?.length || 0;

		const operatingOnAllContacts = !numSelectedContacts || allSelected;
		const operatingOnTab = allSelected && contactFilter !== 'All' && !numSelectedContacts;
		const selectSyncText = 'Syncing Contacts' + (operatingOnTab ? ` within the ${contactFilter} Tab` : '');
		const syncText =
			operatingOnAllContacts || operatingOnTab
				? selectSyncText
				: `Syncing ${numSelectedContacts} Selected Contacts within the ${contactFilter} Tab`;
		return `${syncText}. This may take a minute based on the number of Contacts.`;
	},
};

interface ListJobLoaderProps {
	contactFilter: ContactListFilter;
	contactsSelected: Record<string, boolean>;
	jobLoading: boolean;
	actionType?: 'SYNC' | 'REFRESH';
}

export const ListJobLoader = ({ contactFilter, contactsSelected, jobLoading = false, actionType }: ListJobLoaderProps) => {
	const textHeader = ListJobLoaderLabelMap?.header(actionType);
	const textBody = ListJobLoaderLabelMap.subText(actionType, contactFilter, contactsSelected);

	return (
		<Snackbar open={jobLoading && !!textHeader} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
			<SnackbarContent
				className={cn('bg-white')}
				message={
					<div style={{ maxWidth: '325px' }} className={cn('d-flex', 'align-items-center')}>
						<div className='text-primary'>
							<span className={cn('spinner-border', 'spinner-border-md')} style={{ animationDuration: '1.25s' }} />
						</div>
						<div className={cn('d-flex', 'flex-column', 'align-items-start', 'mx-3')}>
							<div className={cn('bf-txt', 'txt-lg', 'txt-bolder', 'txt-dark')}>{textHeader}</div>
							<div className={cn('bf-txt', 'txt-neutral')}>{textBody}</div>
						</div>
					</div>
				}
			/>
		</Snackbar>
	);
};
