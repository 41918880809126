import { useContext, useEffect, useState } from 'react';

import { ContactsListContext } from '../ContactsList.context';

export const useSidebarDimensions = (ref: React.MutableRefObject<HTMLDivElement>) => {
	const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
	const { filters } = useContext(ContactsListContext);

	useEffect(() => {
		if (ref?.current) setDimensions({ width: ref.current.offsetWidth, height: ref.current.offsetHeight });
	}, [ref, filters]);

	return dimensions;
};
