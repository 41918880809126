import { create } from 'zustand';

export interface ByServiceFilterStore {
	collapsible: {
		map: Record<(typeof COLLAPSIBLE_MAP_KEYS)[number], boolean>;
		toggle: Record<(typeof COLLAPSIBLE_MAP_KEYS)[number], () => void>;
		clear: () => void;
	};
}

export const COLLAPSIBLE_MAP_KEYS = ['hqLocation'] as const;

export const useByServiceFilterStore = create<ByServiceFilterStore>((set) => ({
	collapsible: {
		map: COLLAPSIBLE_MAP_KEYS.reduce(
			(acc, key) => {
				acc[key] = false;
				return acc;
			},
			{} as Record<(typeof COLLAPSIBLE_MAP_KEYS)[number], boolean>
		),
		toggle: COLLAPSIBLE_MAP_KEYS.reduce(
			(acc, key) => {
				acc[key] = () =>
					set((state) => ({
						collapsible: {
							...state.collapsible,
							map: {
								...Object.keys(state.collapsible.map).reduce(
									(acc, cur) => ({ ...acc, [cur]: false }),
									{} as Record<(typeof COLLAPSIBLE_MAP_KEYS)[number], boolean>
								),
								[key]: !state.collapsible.map[key],
							},
						},
					}));
				return acc;
			},
			{} as Record<(typeof COLLAPSIBLE_MAP_KEYS)[number], () => void>
		),
		clear: () =>
			set((state) => ({
				collapsible: {
					...state.collapsible,
					map: COLLAPSIBLE_MAP_KEYS.reduce(
						(acc, cur) => ({ ...acc, [cur]: false }),
						{} as Record<(typeof COLLAPSIBLE_MAP_KEYS)[number], boolean>
					),
				},
			})),
	},
}));
