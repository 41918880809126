import { useCallback, useContext, useMemo, useState } from 'react';
import { addNotification } from '@benefitflow/designsystem';
import { Notifications } from '../../../../../constants/notifications';
import ExportStatus from 'common.model/src/types/services/data-export-service/ExportStatus';
import { APIContext } from 'new-beginning/services/api/Api.context';
import { OnExportParameters } from 'new-beginning/context/ExportContext';
import { useExportMutation } from 'new-beginning/mutations/useExportMutation';

export const useOnExport = () => {
	const [exportId, setExportId] = useState<string>();
	const [exportStatus, setExportStatus] = useState<ExportStatus>();
	const { dataExportApi } = useContext(APIContext);

	const { mutate, isLoading } = useExportMutation({
		options: {
			onSuccess: ({ exportId, status }) => {
				setExportId(exportId);
				setExportStatus(status as ExportStatus);
			},
			onError: (e) => {
				console.error(e);
			},
		},
	});

	const onExport = useCallback(
		(p: OnExportParameters) => {
			if (p.type === 'brokerContacts')
				mutate({
					params: {
						filters: p.params.filters,
						checkedMap: p.params.checkedMap,
						notCheckedMap: p.params.notCheckedMap,
						page: 0,
					},
					type: p.type,
				});
			if (p.type === 'brokerContactsList') {
				mutate({
					params: {
						filters: p.params.filters,
						contactsCheckedMap: p.params.checkedMap,
						contactsNotCheckedMap: p.params.notCheckedMap,
						isSelectAll: false,
						itemType: 'BROKER_CONTACT',
						listId: p.params.listId,
						page: 0,
					},
					type: p.type,
				});
			}
			if (p.type === 'employerContactsList') {
				mutate({
					params: {
						filters: p.params.filters,
						contactsCheckedMap: p.params.checkedMap,
						contactsNotCheckedMap: p.params.notCheckedMap,
						isSelectAll: false,
						itemType: 'EMPLOYER_CONTACT',
						listId: p.params.listId,
						page: 0,
					},
					type: 'brokerContactsList',
				});
			}
			if (p.type === 'broker') {
				mutate({
					params: { ...p.filters, page: 0 },
					type: 'broker',
				});
			}
			if (p.type === 'brokerOffice') {
				mutate({
					params: { ...p.filters, page: 0 },
					type: 'brokerOffice',
				});
			}
			if (p.type === 'employerContacts') {
				mutate({
					params: {
						checkedMap: p.checkedMap,
						notCheckedMap: p.notCheckedMap,
						filters: p.filters,
						page: 0,
					},
					type: 'employerContacts',
				});
			}
			if (p.type === 'employer') {
				mutate({
					params: { ...p.filters, page: 0 },
					type: 'employer',
				});
			}
			if (p.type === 'policy') {
				mutate({
					params: { ...p.filters, page: 0 },
					type: 'policy',
				});
			}
		},
		[mutate],
	);

	dataExportApi.useCheckExportStatus(
		{ exportId },
		{
			enabled: !!exportId,
			onSuccess: (status) => {
				setExportStatus(status.status);
				if (status.status === ExportStatus.Failed) {
					addNotification(Notifications.Export.Error);
					setExportStatus(undefined);
					setExportId(undefined);
					return;
				}
			},
			refetchInterval: () => {
				if (!exportId) return false;
				if (exportStatus === ExportStatus.Complete) return false;
				if (exportStatus === ExportStatus.Failed) return false;
				if (exportStatus === ExportStatus.Downloaded) return false;
				return 1000;
			},
		},
	);

	dataExportApi.useDownloadExport(
		{ exportId },
		{
			enabled: exportStatus === ExportStatus.Complete,
			onSuccess: (downloadLink) => {
				setExportStatus(undefined);
				setExportId(undefined);
				addNotification(Notifications.Export.Success);
				const link = document.createElement('a');
				link.href = downloadLink;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			},
		},
	);

	const isExporting = useMemo(() => !!exportId || isLoading, [exportId, isLoading]);

	return { onExport, isExporting };
};
