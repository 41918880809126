import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { listsDetailPageActions } from 'app/containers/ListsPage/containers/ListsDetailPage/slice';
import { selectListsDetailPage } from 'app/containers/ListsPage/containers/ListsDetailPage/selectors';

export const useOnExclude = () => {
	const dispatch = useDispatch();
	const { contactFilters } = useSelector(selectListsDetailPage);

	const onExclude = useCallback(
		({ value }: { value: string }) => {
			dispatch(
				listsDetailPageActions.updateContactFilters({
					...contactFilters,
					licenseTypes: {
						exclude: [...contactFilters.licenseTypes.exclude, value],
						include: contactFilters.licenseTypes.include,
					},
				}),
			);
		},
		[dispatch, contactFilters],
	);

	return onExclude;
};
