import React, { useMemo } from 'react';
import { ToolTip } from '../../ToolTip1';
import './style.scss';
import { FormattingUtils } from "utils/index";


interface IData {
  name: string;
  value?: any;
  color: string;
}

export interface StackedBarProps {
  className?: string;
  data: IData[];
  showTotal?: boolean;
  getToolTipConfig: (data: IData[]) => any;
  maxValue: any;
}

export const StackedBar = ({ showTotal, data, maxValue, getToolTipConfig }: StackedBarProps) => {
  const totalValue = useMemo(
    () =>
      data.reduce((a, b) => {
        const parsed = parseFloat(b.value);
        const val = isNaN(parsed) ? 0 : parsed;
        return a + val;
      }, 0),
    [data],
  );

  const numMissingValues = useMemo(
    () => data.filter(x => isNaN(parseFloat(x.value))).length,
    [data],
  );
  const { title, keyValuePairs } = getToolTipConfig(data);

  return (
    <div style={{ width: '100%', textAlign: 'left' }}>
      {!data[0].value ? (
        <span>N/A</span>
      ) : (
        <ToolTip title={title} keyValuePairs={keyValuePairs}>
          <div className="simple-bar">
            {data.map((x, index) => {
              let percentage =
                (((parseFloat(x.value) / totalValue) * totalValue) /
                  parseFloat(maxValue)) *
                  100 -
                (5 * numMissingValues * parseFloat(x.value)) / totalValue;
              percentage = isNaN(percentage) ? 5 : percentage;
              return (
                <>
                  <div
                    className="wrap"
                    style={{
                      display: 'inline-block',
                      width: `${percentage}%`,
                      backgroundColor: x.color,
                    }}
                  >
                    <div className="bar" style={{ width: '100%' }}></div>
                    <span style={{ color: 'transparent' }}>{'_'}</span>
                  </div>
                </>
              );
            })}
            {showTotal && (
              <span>{FormattingUtils.formatNumberAbbrev(totalValue)}</span>
            )}
          </div>
        </ToolTip>
      )}
    </div>
  );
};
