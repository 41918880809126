import { useContext, useMemo } from 'react';

import { ListDetailsContext } from '../../../ListDetails.context';

export const useLoading = () => {
	const { loading: filtersAreLoading, options } = useContext(ListDetailsContext);
	const loading = useMemo(() => filtersAreLoading && !options.companyName, [filtersAreLoading, options]);

	return loading;
};
