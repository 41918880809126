export const useYearsAtCompany = () => {
	return {
		options: {
			start: MinYears,
			end: MaxYears,
		},
	};
};

export const MinYears = [
	{ label: 'No Min', value: 0 },
	{ label: '1 year', value: 1 },

	...new Array(19).fill(null).map((_, i) => ({ label: `${i + 2} years`, value: i + 2 })),
];

export const MaxYears = [
	{ label: '1 year', value: 1 },
	...new Array(19).fill(null).map((_, i) => ({ label: `${i + 2} years`, value: i + 2 })),
	{ label: 'No Max', value: 21 },
];
