import { Box } from '@benefitflow/designsystem';
import { EmployeePerception, Summary } from './components';
import { useGetValueByScreenSizes } from 'new-beginning/hooks/useGetValueByScreenSizes';

export const Info = () => {
	const getValueByScreenSize = useGetValueByScreenSizes();

	return (
		<Box display="grid" style={{ gridTemplateColumns: 'repeat(12,1fr)' }} gap="sm">
			<Box gridSpan={getValueByScreenSize({ lg: 4, md: 12, sm: 12 })} height="full">
				<EmployeePerception />
			</Box>
			<Box gridSpan={getValueByScreenSize({ lg: 8, md: 12, sm: 12 })} height="full">
				<Summary />
			</Box>
		</Box>
	);
};
