import cn from 'classnames';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa6';

export const Header: FC = () => {
	const navigate = useNavigate();

	return (
		<div className={cn('pb-2', 'w-100')}>
			<div className={cn('bf-header', 'sync-page-header', 'p-3', 'rounded', 'bg-white', 'w-100')}>
				<div className={cn('d-flex', 'justify-content-between', 'flex-column', 'align-items-start')}>
					<div className={cn('d-flex', 'align-items-center', 'justify-content-start', 'flex-wrap', 'flex-wrap-space')}>
						<button onClick={() => navigate(-1)} className={cn('btn', 'btn-outline', 'btn-lg', 'border', 'me-2', 'p-2')}>
							<FaArrowLeft size={16} />
						</button>
						Lists
					</div>
				</div>
			</div>
		</div>
	);
};
