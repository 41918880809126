import { Fragment } from 'react';
import { ContactRow, RowDetail, LoadingRowSet } from 'new-beginning/components/pages/ListSync/SyncTable/TableRows';
import { ListContactType } from 'common.model/src/db/model-decorators/type-extensions';
import { EmptyResultRow } from 'new-beginning/components/shared/Table/EmptyResultRow';
import { SalesforceUsersById } from 'new-beginning/hooks/salesforce/useContactOwner';

interface ContactRowSetProps {
	syncListContacts: (syncContactOverride?: Record<string, boolean>) => void;
	salesforceUsersById: SalesforceUsersById;
	listContacts: ListContactType[];
	syncInProgress: boolean;
	jobLoading: boolean;
	listLoading: boolean;
	rowCount: number;
	selectedRows: Record<string, boolean>;
	expandedRows: Record<string, boolean>;
	allContactSelected: boolean;
	selectRow: (rowId: string, selected: boolean) => void;
	expandRow: (rowId: string) => void;
	isAdmin: boolean;
}

export const ContactRowSet = ({
	listContacts,
	salesforceUsersById,
	syncListContacts,
	syncInProgress,
	jobLoading,
	listLoading,
	rowCount,
	selectedRows,
	expandedRows,
	selectRow,
	expandRow,
	allContactSelected,
	isAdmin,
}: ContactRowSetProps) => {
	const isLoading = jobLoading || listLoading;
	const hasContacts = (listContacts?.length || 0) > 0;

	return (
		<tbody>
			{listLoading && <LoadingRowSet rowCount={rowCount} />}
			{!listLoading && hasContacts ? (
				listContacts?.map((listContact) => {
					const contactOwnerId = listContact?.contactDiffItem?.merged_contact_payload?.OwnerId || listContact?.sObjectContact?.OwnerId;
					const contactOwner = salesforceUsersById?.[contactOwnerId]?.Username;

					return (
						<Fragment key={listContact?.contact?.id}>
							<ContactRow
								listContact={listContact}
								salesforceContactOwner={contactOwner}
								directSyncContact={(listItemId: string) => syncListContacts({ [listItemId]: true })}
								selectRow={selectRow}
								expandRow={expandRow}
								isSelected={selectedRows?.[listContact?.contact?.id] === true}
								isExpanded={expandedRows?.[listContact?.contact?.id] === true}
								allSelected={allContactSelected}
								loading={isLoading}
								syncInProgress={syncInProgress}
								isAdmin={isAdmin}
							/>
							<RowDetail listContact={listContact} />
						</Fragment>
					);
				})
			) : (
				<EmptyResultRow colSpan={4} message='No Contacts Found' />
			)}
		</tbody>
	);
};
