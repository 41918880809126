import React, {useState} from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Skeleton from "@material-ui/lab/Skeleton";
import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from '@material-ui/icons/FilterList';
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import { Skeleton as DSSkeleton } from '@benefitflow/designsystem';
import { 
  SecondarySpanSmall,
  SecondarySpanMedium,  
  TerciarySpanLarge, 
  PrimaryScrollBarContainer
} from 'atoms/StyledComponents';
import { terciaryColor, secondaryColor, primaryColor } from 'designTokens/colors';
import Divider from '@material-ui/core/Divider';


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#FFFFFF", //header fill
    color: "#000000", //header font color
    fontFamily: "Roboto",
    fontSize: 18,
    fontWeight: "bold",
    border: "none"//"1px solid #e0e0e0"
    // position: "fixed"
  },
  body: {
    fontFamily: "Roboto",
    color: "#000000",
    fontSize: 14,
    border: "none" //"1px solid #e0e0e0"
  }
}))(TableCell);

const StyledTableCellClickable = withStyles((theme) => ({
  body: {
    color: "#1A0DAB",
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: "bold",
    cursor: "pointer",
    border: "none"
  }
}))(TableCell);

const StyledTableCellTitle = withStyles((theme) => ({
  head: {
    backgroundColor: "#FFFFFF", //header title fill
    color: "#000000", //header title font color
    fontFamily: "Roboto",
    fontSize: 18,
    border: "none"
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#F7F7F7"
    },
    border: "none"// "1px solid #e0e0e0"
  }
}))(TableRow);

const StyledFooterTableRow = withStyles((theme) => ({
  root: {    
    backgroundColor: "white",
    border: "none",//"1px solid #e0e0e0",
    // borderTop: "double",
    position: "sticky",
    bottom: "0px"
  }
}))(TableRow);

const StyledTableHead = withStyles((theme) => ({
  root: {
    position: "sticky",
    top: 0
  }
}))(TableHead); 

const StyledMenu = withStyles({
  paper: {
    border: "none"
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));


const useStyles = makeStyles({
  table: {
    minWidth: "100%"
  },
  menuPaper: {
    maxHeight: "250px",
    background: "#FFFFFF",
    border: "0.5px solid rgba(0, 0, 0, 0.09);",
    marginTop: "15px",
    // boxSizing: "border-box",
    boxShadow: "2px 4px 31px rgba(0, 0, 0, 0.07)",
    transitionDuration: "0s !important",
    '&::-webkit-scrollbar': {
        width: "4px",
        height: '0px'
    },
    '&::-webkit-scrollbar-track': {
        background: "#f1f1f1"
    },
    '&::-webkit-scrollbar-thumb': {
        background: "#C4C4C4",
        borderRadius: "2px"
    },
    '&::-webkit-scrollbar-thumb:hover': {
        background: "#555"
    }
  }
});

class FilterParamsConfig {
    filterValueFormatter?: (val) => any;
    filterValues: string[];
    onChangeFilter: (allFilters) => any;
}

export class ColConfig {
    headerName: string;
    subHeaderName?: string;
    fieldName: string;
    fieldValueFormatter?: (row) => any;
    includeFilter: boolean;
    filterParams?: FilterParamsConfig;
    isClickable: boolean;
    formatTotalRow: boolean;
}

export class RowStyleConfig {
  rowPredicate: (row) => boolean;
  style: any;
}

export interface ProviderTableProps {
    values: any[];
    totalRow: any;
    colConfigs: ColConfig[];
    rowKey: string;
    height: string;
    rowStyles?: RowStyleConfig[];
    totalRowStyles?: any[]; 
}

export function ProviderTable(props: ProviderTableProps) {
  const classes = useStyles();
  const [page, setPage] = useState(1);

  const anchorElsObj = props.colConfigs.reduce((a,b) => {
      a[b.fieldName] = null;
      return a;
  }, {})
  const [anchorEls, changeAnchorElsState] = useState(anchorElsObj);

  const changeAnchorEls = function(newValue, fieldName: string) {
    changeAnchorElsState({...anchorEls, [fieldName] : newValue});
  }

  const getFilteredValues = function() {
    const filtersToApply = props.colConfigs
        .filter(x => x.includeFilter && x.filterParams.filterValues && Object.keys(x.filterParams.filterValues).length > 0);
    const isGoodRowFunc = (row) => filtersToApply.length == 0 || 
        filtersToApply
            .map(x => x.filterParams.filterValues[row[x.fieldName]] != null)
            .reduce((a,b) => a && b, true)
    return props.values.filter(isGoodRowFunc);
  }


  React.useEffect(() => {

    const isBottom = (el) => {
      const scrollableHeight = el.scrollHeight;
      const scrolledHeight =  el.scrollTop + el.getBoundingClientRect().height;
      return scrolledHeight >= (scrollableHeight * .95)
    }
    
    const trackScrolling = () => {
      const el = document.getElementById("PivotTableContainer")
      if (isBottom(el)) {
        setPage(page + 1);
      }
    }
    
    document.getElementById("PivotTableContainer").addEventListener('scroll', trackScrolling);

    return () => {
      document.getElementById("PivotTableContainer")?.removeEventListener('scroll', trackScrolling);
    };
  }, [props.values, props.colConfigs, page, anchorEls]);

  const areAnySubHeaders = props.colConfigs.map(x => x.subHeaderName != null).reduce((a,b) => a || b, false);

  const baseTotalRowStyles = { bottom: "-2px", backgroundColor: props.values && props.values.length % 2 == 0 ? "#F7F7F7" : "white" }
  const totalRowStyles = props.totalRowStyles ? 
      props.totalRowStyles
        .reduce((a,b) => {
          return ({
            ...a,
            ...b.style
          });
        }, baseTotalRowStyles) :
        baseTotalRowStyles;

  return (
    <PrimaryScrollBarContainer id="PivotTableContainer" style={{ height: props.height, borderRadius: "8px", paddingRight: "20px", paddingLeft: "20px" }}>
      <Table className={classes.table} aria-label="customized table">
        <StyledTableHead>
          <TableRow>
            {
                props.colConfigs.map(curColConfig => {
                    const subHeader = curColConfig.subHeaderName ? 
                      <span style={{ fontSize: "14px", color: "grey", fontStyle: "italic"}}>{curColConfig.subHeaderName}</span> :    
                      <span style={{"color": "transparent"}}>_</span>
                    if (curColConfig.includeFilter) {
                        return (
                            <StyledTableCellTitle style={{whiteSpace: "nowrap", left: 0}} align="left">
                                <TerciarySpanLarge style={{ fontWeight: 300 }}>
                                  <b> { curColConfig.headerName } </b>
                                </TerciarySpanLarge>                                
                                <IconButton
                                    onClick={(event: React.MouseEvent<HTMLElement>) => changeAnchorEls(event.currentTarget, curColConfig.fieldName)}
                                    style={{ padding: "0", color: terciaryColor }}
                                >
                                    <FilterListIcon style={{ width: ".75em"}} />
                                </IconButton>
                                    <StyledMenu
                                      id="customized-menu"
                                      anchorEl={anchorEls[curColConfig.fieldName]}
                                      classes={{ paper: classes.menuPaper }}
                                      keepMounted
                                      open={Boolean(anchorEls[curColConfig.fieldName])}
                                      style={{ maxHeight: "450px" }}
                                      onClose={() => changeAnchorEls(null, curColConfig.fieldName)}
                                    >
                                    {
                                        props.values ?
                                            Object.keys(
                                                props.values
                                                    .map(r => r[curColConfig.fieldName])
                                                    .reduce((a,b) => {
                                                        a[b] = 1;
                                                        return a;
                                                    }, {})
                                            )
                                            .sort()
                                            .map(x => {
                                                return (

                                                <MenuItem 
                                                  key={x} 
                                                  value={x}
                                                  onClick={() => {
                                                      const cpy = JSON.parse(JSON.stringify(curColConfig.filterParams.filterValues));
                                                      if (cpy[x]) {
                                                        delete cpy[x];
                                                      } else {
                                                        cpy[x] = true;
                                                      }
                                                      curColConfig.filterParams.onChangeFilter(cpy);
                                                  }}
                                                >

                                                  <Checkbox
                                                      // className={classes.buttonBase}
                                                      checked={curColConfig.filterParams.filterValues[x]}
                                                      checkedIcon={<Check style={{ color: primaryColor }} />}
                                                      disableRipple
                                                  />
                                                  <SecondarySpanMedium>{ curColConfig.filterParams.filterValueFormatter ? curColConfig.filterParams.filterValueFormatter(x) : x }</SecondarySpanMedium>
                                                  {/* <ListItemText primary={ curColConfig.filterParams.filterValueFormatter ? curColConfig.filterParams.filterValueFormatter(x) : x } /> */}
                                                  <Divider style={{ position: "absolute", bottom: 0, width: "calc(100% - 30px)", left: "15px", opacity: "0.3", color: "#B7C2CF" }} />                        
                                                </MenuItem>
                                                )   
                                            }) : null
                                    }
                                    </StyledMenu>
                                { areAnySubHeaders ? <br /> : null }
                                { subHeader }
                            </StyledTableCellTitle>
                        );
                    }
                    else {
                        return (
                            <StyledTableCellTitle style={{ whiteSpace: "nowrap", left: 0}} align="left">
                                <TerciarySpanLarge style={{ fontWeight: 300 }} >
                                  <b> { curColConfig.headerName } </b>
                                </TerciarySpanLarge>
                                { areAnySubHeaders ? <br /> : null }
                                { subHeader }
                            </StyledTableCellTitle>
                        )
                    }
                })
            }
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {
              props.values ?
                getFilteredValues()
                    .slice(0,page*25)
                    .map((row) => {
                        const styles = props.rowStyles ? 
                          props.rowStyles
                            .filter(x => x.rowPredicate(row))
                            .reduce((a,b) => {
                              return ({
                                ...a,
                                ...b.style
                              });
                            }, {}) :
                            {};
                        return (
                            <StyledTableRow key={row[props.rowKey]} style={styles}>
                                {
                                    props.colConfigs.map(c => {
                                        let value = c.fieldValueFormatter ? c.fieldValueFormatter(row) : row[c.fieldName];
                                        if (!value) {
                                          value = '-';
                                        }
                                        if (c.isClickable) {
                                            return (
                                                <StyledTableCellClickable component="th" scope="row">
                                                  <SecondarySpanSmall style={{ fontSize: "13px" }}>
                                                    { value }
                                                  </SecondarySpanSmall>
                                                </StyledTableCellClickable>
                                            )
                                        } else {
                                            return (
                                                <StyledTableCell style={{whiteSpace: "nowrap"}} align="left">
                                                  <SecondarySpanSmall style={{ fontSize: "13px" }}>
                                                    { value }
                                                  </SecondarySpanSmall>
                                                </StyledTableCell>
                                            );
                                        }
                                    })
                                }
                            </StyledTableRow>
                        )
                    }) : 
                (new Array(25).fill(0)).map((x, ix) => (
                  <StyledTableRow key={ix}>
                  {
                    (new Array(props.colConfigs.length).fill(0)).map(y => {
                      return (
                        <StyledTableCell style={{whiteSpace: "nowrap"}} align="right"><DSSkeleton height="sm" width="xl5" /></StyledTableCell>
                      )
                    })

                  }
                  </StyledTableRow>
                ))
          }
          {
              // Footer Row
              props.totalRow ? 
                <StyledFooterTableRow style={totalRowStyles}>
                    {
                        props.colConfigs.map(c => {                          
                            const value = c.fieldValueFormatter && c.formatTotalRow ? c.fieldValueFormatter(props.totalRow) : props.totalRow[c.fieldName];
                            return (
                                <StyledTableCell style={{whiteSpace: "nowrap", color: secondaryColor }} align="left">
                                    <b>{ value }</b>
                                </StyledTableCell>
                            );
                        })
                    }
                </StyledFooterTableRow> :null
          }
        </TableBody>
      </Table>
    </PrimaryScrollBarContainer>
  );
}
