import { useContext, useMemo } from 'react';

import { ListDetailsContext } from '../../../ListDetails.context';

export const useLoading = () => {
	const {
		loading: optionsAreLoading,
		options: { roles },
	} = useContext(ListDetailsContext);
	const loading = useMemo(() => optionsAreLoading && !roles, [optionsAreLoading, roles]);

	return loading;
};
