import { useMemo } from 'react';

export const useDurationLabel = ({
	sufix,
	months,
}: {
	/**
	 * Number of months
	 */
	months?: number | string;
	/**
	 * Sufix to be added to the label
	 * @example 'at Company'
	 */
	sufix: string;
}) => {
	const label = useMemo(() => {
		const value = Number(months);
		if (!value) return null;
		if (value < 12) return { value, label: `Mo. ${sufix}` };
		if (value < 23) return { value: 1, label: `Year ${sufix}` };
		return { value: Math.floor(value / 12), label: `Yrs. ${sufix}` };
	}, [months, sufix]);

	return label;
};
