import ListUtils from "utils/ListUtils";

export default class BenefitsTableModel { 

    public static getConfig(data, brokerColors, carrierColors, isLoading) {
        if (isLoading) {
          return {
            maxBrokerCommissions: 0,
            maxCarrierPremiums: 0,
            policies: [
              {
                subtitle: 'Medical Policies',
                data: [{ loading: true }, { loading: true }, { loading: true }],
              },
              {
                subtitle: 'Non-Medical Policies',
                data: [{ loading: true }, { loading: true }, { loading: true }],
              },
            ],
          };
        }

        const grped = data.reduce((a, b) => {
          if (!a[b.rowUniqueKey]) {
            a[b.rowUniqueKey] = [];
          }
          a[b.rowUniqueKey].push(b);
          return a;
        }, {});
      
        const grpedValues = Object.values(grped)
          .map((grp: any[]) => {

            const brokers = grp
              .map(x => ({
                name: x.brokerName,
                value: x.brokerCommission,
                color: brokerColors[x.brokerName]
                  ? brokerColors[x.brokerName]
                  : '#849AA9',
              }))
              .sort((a, b) => parseFloat(b.value) - parseFloat(a.value));

            const carriers = ListUtils.distinctBy(grp, x => x.carrierUniqueKey)
              .map(x => ({
                name: x.carrierName,
                value: x.carrierPremium,
                color: carrierColors[x.carrierName]
                  ? carrierColors[x.carrierName]
                  : '#849AA9',
              }));
            
            return {
              rowUniqueKey: grp[0].rowUniqueKey,
              isMedical: grp[0].isMedical,
              coveredLives: grp[0].coveredLives,
              benefitsOffered: grp[0].benefitsOffered,
              otherText: grp[0].otherText,
              renewalDate: grp[0].renewalDate,
              pepm: grp[0].pepm,
              brokers: brokers,
              carriers: carriers
            };
          });
      
        const maxBrokerCommissions = grpedValues.reduce((a, b) => {
          const value = b.brokers.reduce((a, b) => {
            const parsed = parseFloat(b.value);
            return isNaN(parsed) ? a : a + parsed;
          }, 0);
          return Math.max(a, value);
        }, 0);
      
        const getCarriersPremiums = (carriers) => {
          return carriers.reduce((a, b) => {
            const parsed = parseFloat(b.value);
            return isNaN(parsed) ? a : a + parsed;
          }, 0);
        }

        const maxCarrierPremiums = grpedValues.reduce(
          (a, b) =>
            getCarriersPremiums(b.carriers) ? Math.max(a, getCarriersPremiums(b.carriers)) : a,
          0,
        );
      
        const medical = grpedValues
          .filter(x => x.isMedical)
          .sort(
            (a, b) =>
              getCarriersPremiums(b.carriers) - getCarriersPremiums(a.carriers),
          );
      
        const nonMedical = grpedValues
          .filter(x => !x.isMedical)
          .sort(
            (a, b) =>
              getCarriersPremiums(b.carriers) - getCarriersPremiums(a.carriers),
          );
          
        return {
          policies: [
            { subtitle: 'Medical Policies', data: medical },
            { subtitle: 'Non-Medical Policies', dark: true, data: nonMedical },
          ],
          maxBrokerCommissions: maxBrokerCommissions,
          maxCarrierPremiums: maxCarrierPremiums,
        };
    }
      
}