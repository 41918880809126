import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectListsDetailPage } from 'app/containers/ListsPage/containers/ListsDetailPage/selectors';
import { listsDetailPageActions } from 'app/containers/ListsPage/containers/ListsDetailPage/slice';

export const useOnDelete = () => {
	const dispatch = useDispatch();
	const { contactFilters } = useSelector(selectListsDetailPage);

	const onSelect = useCallback(
		({ type, value }: { value: string; type: 'include' | 'exclude' }) =>
			() => {
				dispatch(
					listsDetailPageActions.updateContactFilters({
						...contactFilters,
						contactLocation: {
							include:
								type === 'include'
									? contactFilters.contactLocation.include.filter(({ location: { searchValue } }) => searchValue !== value)
									: contactFilters.contactLocation.include,
							exclude:
								type === 'exclude'
									? contactFilters.contactLocation.exclude.filter(({ location: { searchValue } }) => searchValue !== value)
									: contactFilters.contactLocation.exclude,
						},
					})
				);
			},
		[dispatch, listsDetailPageActions, contactFilters]
	);

	return onSelect;
};
