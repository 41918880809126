import { create } from 'zustand';

import { StoreUtils } from '../../../../utilities';
import { EmployerContactsStore } from './EmployerContacts.types';
import { getDefaultEmployerContactFilters } from 'common.model/src/types/common/search/filters/ContactFilters';

const FILTERS = [
	'companyName' as const,
	'contactType' as const,
	'jobTitles' as const,
	'yearsAtCompany' as const,
	'roles' as const,
	'contactLocation' as const,
	'nameSearch' as const,
	'employerLineOfBusiness' as const,
	'employerCarrierName' as const,
	'employerIndustries' as const,
	'employerEntityType' as const,
	'employerPlanType' as const,
	'employerTotalEmployees' as const,
	'employerHeadquarters' as const,
	'employerRenewalDatesRange' as const,
];

export const useEmployerContactsStore = create<EmployerContactsStore>((set) => ({
	...StoreUtils.createContactSelectorMethods(set),
	...StoreUtils.createFilterVisibilityMethods(FILTERS, set),
	resetFilterValues: () => set({ filters: getDefaultEmployerContactFilters() }),
	filters: getDefaultEmployerContactFilters(),
	setFilters: {
		companyName: StoreUtils.createIncludeExcludeMethods('companyName', set),
		jobTitles: StoreUtils.createIncludeExcludeMethods('jobTitles', set),
		yearsAtCompany: StoreUtils.createRangeMethodsLegacy('yearsAtCompany', set),
		roles: StoreUtils.createIncludeExcludeMethods('roles', set),
		contactLocation: StoreUtils.createLocationMethods('contactLocation', set),
		nameSearch: StoreUtils.createStringMethods('nameSearch', set),
		employerTotalEmployees: StoreUtils.createRangeMethods('employerTotalEmployees', set),
		employerHeadquarters: StoreUtils.createLocationMethods('employerHeadquarters', set),
		employerIndustries: StoreUtils.createIncludeExcludeMethods('employerIndustries', set),
		employerEntityType: StoreUtils.createIncludeExcludeMethods('employerEntityType', set),
		employerPlanType: StoreUtils.createIncludeExcludeMethods('employerPlanType', set),
		employerLineOfBusiness: StoreUtils.createIncludeExcludeMethods('employerLineOfBusiness', set),
		employerCarrierName: StoreUtils.createIncludeExcludeMethods('employerCarrierName', set),
		employerRenewalDatesRange: StoreUtils.createRangeMethods('employerRenewalDatesRange', set),
	},
}));
