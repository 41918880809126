import { useMemo, useState } from 'react';
import { AutoComplete, Collapsible, InputLabel } from '@benefitflow/designsystem';

import { useIsOpen, useLoading, useOnDelete, useOnExclude, useOnSearch, useOnSelect, useOptions, useToggle, useValues } from './hooks';
import { Tags } from 'new-beginning/components/shared/SidebarFilters/components/Tags/Tags';

export const LicenseType = () => {
	const [search, setSearch] = useState('');
	const values = useValues();
	const loading = useLoading();
	const onSelect = useOnSelect();
	const onDelete = useOnDelete();
	const options = useOptions({ search });
	const onSearch = useOnSearch({ setSearch });
	const selectedValues = useMemo(() => values.map(({ value }) => value), [values]);
	const isOpen = useIsOpen();
	const toggle = useToggle();
	const onExclude = useOnExclude();

	return (
		<Collapsible
			headerTestId="license-type-filter-header"
			isOpen={isOpen}
			toggle={toggle}
			title="License Type"
			icon="shield-check"
			content={values.length && <Tags onDelete={onDelete} values={values} />}
			openContent={
				<InputLabel label="Contacts that are licensed to sell [Health] insurance">
					<AutoComplete
						removable
						onDelete={onExclude}
						inputTestId="role-filter-input"
						selectedValues={selectedValues}
						loading={loading}
						placeholder="e.g. Health"
						onSelect={onSelect}
						options={options}
						onSearch={onSearch}
					/>
				</InputLabel>
			}
		/>
	);
};
