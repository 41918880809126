import React from 'react';

import Skeleton from '@material-ui/lab/Skeleton';

import './style.scss';

export interface MetricProps {
  className?: string;
  title?: string;
  text?: string;
  type?: string;
  loading?: boolean;
}

export const Metric = ({ className, text, title, type, loading }: MetricProps) => {
  return (
    <div className={`dev-stat-bloc ${className ?? ''}`}>
      <h4>{title}</h4>
      {type === 'tag' && (
        <>
          {loading ? (
            <Skeleton variant="rect" height={40} />
          ) : (
            <span>{text}</span>
          )}
        </>
      )}
      {type === 'text' && (
        <>
          {loading ? (
            <Skeleton variant="rect" width={100} height={24} />
          ) : (
            <p>{text}</p>
          )}
        </>
      )}
    </div>
  );
};