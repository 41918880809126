import { Col, Row } from 'react-bootstrap';
import { ServiceProviderTable } from './components/ServiceProviderTable';
import Title from 'atoms/Title';
import { APIContext } from 'new-beginning/services/api/Api.context';
import { useContext } from 'react';
import FormattingUtils from 'utils/FormattingUtils';
import { RouterUtils } from 'utils/RouterUtils';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { ToolTip, terciaryColor, StackedBar } from 'ui-components';

export interface ServiceProviderSectionProps {
    employerMasterId: string;
    selectedFilingYear: number;
    navigate: (path: string) => void;
}

export const ServiceProviderSection = ({ employerMasterId, selectedFilingYear, navigate }: ServiceProviderSectionProps) => {
    const { providerApi } = useContext(APIContext);
    const feesPaidToServiceProviders = providerApi.useGetFeesPaidToServiceProviders({ 
        employer_master_id: employerMasterId, 
        selected_filing_year: selectedFilingYear 
    });
    
    const healthServiceProviders =
        feesPaidToServiceProviders?.data
            ?.filter(x => x.plan_type == "Health & Welfare") 
            .sort((a, b) => b.fees_accounted_for - a.fees_accounted_for) ?? [];

    const pensionServiceProviders = 
        feesPaidToServiceProviders?.data
            ?.filter(x => x.plan_type == "Pension") 
            .sort((a,b) => b.fees_accounted_for - a.fees_accounted_for) ?? [];

    const maxHealthFees = healthServiceProviders
        ?.map(x => x.fees_accounted_for)
        ?.reduce((a, b) => Math.max(a, b), 0);

    const maxPensionFees = pensionServiceProviders
        ?.map(x => x.fees_accounted_for)
        ?.reduce((a, b) => Math.max(a, b), 0);

    const getProvidersColumns = (maxFees) => [
        {
            name: 'Service Provider',
            cell: (row: any) => {
                return (
                <span className="clickable" onClick={() => navigate(RouterUtils.getProviderPath(row.master_provider_id, row.master_provider_name))}>{
                    FormattingUtils.formatUpperCasing(row.master_provider_name)
                }</span>
                )
            },
            sortable: true,
            width : "150px"
        },
        {
            name: 'Services',
            sortable: false,
            width : "100px",
            cell: (row: any) => {
                const services = row.services_list
                    ?.split("|")
                    .map(x => x.trim())
                    .sort()
                    .map(x => [x, '']);
                return (
                    <ToolTip
                        key={row.master_provider_id}
                        title={"Services"}
                        keyValuePairs={
                            services ? services : [[]]
                        }
                    >
                    {
                        row.services_list ?
                        <i className="ico"><BsFillInfoCircleFill color={terciaryColor} size="15px" /></i> :
                        <span></span>
                    }
                    
                    </ToolTip>
                )
            }
        },
        {
            name: 'Fees Paid',
            sortable: false,
            cell: (row : any) => {
                const data = [ 
                    {
                    name: 'Test Name',
                    value: row.fees_accounted_for,
                    color: "rgb(89, 167, 255)"
                    }
                ];
                return (
                    <StackedBar
                        data={data}
                        maxValue={maxFees}
                        showTotal
                        getToolTipConfig={input => {
                            return {
                            title: FormattingUtils.formatUpperCasing(
                                row.master_provider_name,
                            ),
                            keyValuePairs: [
                                [
                                'Fees',
                                row.fees_accounted_for
                                    ? FormattingUtils.formatNumberAbbrev(
                                        row.fees_accounted_for,
                                    )
                                    : 'N/A',
                                ],
                            ],
                            };
                        }}
                    />
                )
            }
        }
    ];
    
    return (
        <>
            <div className="m-t-25" style={{ marginTop: "40px" }}>
                <Row>
                    <Col lg={6}>
                        <Title text="Health Plan Service Providers" className="md" />
                        <ServiceProviderTable 
                            className="service-table" 
                            columns={getProvidersColumns(maxHealthFees)} 
                            data={healthServiceProviders} 
                        />
                    </Col>
                    <Col lg={6}>
                        <Title text="Retirement Plan Service Providers" className="md" />
                        <ServiceProviderTable 
                            className="service-table" 
                            columns={getProvidersColumns(maxPensionFees)}
                            data={pensionServiceProviders} 
                        />
                    </Col>
                </Row>
            </div>
        </>
    );
}
