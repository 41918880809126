import { useContext, useMemo, useState } from 'react';
import { Box, Button, Divider, Icon, InputLabel, Select, Typography } from '@benefitflow/designsystem';

import { useAddToList } from '../../../../hooks';
import { AddToListButtonProps } from '../../../../AddToListButton.types';
import { APIContext } from '../../../../../../../../../services/api/Api.context';
import { ContactListHeaderContext } from '../../../../../../ContactListHeader.context';

export const Add = ({
	setTab,
	setIsAddToListDialogOpen,
	useAddToExistingListMutation,
}: {
	useAddToExistingListMutation: AddToListButtonProps['useAddToExistingListMutation'];
	setTab: (tab: 'add' | 'create') => void;
	setIsAddToListDialogOpen: (isOpen: boolean) => void;
}) => {
	const {
		store: { checkMode, checkedMap, filters },
		context: { numberOfSelectedContacts },
		type,
	} = useContext(ContactListHeaderContext);
	const [selectedListId, setSelectedListId] = useState('');
	const { listsApi } = useContext(APIContext);
	const { data, loading: isLoadingListOptions } = listsApi.useGetAllListsForUser({
		onSuccess: (data) => {
			if (data.length) setSelectedListId(String(data[1].id));
		},
	});
	const { addToList, loading: isAddingToList } = useAddToList({
		useAddToExistingListMutation,
		checkedMap,
		contactType: type,
		checkMode,
		filters,
		setIsAddToListDialogOpen,
	});
	const haveAtLeastOneOption = useMemo(() => !data?.length || !isLoadingListOptions, [data, isLoadingListOptions]);

	return (
		<Box display="flex" flexDirection="column">
			<Box m="lg" display="flex" flexDirection="column" gap="md">
				<Typography.Text size="lg" color="neutral-700">
					Save {numberOfSelectedContacts} Contact
				</Typography.Text>
				{haveAtLeastOneOption && (
					<InputLabel label="List">
						<Select value={selectedListId} onChange={(event) => setSelectedListId(event.target.value)}>
							{data?.slice(1).map(({ id, name }) => <Select.Option key={id} label={name} value={id} />)}
						</Select>
					</InputLabel>
				)}
				<Box onClick={() => setTab('create')} cursor="pointer" display="flex" gap="xs" alignItems="center">
					<Icon color="primary-500" name="add" size="md" />
					<Typography.Text color="primary-500">Create List</Typography.Text>
				</Box>
			</Box>

			<Divider />
			<Box m="lg" display="flex" justifyContent="end" alignItems="center" gap="md">
				<Box cursor="pointer" onClick={() => setIsAddToListDialogOpen(false)}>
					<Typography.Text color="primary-500">Cancel</Typography.Text>
				</Box>
				<Box>
					<Button fetching={isAddingToList} onClick={() => addToList(selectedListId)}>
						Confirm
					</Button>
				</Box>
			</Box>
		</Box>
	);
};
