export enum Tabs {
	BENEFITS = 'benefits',
	HISTORY = 'history',
	CONTACTS = 'contacts',
	EMPLOYEE_SENTIMENT = 'employeeSentiment',
}

export interface EmployerProfileStore {
	currentTab?: Tabs;
	setCurrentTab: (tab: Tabs) => void;
	setCurrentTabTo: Record<Tabs, () => void>;
}
