import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectListsDetailPage } from 'app/containers/ListsPage/containers/ListsDetailPage/selectors';

export const useValues = () => {
	const {
		contactFilters: { licenseTypes },
	} = useSelector(selectListsDetailPage);

	const values = useMemo(
		() => [
			...licenseTypes.include.map((licenseType) => ({ value: licenseType, label: licenseType, type: 'include' as const })),
			...licenseTypes.exclude.map((licenseType) => ({ value: licenseType, label: licenseType, type: 'exclude' as const })),
		],
		[licenseTypes],
	);

	return values;
};
