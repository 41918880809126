import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { employerPageActions } from 'app/containers/EmployerPage/slice';

export const useOnClickBack = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const onClickBack = useCallback(() => {
		navigate(-1);
		dispatch(employerPageActions.resetContactSearchFilters());
	}, [navigate, dispatch]);

	return onClickBack;
};
