import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.appPage || initialState;

export const selectAppPage = createSelector(
    [selectDomain],
    pageState => pageState
  );

export const selectIdToken = createSelector(
  [selectDomain],
  pageState => pageState.idToken
);

export const selectLoginAsTenant = createSelector(
  [selectDomain],
  pageState => pageState.loginAsTenant
);
