import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MTabs from '@material-ui/core/Tabs';
import MTab from '@material-ui/core/Tab';
import { Box } from "@material-ui/core";
import { primaryColor, secondaryColor } from "designTokens/colors";

export const Tabs = withStyles((theme) => ({
  root: {
    borderBottom: "1px solid rgba(175, 189, 207, 0.4)"
  },
  indicator: {
    color: primaryColor,
    background: primaryColor, 
    height: "3px"
  }
}))(MTabs);

export const Tab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    fontWeight: 600,
  },
  selected: {
    color: primaryColor,
    textTransform: 'none',
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px"
  },
  disabled: {
    color: secondaryColor,
    textTransform: 'none',
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px"
  }
}))(MTab);

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ paddingTop: "0px" }}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}