import { Box } from '@benefitflow/designsystem';
import { Header } from './Header/Header';
import { Summary } from './Summary/Summary';
import { Tabs } from './Tabs/Tabs';

export const EmployerProfile = () => {
	return (
		<Box width="full">
			<Header employerMasterId={null} />
			<Summary />
			<Tabs />
		</Box>
	);
};
