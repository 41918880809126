export interface FilterVisibilityMethods<T extends string> {
	filtersVisibilityMap: Record<T, boolean>;
	toggleFilterVisibility: Record<T, () => void>;
	resetFiltersVisibility: () => void;
}

export const createFilterVisibilityMethods = <T extends string>(filters: T[], set): FilterVisibilityMethods<T> => {
	return {
		filtersVisibilityMap: filters.reduce(
			(acc, key) => {
				acc[key] = false;
				return acc;
			},
			{} as Record<(typeof filters)[number], boolean>,
		),
		toggleFilterVisibility: filters.reduce(
			(acc, key) => {
				acc[key] = () =>
					set((state) => ({
						filtersVisibilityMap: {
							...Object.keys(state.filtersVisibilityMap).reduce((acc, cur) => ({ ...acc, [cur]: false }), {} as Record<string, boolean>),
							[key]: !state.filtersVisibilityMap[key],
						},
					}));
				return acc;
			},
			{} as Record<string, () => void>,
		),
		resetFiltersVisibility: () =>
			set(() => ({
				filtersVisibilityMap: filters.reduce((acc, cur) => ({ ...acc, [cur]: false }), {} as Record<string, boolean>),
			})),
	};
};
