import { Box, Tabs as DSTabs } from '@benefitflow/designsystem';

import { Tabs as TabsEnum } from '../../ContactsList.types';
import { useContactsListStore } from '../../ContactsList.store';

export const Tabs = () => {
	const { tab, setTab } = useContactsListStore();

	return (
		<Box mb="sm">
			<DSTabs align="start" value={tab} onChange={setTab}>
				<DSTabs.Tab label="Broker Contacts" value={TabsEnum.BROKER_CONTACTS} />
				<DSTabs.Tab label="Employer Contacts" value={TabsEnum.EMPLOYER_CONTACTS} />
			</DSTabs>
		</Box>
	);
};
