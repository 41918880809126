import { render } from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from 'serviceWorker';
import { WithLoggedInUser } from 'new-beginning/context/LoggedInUser';
import { LandingPageController } from 'app/containers/LandingPage/Loadable';
import { Provider as DSProvider, NotificationContainer } from '@benefitflow/designsystem';
// Use consistent styling
import 'sanitize.css/sanitize.css';
// Import root app
import { AppController } from 'app';
import { HelmetProvider } from 'react-helmet-async';
import { configureAppStore } from 'store/configureStore';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from 'new-beginning/lib/queryClient';
import { WithAuthenticatedContextProvider } from 'new-beginning/context/AuthenticatedContext';
import { APIProvider } from 'new-beginning/services/api/Api.provider';
import { ExportProvider } from './new-beginning/components/shared/ExportProvider/ExportProvider';

const store = configureAppStore();
const RootComponent = () => {
	return (
		<>
			<NotificationContainer zIndex={99999} />
			<Provider store={store}>
				<HelmetProvider>
					<DSProvider>
						<WithLoggedInUser>
							<WithAuthenticatedContextProvider>
								<APIProvider>
									<QueryClientProvider client={queryClient}>
										<ExportProvider>
											<BrowserRouter>
												<Routes>
													<Route path="/" element={<LandingPageController />} />
													<Route path="/*" element={<AppController />} />
												</Routes>
											</BrowserRouter>
										</ExportProvider>
									</QueryClientProvider>
								</APIProvider>
							</WithAuthenticatedContextProvider>
						</WithLoggedInUser>
					</DSProvider>
				</HelmetProvider>
			</Provider>
		</>
	);
};

render(<RootComponent />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
