import { useListDetailsFilterStore } from '../../../ListDetails.store';

export const useIsOpen = () => {
	const {
		collapsible: {
			map: { role },
		},
	} = useListDetailsFilterStore();

	return role;
};
