import { useMemo } from 'react';
import DOMPurify from 'dompurify';

import { InsightProps } from '../../../Benefit.types';

export const useFormattedText = ({ text, boldWords }: Pick<InsightProps, 'boldWords' | 'text'>) => {
	const formattedText = useMemo(() => {
		let formattedText = text;
		for (const boldWord of boldWords) formattedText = formattedText.replace(new RegExp(boldWord, 'g'), `<strong>${boldWord}</strong>`);

		return DOMPurify.sanitize(formattedText);
	}, [text, boldWords]);

	return formattedText;
};
