import { useState, useEffect } from 'react';
import { Amplify, Auth } from 'aws-amplify';
import AmplifyConfigManager from 'utils/AmplifyConfigManager';
import { CognitoRefreshToken, CognitoUserSession } from 'amazon-cognito-identity-js';
import { CognitoUser } from '@aws-amplify/auth';

Amplify.configure({ Auth: AmplifyConfigManager.getAmplifyConfig() });

export interface UserAmplifySession {
  idToken: string;
  userSessionMeta: { [id: string]: any };
  cognitoSession: CognitoUserSession;
}

export interface TokenManager {
    getToken: () => Promise<UserAmplifySession | void>;
    amplifyToken: string | null;
    refreshToken: () => Promise<string>;
    tokenLoading: boolean;
}

export const useAmplifyToken = (): TokenManager => {
    const [tokenLoading, setTokenLoading] = useState<boolean>(true);
    const [amplifySession, setAmplifySession] = useState<CognitoUserSession | null>(null);
    
    const sessionIdToken = amplifySession?.getIdToken();
    const amplifyToken = sessionIdToken?.getJwtToken();

    async function getToken() {
      setTokenLoading(true);
      return Auth?.currentSession()
        .then((cognitoSession: CognitoUserSession) => {
          const cognitoSessionToken = cognitoSession?.getIdToken();
          setAmplifySession(cognitoSession);

          return {
              cognitoSession,
              idToken: cognitoSessionToken?.getJwtToken(),
              userSessionMeta: cognitoSessionToken?.decodePayload(),
          };
        })
        .catch((err) => {
          console.error(`Failed to retrieve Amplify Token. ${err}`);
        })
        .finally(() => setTokenLoading(false)
      );
    };

    useEffect(() => {
      getToken();
    }, []);

    const refreshToken = async () => {
      try {
        const currentSession: CognitoUserSession = await Auth?.currentSession();
        const refreshToken: CognitoRefreshToken = amplifySession?.getRefreshToken();

        if (currentSession) {
          setAmplifySession(currentSession);
          return currentSession.getIdToken().getJwtToken();
        } else if (!currentSession && refreshToken) {
          const currentAuthUser: CognitoUser = await Auth?.currentAuthenticatedUser();
          currentAuthUser?.refreshSession(refreshToken, async (err, newSession) => {
            if (!err) {
              setAmplifySession(newSession);
              return newSession.getIdToken().getJwtToken();
            }
          });
        }
      } catch(err) {
        console.error(`Encountered Failure attempting to refresh Amplify Session: ${err}.`)
      }
    };

    return {
        getToken,
        refreshToken,
        amplifyToken,
        tokenLoading,
    };
};