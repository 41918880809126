import { Box, Tag } from '@benefitflow/designsystem';

export const Tags = ({
	values,
	onDelete,
}: {
	values: { value: string; label: string; type: 'exclude' | 'include' }[];
	onDelete: ({ value, type }: { value: string; type: 'exclude' | 'include' }) => () => void;
}) => {
	if (!values.length) return null;

	return (
		<Box display='flex' gap='xxs' flexWrap='wrap'>
			{values.map(({ label, value, type }) => (
				<Tag variant={type === 'include' ? 'primary' : 'danger'} label={label} onDelete={onDelete({ value, type })} key={value} />
			))}
		</Box>
	);
};
