import { Route, Routes, Navigate } from 'react-router-dom';
import { LoginPage } from 'app/containers/LoginPage/Loadable';
import { PrivacyPolicyPage } from 'app/containers/PrivacyPolicyPage/Loadable';
import { NotFoundPage } from 'pages/index';

export const PublicRoutes = () => {
    return (
        <Routes>
            <Route path="/Login" element={<LoginPage />} />
            <Route path="/PrivacyPolicy" element={<PrivacyPolicyPage />} />
            <Route path="/404" element={<NotFoundPage />} />
            {/* NOTE: The Catch-All 404 needs a Global Loading state value. Otherwise it flashes between pages. */}
            {/* <Route path="*" element={<Navigate to="/404" />} /> */}
        </Routes>
    );
};
