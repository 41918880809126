import React, { useState } from 'react';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import { styled, withStyles } from '@material-ui/core/styles';

/*
  Tooltip that stays open as long is your mouse in on the popup
*/
const BlackToolTip = withStyles({
  tooltip: {
    color: 'white',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    fontSize: '16px',
    maxWidth: 'none',
    pointerEvents: 'auto'
  }
})(Tooltip);

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <BlackToolTip {...props} />
))(({ theme }) => ({
  [`&`]: {
    fontSize: theme.typography.pxToRem(16)
  },
}));

export interface ToolTipProps {
  title: string;
  keyValuePairs: [string | JSX.Element, string][];
  children: any;
}

export const ToolTip = ({ title, keyValuePairs, children }: ToolTipProps) => {
  const [open, setOpen] = useState(false);

  const handleMouseEnter = () => {
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };

  function getToolTip(title: string, keyValuePairs: [string | JSX.Element, string][]) {
    return (
      <div
        onMouseLeave={handleMouseLeave}
        style={{ padding: '5px', width: '100%' }}
      >
        <table>
          <thead style={{ width: '100%' }}>
            <tr>
              <th style={{ padding: '3px' }}>{title}</th>
            </tr>
          </thead>
          <tbody>
            {keyValuePairs.map(([key, value]) => (
              <tr style={{ whiteSpace: 'nowrap' }}>
                <td className="tooltip-key" style={{ padding: '3px' }}>
                  {key}
                </td>
                <td style={{ padding: '3px', paddingLeft: '20px' }}>{value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <HtmlTooltip
      onMouseEnter={handleMouseEnter}
      open={open}
      title={
        <React.Fragment>
          {getToolTip(title, keyValuePairs)}
        </React.Fragment>
      }
    >
      {children}
    </HtmlTooltip>
  );
};
