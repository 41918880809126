import Select from 'react-select';
import { useEffect, useState, useRef } from 'react';
import { DropDownOptionType, GroupedOptionSet } from 'new-beginning/hooks/salesforce/useCrmFieldConfigurationData';
import {
	matchSelectedOption,
	getOptionValue,
	getFieldMappingUpdate,
	formatOptionLabel,
} from 'new-beginning/components/pages/IntegrationSettings/FieldMappings/SubComponents/dropdown_utils';
import { CustomSelectedField, FieldStateType } from 'new-beginning/components/pages/IntegrationSettings/FieldMappings/SubComponents/CustomSelectedField';
import {
	CustomOptions,
	DomainGroupHeader,
	GroupMenuList,
} from 'new-beginning/components/pages/IntegrationSettings/FieldMappings/SubComponents/CustomOptions';
import { CrmIntegrationFieldDomainType, DecoratedFieldMapping, StagedDecoratedFieldMapping, SourceOrTarget } from 'common.model/src/db/model-decorators/type-extensions';

const { SALESFORCE_CONSTANT } = CrmIntegrationFieldDomainType;
const { SOURCE, TARGET } = SourceOrTarget;

enum DomainLabel {
	SOURCE = 'Source',
	TARGET = 'Target',
}

interface FieldMappingDropdownProps {
	optionSet: (GroupedOptionSet | DropDownOptionType)[];
	value: {
		field_domain_type: CrmIntegrationFieldDomainType;
		name: string;
		value?: string;
	} | null;
	updateFieldMapping: (mappingIdx: number, values: Partial<DecoratedFieldMapping>) => void;
	sourceOrTarget: SourceOrTarget;
	mappingField: DecoratedFieldMapping | StagedDecoratedFieldMapping;
	fieldIdx: number;
	isLocked: boolean;
	isEdit?: boolean;
}

export const FieldMappingDropdown = ({
	value,
	optionSet,
	fieldIdx,
	updateFieldMapping,
	sourceOrTarget,
	mappingField,
	isLocked = false,
	isEdit=false,
}: FieldMappingDropdownProps) => {
	const { id, matching_field, required_field, sourceField } = mappingField;
	const [currentSelectedValue, setCurrentSelectedValue] = useState<DropDownOptionType | null>(null);

	const picklistConstantSelected = sourceField?.field_domain_type === SALESFORCE_CONSTANT && sourceOrTarget === TARGET;
	const picklistTarget = picklistConstantSelected ? sourceField?.name : null;

	const [isFocused, setIsFocused] = useState<boolean>(false);
	const selectRef = useRef(null);
	const onMenuClick = (e) => {
		const menu = selectRef?.current.querySelector('.custom-select__menu');
		if (!selectRef.current.contains(e.target) || !menu || !menu.contains(e.target)) {
			setIsFocused(false);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', onMenuClick);

		return () => {
			document.removeEventListener('mousedown', onMenuClick);
		};
	}, []);

	useEffect(() => {
		const currentValue = matchSelectedOption(value, optionSet);
		setCurrentSelectedValue(currentValue);
	}, [value, optionSet]);

	return (
		<div ref={selectRef}>
			<Select
				className='dropdown-select-container'
				classNamePrefix='custom-select'
				components={{
					Control: CustomSelectedField,
					Option: CustomOptions,
					GroupHeading: DomainGroupHeader,
					Group: GroupMenuList,
				}}
				// @ts-ignore
				fieldStates={{
					[FieldStateType.LOCKED]: required_field,
					[FieldStateType.NEW]: !id,
					[FieldStateType.EDIT]: isEdit,
				}}
				showStateIcon={sourceOrTarget === SOURCE}
				fieldObjectDomainType={value?.field_domain_type}
				isDisabled={isLocked}
				placeholder={picklistTarget || `Select ${DomainLabel[sourceOrTarget]}`}
				value={currentSelectedValue}
				options={optionSet}
				getOptionValue={getOptionValue}
				onChange={(valueToSet: DropDownOptionType) => {
					const valueMapped = getFieldMappingUpdate(
						valueToSet,
						sourceOrTarget,
						picklistConstantSelected
					);
					updateFieldMapping(fieldIdx, valueMapped);
					setIsFocused(false);
				}}
				formatOptionLabel={(option) => formatOptionLabel(option, sourceOrTarget)}
				isClearable={false}
				isSearchable
				// Custom Display Props for CustomInput
				onMenuInputFocus={(setFocused = true) => setIsFocused(setFocused)}
				menuIsOpen={isFocused || undefined}
				isFocused={isFocused || undefined}
			/>
		</div>
	);
};
