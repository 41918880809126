import { ReactNode } from 'react';

import { useOnExport } from './hooks';
import { ExportContext } from '../../../context/ExportContext';

export const ExportProvider = ({ children }: { children: ReactNode }) => {
	const value = useOnExport();

	return <ExportContext.Provider value={value}>{children}</ExportContext.Provider>;
};
