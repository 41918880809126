import { Box, Icon, Paper, Skeleton, Typography } from '@benefitflow/designsystem';
import { useBackgroundColor, useBenefitsRatingVariationColor, useIcon, useBenefitsRatingColor } from './hooks';
import { useEmployeeSentiment } from 'new-beginning/components/pages/EmployerProfile/Tabs/components/EmployeeSentimentTab/hooks';
import { BenefitsRating } from './components';

export const EmployeePerception = () => {
	const { evaluation, type, loading, yearlyVariation } = useEmployeeSentiment();
	const backgroundColor = useBackgroundColor(type);
	const color = useBenefitsRatingVariationColor(type);
	const icon = useIcon(type);
	const benefitsRatingColor = useBenefitsRatingColor(evaluation);

	if (loading)
		return (
			<Paper p="lg" style={{ height: '100%' }}>
				<Box height="full" display="flex" flexDirection="column" gap="xxs">
					<Skeleton height="md" width="xl5" />
					<Skeleton height="xl" width="xl7" />
					<Skeleton height="md" width="xl5" />
				</Box>
			</Paper>
		);
	if (!evaluation) return null;

	return (
		<Paper p="lg" style={{ height: '100%' }}>
			<Box height="full" display="flex" flexDirection="column" gap="xxs">
				<Typography.Text size="lg" color="neutral-600">
					Benefits Rating
				</Typography.Text>
				<BenefitsRating benefitsRating={evaluation} />
				<Box display="flex" justifyContent="start" alignItems="center" gap="lg">
					<Typography.Display size="md">{`${evaluation?.toFixed(1)} / 5`}</Typography.Display>
					{!!yearlyVariation && (
						<Box display="flex" gap="xs" justifyContent="start" alignItems="center">
							<Box display="flex" alignItems="center" justifyContent="center" borderRadius="round" height="lg" width="lg" backgroundColor={backgroundColor}>
								<Icon name={icon} color={color} size="lg" />
							</Box>
							<Box display="flex" gap="xxs" alignItems="center">
								<Typography.Text size="sm" color={color}>
									{yearlyVariation}%
								</Typography.Text>
								<Typography.Text weight="regular" size="sm" color="neutral-600">
									last 12 months
								</Typography.Text>
							</Box>
						</Box>
					)}
				</Box>
			</Box>
		</Paper>
	);
};
