import { useListDetailsFilterStore } from '../../../ListDetails.store';

export const useIsOpen = () => {
	const {
		collapsible: {
			map: { licenseTypes },
		},
	} = useListDetailsFilterStore();

	return licenseTypes;
};
