import { Button } from '@benefitflow/designsystem';

import { useIsDisabled } from './hooks';
import { useDeleteSelected } from './hooks/useDeleteSelected';

export const DeleteSelectedButton = () => {
	const { deleteSelected, loading } = useDeleteSelected();
	const isDisabled = useIsDisabled();

	return (
		<Button icon="trash" variant="outlined" onClick={deleteSelected} fetching={loading} disabled={isDisabled}>
			Delete Selected
		</Button>
	);
};
