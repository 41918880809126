import { getDialogZIndex } from './getDialogZIndex';
import { getNumberOfSelectedContacts } from './getNumberOfSelectedContacts';
import { isGenericActionDisabled } from './isGenericActionDisabled';
import { isGetSelectedButtonDisabled } from './isGetSelectedButtonDisabled';

export const ListActionUtils = {
	isGetSelectedButtonDisabled,
	isGenericActionDisabled,
	getDialogZIndex,
	getNumberOfSelectedContacts,
};
