import { createContext, useContext, useEffect } from 'react';
import { FC, PropsWithChildren } from 'react';
import axios, { AxiosInstance } from 'axios';
import { Amplify } from 'aws-amplify';
import { useAmplifyToken } from 'new-beginning/hooks/useAmplifyToken';
import AmplifyConfigManager from 'utils/AmplifyConfigManager';
import serviceUrl from 'utils/serviceUrl';
import { UserContextType, LoggedInUserContext } from './LoggedInUser';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

interface AuthenticatedContextValue {
	amplifyToken: string | null;
	httpClient: AxiosInstance;
}

export const WithAuthenticatedContext = createContext<AuthenticatedContextValue>({
	amplifyToken: null,
	httpClient: null,
});

export const WithAuthenticatedContextProvider: FC<PropsWithChildren> = ({ children }) => {
	const { client, amplifyToken } = useAuthenticatedHttpClient();
	return <WithAuthenticatedContext.Provider value={{ httpClient: client, amplifyToken }}>{children}</WithAuthenticatedContext.Provider>;
};

const useAuthenticatedHttpClient = () => {
	const { amplifyToken, refreshToken } = useAmplifyToken();
	const { authUser }: UserContextType = useContext(LoggedInUserContext);
	const client = axios.create({ baseURL: serviceUrl, withCredentials: true });
	client.defaults.headers.common.Authorization = amplifyToken;
	client.defaults.headers.common['Content-Type'] = 'application/json';
	client.defaults.headers.common.bf_login_as_tenant = authUser?.tenantIdOverride || null;

	createAuthRefreshInterceptor(
		client,
		async (failedRequest) => {
			console.log('auth failure--refreshing token');
			const newToken = await refreshToken();
			failedRequest.response.config.headers.Authorization = newToken;
			client.defaults.headers.common.Authorization = newToken;
		},
		{
			statusCodes: [401, 403],
			pauseInstanceWhileRefreshing: true,
		},
	);
	useEffect(() => {
		if (amplifyToken && authUser) {
			client.defaults.headers.common.Authorization = amplifyToken;
			client.defaults.headers.common['Content-Type'] = 'application/json';
			client.defaults.headers.common.bf_login_as_tenant = authUser?.tenantIdOverride || null;
		}
	}, [client, amplifyToken, authUser?.tenantIdOverride]);
	return { client, amplifyToken };
};
