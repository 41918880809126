import { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { APIContext } from 'new-beginning/services/api/Api.context';
import { ListAttributesWithCounts } from 'common.model/src/db/models/List';

export const useList = (selectedListId?: string): ListAttributesWithCounts | undefined => {
	const { listsApi } = useContext(APIContext);
	const { listId } = useParams();
	const { data } = listsApi.useGetAllListsForUser();
	const list = useMemo(() => data?.find((list) => list.id == Number(selectedListId ?? listId)), [selectedListId, listId, data]);

	return list;
};
