/**
 *
 * Asynchronously loads the component for SearchPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const LandingPageController = lazyLoad(
  () => import('./index'),
  module => module.LandingPageContainer,
);
