import { ReactNode, useState } from 'react';
import cn from 'classnames';
import { IoShuffle } from 'react-icons/io5';
import { RiUserAddLine } from 'react-icons/ri';
import { VscTypeHierarchySub as HierarchyIcon } from 'react-icons/vsc';
import { AiOutlineSisternode } from 'react-icons/ai';
import { MdOutlineAccountTree } from 'react-icons/md';
import { VscGitPullRequestCreate } from 'react-icons/vsc';
import { AssignMenu } from 'new-beginning/components/pages/ListSync/SyncTable/AssignMenu';
import MenuItem from '@material-ui/core/MenuItem';
import { SyncToggleInterface } from 'new-beginning/hooks/salesforce/useSyncModalActions';

interface SyncActionBarProps {
	syncListContacts: (syncContactOverride?: Record<string, boolean>) => void;
	selectAllRows: () => void;
	hasSelection: boolean;
	numSelected: number;
	hasSyncActions: boolean;
	allSelected: boolean;
	lockCrmSync: boolean;
	lockAllActions: boolean;
	modalConfig: SyncToggleInterface;
	children: ReactNode;
}

export const SyncActionBar = ({
	syncListContacts,
	allSelected,
	selectAllRows,
	modalConfig,
	lockAllActions,
	numSelected,
	hasSelection,
	hasSyncActions,
	lockCrmSync,
	children,
}: SyncActionBarProps) => {
	const [menuOpen, setMenuOpen] = useState<boolean>(false);

	return (
		<div
			className={cn(
				'd-flex',
				'align-items-center',
				'justify-content-between',
				'bf-border',
				'border-btm-light',
				'bf-header',
				'sync-action-header',
				'p-3'
			)}
		>
			<div className={cn('d-flex', 'align-items-center', 'flex-wrap')}>
				<div className={cn('me-3')}>
					<input
						className={cn('form-check-input', 'cursor-ptr', 'bf-checkbox')}
						type='checkbox'
						disabled={lockAllActions}
						checked={allSelected}
						onChange={() => selectAllRows()}
					/>
				</div>
				{hasSyncActions && (
					<div className={cn('d-flex', 'align-items-center', 'flex-wrap', 'flex-wrap-space')}>
						<div className={cn('me-3')}>
							{hasSelection && (
								<p className={cn('bf-txt', 'txt-primary')}>{`${allSelected ? 'All ' : ''} ${numSelected}`} Selected</p>
							)}
						</div>
						<button
							onClick={() => syncListContacts()}
							disabled={lockCrmSync || !hasSelection}
							className={cn('bf-btn', 'bf-btn-primary', 'me-3')}
						>
							<IoShuffle className={cn('bf-icon', 'me-2')} size={20} />
							<p className={cn('bf-txt', 'd-inline-block')}>Sync to CRM</p>
						</button>
						<AssignMenu isOpen={menuOpen} setIsOpen={setMenuOpen}>
							<MenuItem
								onClick={() => {
									setMenuOpen(false);
									modalConfig?.assignContactOwner?.toggleModal();
								}}
								disabled={lockCrmSync || numSelected === 0}
							>
								<RiUserAddLine className={cn('me-2')} size={20} />
								Assign Contact Owner
							</MenuItem>
							{/* <MenuItem
								disabled={lockCrmSync || modalConfig?.assignAccountInsertParent?.isDisabled}
								onClick={() => {
									setMenuOpen(false);
									modalConfig?.assignAccountInsertParent?.toggleModal();
								}}
							>
								<VscGitPullRequestCreate className={cn('me-2')} size={20} />
								Assign Parent Account
							</MenuItem>
							<MenuItem
								disabled={lockCrmSync || modalConfig?.resolveOrphanedAccounts?.isDisabled}
								onClick={() => {
									setMenuOpen(false);
									modalConfig?.resolveOrphanedAccounts?.toggleModal();
								}}
							>
								<HierarchyIcon className={cn('me-2')} size={20} />
								Resolve Orphaned Accounts
							</MenuItem> */}
						</AssignMenu>
					</div>
				)}
			</div>
			<div className={cn('d-flex', 'align-items-center', 'flex-wrap')}>{children}</div>
		</div>
	);
};
