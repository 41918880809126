import React from 'react';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';



type DropDownPopoverPopoverProps = {
  children: any[];
  anchorEl: any;
  infoText?: string;
  handleMenuClose: () => void;
  // shouldStyleLast: boolean;
};

const useStyles = makeStyles({
  paper: {
    boxShadow: "0px 10px 10px 10px rgb(0 0 0 / 1%), 0px 1px 10px 1px rgb(0 0 0 / 1%), 0px 3px 14px 2px rgb(0 0 0 / 7%)"
  },
  styledMenuItem: {
    color: 'black !important',
    cursor: 'pointer',
    'font-size': '1em',
    display: 'flex',
    padding: '13px',
    width: '100%',
    a: {
      color: 'black',
      'font-size': '1.2em',
    },
    span: {
      'font-size': '1em',
    },
    "&:hover": {
      backgroundColor: "rgba(24, 90, 219, 0.09) !important" 
    }
  },
});

const MenuDropDown = styled.div`
    width: 230px;
    &-item {
      display: flex;
      align-items: center;
      padding: 13px 0;

      a {
        text-decoration: none;
        font-size: 14px;
        color: #b7c2cf;
        font-weight: 500;

        & > i.nd-ico {
          margin-right: 10px;
          font-size: 18px;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }
  `;

  const MenuDropDownItem = styled.div`
    display: flex;
    align-items: center;

    a {
      text-decoration: none;
      font-size: 14px;
      font-weight: 500;

      & > i.nd-ico {
        margin-right: 10px;
        font-size: 18px;
      }
    }

    &:hover {
      cursor: pointer;
    }
  `;

const DropDownPopover = ({
  children,
  anchorEl,
  handleMenuClose,
  infoText,
  // shouldStyleLast
}: DropDownPopoverPopoverProps) => {

  const classes = useStyles();

  const isMenuOpen = Boolean(anchorEl);

  const renderMenu = children?.length > 0 ? 
  (
    <MenuDropDown>
      {
        children.map((child, index) => {
            return (
              <MenuDropDownItem key={index} className={classes.styledMenuItem}>
                {child}
              </MenuDropDownItem>
            );
        })
      }
    </MenuDropDown>
  ) : null;

  const id = isMenuOpen ? 'simple-popover' : undefined;

  return (
    <Popover
      id={id}
      open={isMenuOpen}
      anchorEl={anchorEl}
      onClose={handleMenuClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      classes={{
        paper: classes.paper
      }}
      style={{
        marginTop: "10px",
        width: "600px",
        padding: "0 !important",
        maxHeight: "75%"
      }}
    >
      {infoText && (
				<div
					style={{
						position: 'sticky',
						top: '0',
						width: '100%',
						backgroundColor: 'white',
						padding: '10px',
						fontSize: '.75em',
					}}
				>
					<span>{infoText}</span>
				</div>
			)}
      {renderMenu}
    </Popover>
  );
};

export default DropDownPopover;
