import React from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import BannerCheckBox from './atoms/BannerCheckBox';
import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';

export type LoadingBannerProps = {
  id: string;
  message: string;
}

export interface BannerProps {
  errorMessages: string[];
  successMessages: string[];
  loadingBanners: LoadingBannerProps[];
  onCloseErrorMessage: (message) => void;
  onCloseSuccessMessage: (message) => void;
};

export const Banner = ({
  errorMessages,
  successMessages,
  loadingBanners,
  onCloseErrorMessage,
  onCloseSuccessMessage,
}: BannerProps) => {

  const genericBanner = (icon, backgroundColor, message, onClose?, onClick?) => {
    return (
      <div id="banner" style={{ 
          padding: "13px",
          backgroundColor: backgroundColor,
          borderRadius: "8px",
          marginBottom: "10px",
          boxShadow: "2px 2px 12px rgb(128 128 128 / 16%)"
        }}
        onClick={onClick}
        key={message}
      >
        <div className="d-flex">
          <div style={{ display: "inline-block", verticalAlign: "top", lineHeight: "17px", marginRight: "8px" }}>
              {icon}
          </div>
          <div
            className="me-3"
            style={{
            fontSize: "14px",
            fontWeight: 600
            }}
          >
            {message}
          </div>
        </div>
        <div style={{
          cursor: 'pointer',
          position: 'absolute',
          right: 5,
          top: 5,
          visibility: onClose ? "visible" : "hidden"
        }}>
          <AiOutlineCloseCircle size={25} onClick={onClose} />
        </div>
      </div>
    );
  }
  
  const getSuccessMessage = (message) => {
    return genericBanner(
      <BannerCheckBox checked={true} color={"#46BA28"} />, 
      "#dcf6d4",
      message,
      onCloseSuccessMessage
    );
  }

  const getErrorMessage = (message) => {
    return genericBanner(
      <BannerCheckBox isX={true} checked={true} color={"#f50057"} />, 
      "#ffcfe0", 
      message,
      onCloseErrorMessage
    );
  }

  const getLoadingBanner = ({ message }: LoadingBannerProps) => {
    return genericBanner(
      <BannerCircularProgress />,
      '#185adb17',
      message,
      undefined,
      undefined,
    );
  };

  return (
    <div style={{
      position: "sticky",
      right: 0,
      top: "25px",
      zIndex: 100,
      maxWidth: "525px",
      marginRight: "15px",
      opacity: ".95"
     }}>
      {errorMessages.map(x => getErrorMessage(x))}
      {loadingBanners.map(x => getLoadingBanner(x))}
      {successMessages.map(x => getSuccessMessage(x))}
    </div>
  );
};

const BannerCircularProgress = styled(CircularProgress)`
  width: 17px !important;
  height: 17px !important;
  color: #000 !important;
  vertical-align: middle !important;
`;
