import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { listsDetailPageActions } from 'app/containers/ListsPage/containers/ListsDetailPage/slice';
import { selectListsDetailPage } from 'app/containers/ListsPage/containers/ListsDetailPage/selectors';

export const useOnDelete = () => {
	const dispatch = useDispatch();
	const { contactFilters } = useSelector(selectListsDetailPage);
	const onDelete = useCallback(
		({ type, value }: { value: string; type: 'include' | 'exclude' }) =>
			() => {
				dispatch(
					listsDetailPageActions.updateContactFilters({
						...contactFilters,
						licenseTypes: {
							include:
								type === 'include' ? contactFilters.licenseTypes.include.filter((licenseType) => licenseType !== value) : contactFilters.licenseTypes.include,
							exclude:
								type === 'exclude' ? contactFilters.licenseTypes.exclude.filter((licenseType) => licenseType !== value) : contactFilters.licenseTypes.exclude,
						},
					}),
				);
			},
		[dispatch, contactFilters],
	);

	return onDelete;
};
