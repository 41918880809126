import React, { useState } from 'react';
import './style.scss';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Paper from '@material-ui/core/Paper';
import { Modal } from 'molecules/Modal';
import Title from 'atoms/Title';

export interface MoveContactListChooserModalProps {
  isOpen: boolean;
  onMove: (toListId) => any;
  lists: any[];
  onClose: () => any;
}

export const MoveContactListChooserModal = ({
  isOpen,
  onMove,
  lists,
  onClose
}: MoveContactListChooserModalProps) => {

  const cleanLists = lists
    ?.sort((a,b) => a.name.localeCompare(b.name));

  const [selectedList, setSelectedList] = useState(cleanLists?.[0]?.id);

  return (
    <Modal 
      onClose={() => {}}
      isOpen={isOpen}>
        <Paper style={{ padding: "30px", minWidth: "400px" }}>
          <Container>
            <div style={{ textAlign: "center" }}>
              <Title text={'Choose List to Move To'} />
            </div>
            <Row>
              <Col lg={{ span: 12 }} xl={{ span: 12 }}>
                <Form className="dev-form">
                  <div className="form-content" style={{ marginBottom: "20px", maxHeight:'50vh', overflowX:'hidden', overflowY:'scroll' }}>
                    {
                      lists?.length > 0 ?
                        <ul style={{
                          listStyleType: "none", /* Remove bullets */
                          padding: 0, /* Remove padding */
                          margin: 0 /* Remove margins */
                        }}>
                          {
                            lists
                              ?.filter(l => l.id != -1)
                              .sort((a,b) => a.name.localeCompare(b.name))
                              .map((list, index) => (
                                <li 
                                  style={{ 
                                    padding: "10px", 
                                    borderRadius: "10px", 
                                    marginBottom: "5px",
                                    width: "100%", 
                                    border: selectedList == list.id ? "1px solid #185ADB" : "transparent" 
                                  }}
                                  onClick={() => setSelectedList(list.id)}
                                >{list.name}</li>
                              ))
                          }
                        </ul> : 
                        <div style={{ textAlign: "center" }}>
                          <p style={{ color: "gray" }}>No lists to move to</p>
                        </div>
                    }
                  </div>
                  <div className="form-footer" style={{ textAlign: "right" }}>
                    <Button variant="secondary" style={{ marginRight: "12px" }} onClick={onClose}>Cancel</Button>
                    <Button disabled={lists?.length == 0} onClick={() => onMove(selectedList)} variant="primary">Move</Button>
                  </div>
                </Form>
              </Col>
            </Row>
          </Container>
        </Paper>
    </Modal>
  );
};
