import cn from 'classnames';
import { ReactNode, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BaseModal, BaseModalProps } from 'new-beginning/components/shared/Modals/BaseModal';
import {
	ParentAccountAssignContainer,
	ExistingOrphanAccountContainer,
	PendingInsertAccountContainer,
	InsertAccountAssignContainerProps,
	ExistingAccountAssignContainerProps,
} from 'new-beginning/components/pages/ListSync/SubComponents';
import {
	AccountInsertAssignInstance,
	OrphanAccountInstance,
	ChildAccountAssignType,
} from 'common.model/src/db/model-decorators/type-extensions';
import { PaginationControlSimple } from 'new-beginning/components/shared/Pagination/PaginationControlSimple';
import { useAssignParentAccount } from 'new-beginning/hooks/salesforce/useAssignParentAccount';

export interface AccountResolveBaseModalProps extends Partial<BaseModalProps> {
	accountReassignSet: AccountInsertAssignInstance[] | OrphanAccountInstance[];
	assignAccountCallback: () => Promise<void>;
	childAccountType: ChildAccountAssignType;
}

const ChildAccountComponent: Record<ChildAccountAssignType, any> = {
	INSERT: (props: InsertAccountAssignContainerProps) => <PendingInsertAccountContainer {...props} />,
	UPDATE: (props: ExistingAccountAssignContainerProps) => <ExistingOrphanAccountContainer {...props} />,
};

const modalHeaderTemplate: Record<ChildAccountAssignType, string> = {
	UPDATE: 'Resolve Orphaned Accounts',
	INSERT: 'Select Parent Account for Pending Accounts',
};
const accountUpdateTemplate: Record<ChildAccountAssignType, (string) => ReactNode> = {
	INSERT: (accountWebsite) => (
		<span>
			We could not determine the Parent Account in your Salesforce for <strong>"{accountWebsite}"</strong>. Select an Account to be
			used as the Parent Account when BenefitFlow inserts new Child Accounts (Office Locations) for{' '}
			<strong>"{accountWebsite}"</strong>.
		</span>
	),
	UPDATE: (accountWebsite) => (
		<span>
			When syncing your Contacts we encountered Accounts in your Salesforce for <strong>"{accountWebsite}"</strong> that do not have a
			Parent Account assigned. Select the Parent Account to be assigned to the existing orphaned Accounts for{' '}
			<strong>"{accountWebsite}"</strong> listed below.
		</span>
	),
};

export const AccountResolveBaseModal = ({
	isVisible,
	cancelAction,
	assignAccountCallback,
	accountReassignSet,
	childAccountType,
}: AccountResolveBaseModalProps) => {
	const numAccounts = accountReassignSet?.length || 0;

	const [pageNum, setPageNum] = useState<number>(0);
	const paginationState = {
		count: numAccounts,
		numPages: numAccounts,
		offset: pageNum,
		limit: 1,
	};
	const accountReassignInstance = accountReassignSet?.[pageNum];
	const accountWebsite = accountReassignInstance?.accountWebsite;

	const [parentIdMap, setSelectedParentId] = useState<Record<string, string>>({});
	const setSelectedAccount = (accountId: string) => {
		const currentAccountToSet = parentIdMap?.[accountWebsite];

		const accountToSet = accountId === currentAccountToSet ? null : accountId;
		setSelectedParentId({ ...parentIdMap, [accountWebsite]: accountToSet });
	};

	const { listId } = useParams();
	const { setParentAccount, loading: parentUpdateLoading } = useAssignParentAccount(listId, childAccountType, assignAccountCallback);

	const confirmParentAccount = async () => {
		console.log('Setting Parent Account to: ', parentIdMap);
		try {
			const parentId = parentIdMap?.[accountWebsite];
			const childAccounts = accountReassignInstance?.childAccountsToAssign;
			await setParentAccount(parentId, childAccounts);
		} catch (err) {
			console.error('Failed to Set Parent Account: ', parentIdMap);
		} finally {
			setSelectedAccount(null);
		}
	};

	const closeModal = () => {
		setSelectedAccount(null);
		cancelAction();
	};

	const selectedParentId = parentIdMap?.[accountWebsite];
	const ChildAccounts = ChildAccountComponent[childAccountType];

	const subTextTemplate = accountUpdateTemplate[childAccountType];
	const modalHeaderText = modalHeaderTemplate[childAccountType];
	const modalSubText = subTextTemplate(accountWebsite);

	return (
		<BaseModal
			isVisible={isVisible}
			headerText={modalHeaderText}
			headerNav={<PaginationControlSimple setPage={setPageNum} paginationState={paginationState} isLoading={false} />}
			cancelAction={cancelAction}
			className={cn('list-assign-modal')}
		>
			<div className={cn('d-flex', 'flex-column', 'justify-content-between')}>
				<div className={cn('py-1', 'mx-3', 'my-2', 'primary-content')}>
					<div className={cn('text-left', 'text-wrap', 'pb-2')}>
						<p className={cn('bf-txt', 'txt-xs', 'txt-lighter')}>{modalSubText}</p>
					</div>
					<div className={cn('d-flex', 'flex-column', 'align-items-start', 'my-2')}>
						<ParentAccountAssignContainer
							accountReassignInstance={accountReassignInstance}
							selectRow={setSelectedAccount}
							selectedRow={selectedParentId}
						/>
					</div>
					<div className={cn('d-flex', 'flex-column', 'align-items-start', 'my-2')}>
						<ChildAccounts accountReassignInstance={accountReassignInstance} />
					</div>
				</div>
				<div className={cn('d-flex', 'align-items-center', 'justify-content-between', 'p-3', 'modal-footer')}>
					<button onClick={() => closeModal()} className={cn('bf-btn', 'bf-btn-outline', 'x-pd', 'me-1')}>
						<p className={cn('bf-txt', 'd-inline-block')}>Cancel</p>
					</button>
					<button
						onClick={confirmParentAccount}
						disabled={!selectedParentId || parentUpdateLoading}
						className={cn('bf-btn', 'bf-btn-primary', { loading: parentUpdateLoading }, 'x-pd', 'me-1')}
					>
						{parentUpdateLoading ? (
							<span>
								<span className={cn('spinner-border', 'spinner-border-sm')} style={{ color: 'white' }} />
								<span className={cn('bf-txt', 'ms-2')}>Updating</span>
							</span>
						) : (
							<p className={cn('bf-txt', 'd-inline-block')}>Confirm</p>
						)}
					</button>
				</div>
			</div>
		</BaseModal>
	);
};
