import { useCallback, useContext, useMemo } from 'react';
import { Box, Checkbox, Skeleton, Typography } from '@benefitflow/designsystem';

import { FormattingUtils } from '../../../../../../ui-components';
import { ContactListHeaderContext } from '../../ContactListHeader.context';

export const RecordsCounter = () => {
	const {
		type,
		context: { contacts, numberOfSelectedContacts, totalNumberOfContacts, isLoading },
		store: { checkMode, toggleCheckMode },
	} = useContext(ContactListHeaderContext);
	const isSelectAll = useMemo(() => checkMode === 'select-all', [checkMode]);
	const onChange = useCallback(() => toggleCheckMode(contacts), [toggleCheckMode, contacts]);
	const contactTypeLabel = useMemo(() => (type === 'employer' ? 'Employer' : 'Broker'), [type]);
	if (isLoading) return <Skeleton width="xl7" height="sm" containerBackground="gray" />;

	return (
		<Box display="flex" alignItems="center" gap="xs">
			<Checkbox checked={isSelectAll} onChange={onChange} />
			<Typography.Text whiteSpace="nowrap" color="neutral-500">
				{FormattingUtils.formatCommas(numberOfSelectedContacts)} Selected
			</Typography.Text>
			<Typography.Text whiteSpace="nowrap" color="neutral-500">
				/ {FormattingUtils.formatCommas(totalNumberOfContacts)} {contactTypeLabel} Contacts
			</Typography.Text>
		</Box>
	);
};
