export interface EmployeeSentimentProps {
	/**
	 * Evaluation variation in the last year. Number between -100 and 100.
	 */
	yearlyVariation: number;
	/**
	 * Number between 0 and 5.
	 */
	evaluation: number;
	/**
	 * Summary of the employee sentiment.
	 */
	type: InsightDirectionTypeEnum;
	summary: string;
	benefits: BenefitCategoryProps[];
}

export interface BenefitCategoryProps {
	title: string;
	icon: string;
	iconBackgroundColor: string;
	insights: InsightProps[];
}

export interface InsightProps {
	type: InsightSentimentTypeEnum;
	text: string;
	boldWords?: string[];
}

export enum InsightSentimentTypeEnum {
	INFO = 'info',
	POSITIVE = 'positive',
	NEGATIVE = 'negative',
}

export enum InsightDirectionTypeEnum {
	INCREASE = 'increase',
	DECREASE = 'decrease',
	NEUTRAL = 'neutral',
}
