import { Box, Button, Icon, Skeleton } from '@benefitflow/designsystem';

import { BrokerContactCardProps } from '../../../pages/Search/BrokerContactCard/BrokerContactCard.types';

export const Buttons = {
	GetContacts: ({
		isPurchasable,
		isPurchased,
		purchaseContact,
		loading,
		gettingContact,
	}: Pick<BrokerContactCardProps, 'isPurchasable' | 'isPurchased' | 'loading'> & {
		purchaseContact: () => void;
		gettingContact: boolean;
	}) => {
		if (loading) return <Skeleton height="xl" width="xl4" />;
		if (!isPurchasable) return null;
		if (isPurchased)
			return (
				<Box backgroundColor="success-100" p="xs" borderRadius="sm">
					<Box backgroundColor="success-500" borderRadius="round" p="xxs" style={{ color: '#32CC8C' }}>
						<Icon name="check" size="sm" color="white" />
					</Box>
				</Box>
			);
		return (
			<Button data-testid="get-contact-button" fetching={gettingContact} onClick={purchaseContact}>
				Get Contact
			</Button>
		);
	},
};
