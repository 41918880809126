import React, { ReactNode } from 'react';
import { GlobalStyle } from 'shared/global-styles';
import { Banner, BannerProps } from 'molecules/Banner';
import { Box } from '@benefitflow/designsystem';
import '../../../designTokens/assets/sass/components/main.scss';

export interface AppPageProps {
	NavBarElement: ReactNode;
	showBanner: boolean;
	banner: BannerProps;
	children?: React.ReactNode;
}

export function AppPage({ NavBarElement, ...props }: AppPageProps) {
	return (
		<>
			{NavBarElement}
			{props.showBanner && (
				<>
					<div style={{ position: 'fixed', width: 'auto', right: 0, display: 'flex', flexDirection: 'row-reverse', zIndex: '100' }}>
						<Banner {...props.banner} />
					</div>
				</>
			)}
			<Box>{props.children}</Box>
			<GlobalStyle />
		</>
	);
}
