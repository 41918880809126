import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectListsDetailPage } from 'app/containers/ListsPage/containers/ListsDetailPage/selectors';
import { listsDetailPageActions } from 'app/containers/ListsPage/containers/ListsDetailPage/slice';

export const useOnExclude = () => {
	const dispatch = useDispatch();
	const { contactFilters } = useSelector(selectListsDetailPage);

	const onExclude = useCallback(
		({ value }: { value: string }) => {
			dispatch(
				listsDetailPageActions.updateContactFilters({
					...contactFilters,
					roles: {
						exclude: [...contactFilters.roles.exclude, value],
						include: contactFilters.roles.include,
					},
				})
			);
		},
		[dispatch, listsDetailPageActions, contactFilters]
	);

	return onExclude;
};
