import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectListsDetailPage } from 'app/containers/ListsPage/containers/ListsDetailPage/selectors';
import { listsDetailPageActions } from 'app/containers/ListsPage/containers/ListsDetailPage/slice';

export const useMin = () => {
	const dispatch = useDispatch();
	const { contactFilters: filters } = useSelector(selectListsDetailPage);
	const set = useCallback(
		(min: number) => {
			dispatch(
				listsDetailPageActions.updateContactFilters({
					...filters,
					yearsAtCompany: [min, filters.yearsAtCompany[1]],
				})
			);
		},
		[dispatch, filters, filters.yearsAtCompany[1], listsDetailPageActions]
	);

	const value = useMemo(() => filters.yearsAtCompany[0], [filters.yearsAtCompany[0]]);

	return {
		value,
		set,
	};
};
