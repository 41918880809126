import { useMemo } from 'react';

import { useGetValueByScreenSizes } from '../../../../../hooks/useGetValueByScreenSizes';

export const useHeight = () => {
	const getValueByScreenSizes = useGetValueByScreenSizes();
	const height = useMemo(() => getValueByScreenSizes({ lg: 130, md: 365, sm: 500 }), [getValueByScreenSizes]);

	return height;
};
