import { APIClient } from '../types';
import { ExportApi } from './ExportApi';

export class ExportApiHTTP implements ExportApi {
	constructor(readonly client: APIClient) {}

	async createExport({ params, type }) {
		const response = await this.client.post<any, any>('/data-export/createExport', { exportType: type, exportParams: params });
		if (response.data.res.success) return response.data.res.data;
		throw new Error('Failed to create export.');
	}

	async getStatus(exportId: string) {
		const response = await this.client.post<any, any>('/data-export/checkExportStatus', { exportId });
		if (response.data.res.success) return response.data.res.data.status;
		throw new Error('Failed to get export status.');
	}

	async getDownloadLink(exportId: string) {
		const response = await this.client.post<any, any>('/data-export/downloadExport', { exportId });
		if (response.data.res.success) return response.data.res.data;
		throw new Error('Failed to get export link.');
	}
}