import { TerciarySpanLarge } from "atoms/StyledComponents";
import { DropDown, OptionConfigProps, ValueConfigProps } from "../drop/DropDown";
import styled from "styled-components";

export interface DropDownRangeSelectorProps {
    lower: {
        title: string;
        value: ValueConfigProps;
        options: OptionConfigProps[];
        onValueChange: (newValue: any) => void;
    },
    upper: {
        title: string;
        value: ValueConfigProps;
        options: OptionConfigProps[];
        onValueChange: (newValue: any) => void;
    },
    spacerLabel: string;
};

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;

    .styled-dropdown {
        width: 50%;
    }

    .dropdown-range-picker-between {
        width: 20%;
        text-align: center;
    }
`;

export const DropDownRangeSelector = ({ lower, upper, spacerLabel }: DropDownRangeSelectorProps) => {
    return (
        <Container className='dropdown-range-picker'>
            <DropDown
                key={'dropdown-range-picker-min'}
                isDeleteable={false}
                onChange={lower.onValueChange}
                values={[lower.value]}
                options={lower.options}
                title={lower.title}
                disableMultiSelect={true}
                disableSearch={true}
            />
            <TerciarySpanLarge className='dropdown-range-picker-between'>{spacerLabel}</TerciarySpanLarge>
            <DropDown
                key={'dropdown-range-picker-max'}
                isDeleteable={false}
                onChange={upper.onValueChange}
                values={[upper.value]}
                options={upper.options}
                title={upper.title}
                disableMultiSelect={true}
                disableSearch={true}
            />
        </Container>
    );
};
