import { Box } from '@benefitflow/designsystem';

import { DeleteSelectedButton, ExportSelectedButton, GetSelectedButton, MoveSelectedButton, RecordsCounter } from './components';
import { useGetValueByScreenSizes } from '../../../../../hooks/useGetValueByScreenSizes';

export const Header = () => {
	const getValueByScreenSizes = useGetValueByScreenSizes();

	return (
		<Box display="flex" justifyContent="between" alignItems="center" flexDirection={getValueByScreenSizes({ lg: 'row', md: 'column', sm: 'column' })}>
			<RecordsCounter />
			<Box
				flexWrap="wrap"
				display="flex"
				alignItems="center"
				justifyContent={getValueByScreenSizes({ lg: 'end', md: 'center', sm: 'center' })}
				gap="sm"
				mb="md"
			>
				<GetSelectedButton />
				<DeleteSelectedButton />
				<MoveSelectedButton />
				<ExportSelectedButton />
			</Box>
		</Box>
	);
};
