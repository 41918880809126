import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectListsDetailPage } from 'app/containers/ListsPage/containers/ListsDetailPage/selectors';

export const useValues = () => {
	const {
		contactFilters: { carrierAppointments },
	} = useSelector(selectListsDetailPage);

	const values = useMemo(() => {
		if (!carrierAppointments?.include?.length) return [];

		return [{ value: carrierAppointments?.include[0], label: carrierAppointments?.include[0], type: 'include' as const }];
	}, [carrierAppointments]);

	return values;
};
