export enum QueryPath {
	List = 'lists/getAllListsForUser',
	EmployeeSentiment = 'employer/getEmployerInsights',
	IntegrationConnectionDetails = 'crm-integration/getIntegrationConnectionDetails',
	EmployeeSummary = 'employer/getEmployerSummary',
	Brokers = 'broker/search',
	BrokersFilters = '/broker/searchResultsBreakdown',
	GetBrokerOffices = 'broker/getBrokerOffices',
	BrokerContactsFilters = 'brokerContacts/searchResultsBreakdown',
	EmployerContactFilters = 'employer/searchContactsBreakdown',
}

export enum MutationPath {
	DeleteList = 'lists/deleteList',
}
