import { Box, Paper, Skeleton } from '@benefitflow/designsystem';

export const Loading = ({ numberOfRows }: { numberOfRows: number }) => {
	return (
		<Paper p="lg">
			<Box display="flex" flexDirection="column" gap="lg">
				<Skeleton height="sm" width="xl6" />
				{new Array(numberOfRows).fill(null).map(() => (
					<Skeleton height="sm" width="full" />
				))}
			</Box>
		</Paper>
	);
};
