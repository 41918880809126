import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { StackedBar } from 'molecules/charts/StackedBar';
import './style.scss';
import { FormattingUtils } from "utils/index";
import DateUtils from 'utils/DateUtils';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { terciaryColor } from 'designTokens/colors';
import { ToolTip } from 'molecules/ToolTip1';
import { usePolicies } from '../../hooks/usePolicies';
import BenefitsTableModel from './BenefitsTableModel';


export interface BenefitsOfferedTableProps {
  employerMasterId: string;
  selectedFilingYear: number;
}

export const BenefitsOfferedTable = (props: BenefitsOfferedTableProps) => {

  const {
    data,
    loading,
    brokerNameToColor,
    carrierNameToColor
  } = usePolicies({ employerMasterId: props.employerMasterId, selectedFilingYear: props.selectedFilingYear });

  const benefitsOfferedTable = {
      data: BenefitsTableModel
          .getConfig(
              data,
              brokerNameToColor,
              carrierNameToColor,
              loading
          )
  };

  const columns = [
    'Benefits Offered',
    'Covered Lives',
    'Renewal',
    'Broker Commissions',
    'Carrier Premiums',
    'PEPM',
  ];  

  return (
    <div className={`dev-stats-table`}>
      <div className="table-wrapper">
        <div className="stable-header">
          <div className="stable-row">
            {columns.map((item: any) => {
              return <div className="cell">{item}</div>;
            })}
          </div>
        </div>
        <div className="stable-body">
          {benefitsOfferedTable.data?.policies.map((item: any) => {
            return (
              <>
                <div
                  className={`stable-row subtitle ${
                    item.dark ? 'dark-bg' : ''
                  }`}
                >
                  <div className="cell">{item.subtitle}</div>
                  <div className="cell"></div>
                  <div className="cell"></div>
                  <div className="cell"></div>
                  <div className="cell"></div>
                  <div className="cell"></div>
                </div>

                {item.data.map((row: any) => {
                  return row.loading ? (
                    <div className="stable-row">
                      <div className="cell">
                        <Skeleton variant="rect" width="100%" />
                      </div>
                      <div className="cell">
                        <Skeleton variant="rect" width="100%" />
                      </div>
                      <div className="cell">
                        <Skeleton variant="rect" width="100%" />
                      </div>
                      <div className="cell">
                        <Skeleton variant="rect" width="100%" />
                      </div>
                      <div className="cell">
                        <Skeleton variant="rect" width="100%" />
                      </div>
                      <div className="cell">
                        <Skeleton variant="rect" width="100%" />
                      </div>
                    </div>
                  ) : (
                    <div className="stable-row">
                      <div className="cell">
                        <div style={{ width: "100%", position: "relative" }}>
                          <span style={{ display: "inline-block", width: "calc(100% - 20px)" }}>
                            {row.benefitsOffered}
                          </span>
                          <span style={{ display: "inline-block", width: "20px", verticalAlign: "top" }}>
                            <ToolTip
                              key={row.master_provider_id}
                              title={"Add'tl Info From Filing"}
                              keyValuePairs={
                                row.otherText ? [['Other', row.otherText]] : []
                              }
                            >
                              {
                                row.otherText ?
                                  <i className="ico"><BsFillInfoCircleFill color={terciaryColor} size="15px" /></i> :
                                  <span></span>
                              }
                            </ToolTip>
                          </span>
                        </div>
                      </div>
                      <div className="cell">
                        {FormattingUtils.formatNumberAbbrev(row.coveredLives)}
                      </div>
                      <div className="cell">
                        {DateUtils.formatLongMonthDay(row.renewalDate)}
                      </div>
                      <div className="cell">
                        <StackedBar
                          data={row.brokers}
                          maxValue={benefitsOfferedTable.data?.maxBrokerCommissions}
                          showTotal
                          getToolTipConfig={brokers => {
                            return {
                              title: 'Commissions',
                              keyValuePairs: brokers.map(({ name, value }) => [
                                FormattingUtils.formatUpperCasing(name),
                                value
                                  ? FormattingUtils.formatNumberAbbrev(value)
                                  : 'N/A',
                              ]),
                            };
                          }}
                        />
                      </div>
                      <div className="cell">
                        <StackedBar
                          data={row.carriers}
                          maxValue={benefitsOfferedTable.data?.maxCarrierPremiums}
                          showTotal
                          getToolTipConfig={carriers => {
                            return {
                              title: 'Premiums',
                              keyValuePairs: carriers.map(({ name, value }) => [
                                FormattingUtils.formatUpperCasing(name),
                                value
                                  ? FormattingUtils.formatNumberAbbrev(value)
                                  : 'N/A',
                              ]),
                            };
                          }}
                        />
                      </div>
                      <div className="cell">${row.pepm}</div>
                    </div>
                  );
                })}
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};
