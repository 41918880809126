import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getTagLabel } from 'new-beginning/components/shared/SidebarFilters/components/OfficeLocation/helpers';
import { selectListsDetailPage } from 'app/containers/ListsPage/containers/ListsDetailPage/selectors';

export const useValues = () => {
	const { contactFilters } = useSelector(selectListsDetailPage);
	const values = useMemo(
		() => [
			...(contactFilters?.contactLocation?.include?.map((loc) => {
				const label = getTagLabel(loc);
				return { label, value: loc.location.searchValue, type: 'include' as const };
			}) ?? []),
			...(contactFilters?.contactLocation?.exclude?.map((loc) => {
				const label = getTagLabel(loc);
				return { label, value: loc.location.searchValue, type: 'exclude' as const };
			}) ?? []),
		],
		[contactFilters]
	);

	return values;
};
