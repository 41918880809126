import "./style.scss"
import React from 'react';

interface Props {
  className?: string
  label?: string
  color?: string
  checked?: boolean
  onChange?: any
  isX?: boolean
}

const Component = ({ className, label, color, checked, onChange, isX }: Props) => {
  const style = { height: "17px", width: "17px" };
  if (color) {
    style["backgroundColor"] = color;
    style["border"] = "none";
  }
  if (isX) {
    style["backgroundImage"] = "url(x_icon_2.svg)";
    style["backgroundSize"] = "50% auto";
  }
  return (
     <div className={`dev-check form-check ${className ?? ''}`} style={{ minHeight: "unset"}}>
          <input 
            type="checkbox"
            checked={checked}
            onChange={onChange}
            style={style}
            className="form-check-input" />
          {/* { label && <label className="custom-control-label">{label}</label>} */}
          
      </div>
  );
}

export default Component;
