import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { appActions } from 'app/slice';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import {NavLink, useNavigate} from 'react-router-dom';
import { IoLogOutOutline } from 'react-icons/io5';
import { Nav, Navbar as RBNavbar } from 'react-bootstrap';
import { People } from 'react-iconly';
import { IoIosSearch, IoMdBusiness, IoIosList } from 'react-icons/io'
import { TbSchool } from 'react-icons/tb';
import NavDropDown from './molecules/NavDropDown';
import {LoggedInUserContext, UserContextType} from 'new-beginning/context/LoggedInUser';
import { MdSwapVert } from 'react-icons/md';
import './style.scss';
import { logo } from 'assets/index';

export interface NavBarProps {
  isClientAdmin: boolean;
  isBfAdmin: boolean;
  isAuthenticated: boolean;
  userName: string;
  tenants: any[];
}

export const NavBar = (props: NavBarProps) => {
  const navigate = useNavigate();
  const [hideNav, setHideNav] = useState<boolean>(false);

  const dispatch = useDispatch();
  const { authUser, setTenantIdOverride }: UserContextType = useContext(LoggedInUserContext);
  const tenantOverride = authUser?.tenantIdOverride;
  const setTenantOverride = (tenantId: string) => {
    setTenantIdOverride(tenantId);
    dispatch(appActions.setSelectedLoginAsTenant(tenantId))
  };

  useEffect(() => {
    const hideNav = ['/login', '/register', '/'].includes(location.pathname.toLowerCase());
    setHideNav(hideNav);   
  }, [location.pathname]);

  if (hideNav) {
    return null;
  }

  const userNavItems = getUserNavItems({ logoutFn: () => dispatch(appActions.logout()), navigate, isAdmin: props.isBfAdmin || props.isClientAdmin });

  return (
    <div className="dev-navbar">
      <RBNavbar expand="lg">
        <RBNavbar.Brand href="/Search">
          <img src={logo} style={{ height: '32px' }} />
        </RBNavbar.Brand>
        <RBNavbar.Toggle aria-controls="responsive-navbar-nav" />
        <RBNavbar.Collapse id="responsive-navbar-nav">
          <Nav className="m-auto">
            <NavLink to="/Search" className="nav-link">
              <span className="link-icon">
                <IoIosSearch size={26} />
              </span>
              Search
            </NavLink>
            <NavLink to="/Lists" className="nav-link">
              <span className="link-icon">
                <IoIosList size={26} />
              </span>
              Lists
            </NavLink>
            <a
              href={'http://21206862.hs-sites.com/knowledge-base'}
              target="_blank"
              rel="noreferrer"
              className="nav-link"
              style={{ cursor: "pointer" }}
            >
              <span className="link-icon">
                <TbSchool size={26} />
              </span>
              Learning
            </a>
            {
              props.isBfAdmin ? 
                <NavLink to="/Clients" className="nav-link">
                  <span className="link-icon">
                    <IoMdBusiness size={26}  />
                  </span>
                  Clients
                </NavLink> : null
            }
            
          </Nav>
          {/* Right */}
          <div>
            {props.isAuthenticated && (
              <div style={{ float: "right" }}>
                <NavDropDown 
                  getNavButtonEl={(isMenuOpen) => (
                    <div>
                      <span>{props.userName}</span>
                      {isMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </div>
                  )}
                  getPopOverChildren={
                    (closeMenu) => {
                      return userNavItems.map(({ label, onClick, icon }) => (
                        <div
                          className="user-nav-menu"
                          onClick={() => {
                            onClick();
                            closeMenu();
                          }}
                          key={label}
                        >
                          <span>
                            <span className="link-icon">{icon}</span>
                            <span style={{ marginLeft: "10px"}}>{label}</span>
                          </span>
                        </div>
                      ));
                    }
                  }
                />
              </div>
            )}
            {
              props.isBfAdmin ?
                <div style={{ float: "right" }}>
                <NavDropDown 
                  getNavButtonEl={(isMenuOpen) => (
                    <div>
                      <span>{tenantOverride ? tenantOverride : `Login As Tenant`}</span>
                      {isMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </div>
                  )}
                  getPopOverChildren={
                    (closeMenu) => (
                      props?.tenants?.slice()?.sort(
                        (a,b) => a.tenant_id.localeCompare(b.tenant_id)
                        )
                        ?.map(x => (
                          <span
                            key={x?.tenant_id}
                            style={{width: "100%"}}
                            onClick={() => {
                                setTenantOverride(x?.tenant_id);
                                closeMenu();
                              }}
                          >
                                {x?.tenant_id}
                          </span>
                        ))
                    )
                  }
                />
              </div> : null
            }
            
            <div style={{ flexGrow: 1 }} />
          </div>
        </RBNavbar.Collapse>
      </RBNavbar>
    </div>
  );
};

type NavItem = {
  label: string;
  onClick: () => void;
  icon: React.ReactNode;
};

const getUserNavItems = ({ logoutFn, navigate, isAdmin }): NavItem[] => {
  const baseItems = [
    {
      label: "Logout",
      onClick: () => {
        logoutFn();
      },
      icon: <IoLogOutOutline size={24} />,
    },
  ];
  const adminItems = [
    {
      label: "Team Management",
      onClick: () => {
        navigate("/Team");
      },
      icon: <People size={24} />,
    },
    {
      label: "Integrations",
      onClick: () => {
        navigate("/Integrations");
      },
      icon: <MdSwapVert size={24} />,
    },
    ...baseItems,
  ];
  return isAdmin ? adminItems : baseItems;
}
