import React from 'react';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import {
  FaCalendarAlt,
  FaMapMarkerAlt,
  FaBriefcase,
  FaUsers,
} from 'react-icons/fa';
import { AiFillHeart } from 'react-icons/ai';
import { IoIosBusiness } from 'react-icons/io'
import '../../../../../designTokens/assets/sass/components/_forms.scss';
// import 'designTokens/assets/sass/components/_forms.scss';
import { FormattingUtils, NaicsIndustries } from 'utils/index';
import Title from 'atoms/Title';
import { terciaryColor } from 'designTokens/colors';
import PlanParticipantsRangePicker from 'atoms/filters/range/PlanParticipantsRangePicker';
import { DropDown } from 'atoms/filters/drop/DropDown';
import DateRangePicker from 'atoms/filters/range/DateRangePicker';
import { BsShieldFillPlus } from 'react-icons/bs';
import lineOfBusiness from '../utils/LineOfBusinessOptions';
import entityTypes from '../utils/EntityTypeOptions';
import { CityStateDropDown } from 'atoms/filters/drop/CityStateDropDown';
import ControlledOpenUtils from 'pages/SearchPage/utils/ControlledOpenUtils';
import { BsFillBuildingsFill } from 'react-icons/bs';

const Container = styled.div`
  .ico-group {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: flex-start;

    .ico {
      color: #0a1931;
      font-size: 18px;
      width: 35px;
    }
  }

  .form-label {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    margin-bottom: 0.5rem;
  }

  .multi-select {
    display: grid;

    & > .select__control {
      width: 100%;
      overflow: hidden;
    }
  }

  .styled-dropdown {
    display: grid;
  }
`;

export interface EmployerFiltersProps {
  loading?: boolean;
  filters: any;
  filtersOpenMap: any;
  filtersSearchMap: any;
  hqSearchOptions: any[];
  filterOptions: any;
  isHqSearchOptionsLoading: boolean;
  carrierSearchOptions: any[];
  isEmployerCarrierOptionsLoading: boolean;
  onChange: (filters: any, fieldChanged) => void;
  onSearchHq: (search: string) => void;
  onSearchHqInfinitely: () => void;
  onChangeFiltersOpenMap: (filtersOpenMap: any) => void;
  onChangeFiltersSearchMap: (filtersSearchMap: any) => void;
  onSearchCarrier: (search: string) => void;
}

const industries = NaicsIndustries.shortValues.map(x => ({ option: x, formattedOption: x }));

const planTypes = [
  { option: 'Fully-Insured', formattedOption: 'Fully-Insured' },
  { option: 'Self-Funded', formattedOption: 'Self-Funded' }
];

export class EmployerFilters extends React.Component<EmployerFiltersProps, any> {

  updateFilters = (field, value) => {
    switch (field) {
      case 'states':
      case 'industries':
      case 'planTypes':
      case 'entityTypes':
      case 'lineOfBusiness':
      case 'carrierName': 
        this.props.onChange(
          {
            ...this.props.filters,
            [field]: value
          }, 
          field
        );
        break;
      case 'participantRangeFilters':
        this.props.onChange(
          {
            ...this.props.filters,
            [field]: value
          },
          field
        );
        break;
      case 'renewalDateFilters':
        this.props.onChange(
          {
            ...this.props.filters,
            [field]: value
          },
          field
        );
        break;
      case 'cities':
        break;
      default:
        break;
    }
  };

  componentDidUpdate(prevProps: EmployerFiltersProps) {
    const emptySearchOptions = this.props.carrierSearchOptions == null || this.props.carrierSearchOptions == undefined; 
    if (emptySearchOptions && !this.props.filtersSearchMap['EMPLOYER_CARRIER_SEARCH'] && !this.props.isEmployerCarrierOptionsLoading) {
      this.props.onSearchCarrier('');
    }
  }

  getControlledOpen(key: string) {
    return ControlledOpenUtils.getControlledOpen(key, this.props.filtersOpenMap, this.props.onChangeFiltersOpenMap);
  }

  getValues(key: string) {
    return (this.props.filters[key] ?? []).map(x => ({ value: x }));
  }

  getOptions(key: string) {
    return this.props.filterOptions[key] ?? [];
  }

  render() {
    return (
    <Container>
      <Form>
        <Title className="lg" text="Employer" />

        <Form.Group className="ico-group" style={{ marginBottom: '3rem' }}>
          <i className="ico">
            <FaUsers color={terciaryColor} size="20px" />
          </i>
          <div>
            <Form.Label>Employees</Form.Label>
              <PlanParticipantsRangePicker
                disabled={false}
                values={this.props.filters.participantRangeFilters}
                onChange={(e: any) =>
                  this.updateFilters('participantRangeFilters', e)
                }
              />
          </div>
        </Form.Group>

        <Form.Group className="ico-group" style={{ marginBottom: '2rem' }}>
          <i className="ico">
            <FaMapMarkerAlt color={terciaryColor} size="20px" />
          </i>
          <div>
            <Form.Label>HQ Location</Form.Label>
            <CityStateDropDown
              title={`Select Employer HQ Location(s)`}
              options={this.props.hqSearchOptions}
              values={this.props.filters['hq']}
              isLoading={this.props.isHqSearchOptionsLoading}
              onChange={(values: any[]) => {
                const filtersCpy = { 
                  ...this.props.filters, 
                  ['hq']: values
                };
                this.props.onChange(filtersCpy, 'hq')
              }}
              onSearch={this.props.onSearchHq}
              controlledOpen={this.getControlledOpen('EMPLOYER_HQ_LOCATION')}
              searchValue={this.props.filtersSearchMap['EMPLOYER_HQ_LOCATION']}
              onChangeSearchValue={(searchValue: string) => {
                const updated = {
                  ...this.props.filtersSearchMap,
                  ['EMPLOYER_HQ_LOCATION']: searchValue,
                };
                this.props.onChangeFiltersSearchMap(updated);
              }}
            />
          </div>
        </Form.Group>

        <Form.Group className="ico-group" style={{ marginBottom: '2rem' }}>
          <i className="ico">
            <FaBriefcase color={terciaryColor} size="20px" />
          </i>
          <div>
            <Form.Label>Industry</Form.Label>
            <DropDown
              isDeleteable={true}
              values={this.getValues('industries')}
              onChange={(e: any) => this.updateFilters('industries', e)}
              options={this.getOptions('industry')}
              title="Select Industry(s)"
              controlledOpen={this.getControlledOpen('EMPLOYER_INDUSTRY')}
            />
          </div>
        </Form.Group>

        <Form.Group className="ico-group" style={{ marginBottom: '2rem' }}>
          <i className="ico">
            <IoIosBusiness color={terciaryColor} size="20px" />
          </i>
          <div>
            <Form.Label>Entity Type</Form.Label>
            <DropDown
              isDeleteable={true}
              values={this.getValues('entityTypes')}
              onChange={values => {
                this.updateFilters('entityTypes', values)
              }}
              options={this.getOptions('entityTypes')}
              title="Select entity type"
              controlledOpen={this.getControlledOpen('EMPLOYER_ENTITY_TYPE')}
            />
          </div>
        </Form.Group>

        <Title className="lg" text="Plan" />

        <Form.Group className="ico-group" style={{ marginBottom: '2rem' }}>
          <i className="ico">
            <AiFillHeart color={terciaryColor} size="20px" />
          </i>
          <div>
            <Form.Label>Funding</Form.Label>
            <DropDown
              isDeleteable={true}
              values={this.getValues('planTypes')}
              onChange={values => {
                this.updateFilters('planTypes', values)
              }}
              options={this.getOptions('fundingType')}
              title="Select funding"
            />
          </div>
        </Form.Group>

        <Form.Group className="ico-group" style={{ marginBottom: '2rem' }}>
          <i className="ico">
            <BsShieldFillPlus color={terciaryColor} size="20px" />
          </i>
          <div>
            <Form.Label>Line Of Business</Form.Label>
            <DropDown
              isDeleteable={true}
              values={this.getValues('lineOfBusiness')}
              onChange={values => {
                this.updateFilters('lineOfBusiness', values)
              }}
              options={this.getOptions('lineOfBusiness')}
              title="Select Line Of Business"
              controlledOpen={this.getControlledOpen('EMPLOYER_LINE_OF_BUSINESS')}
            />
          </div>
        </Form.Group>

        <Form.Group className="ico-group" style={{ marginBottom: '2rem' }}>
          <i className="ico">
            <BsFillBuildingsFill color={terciaryColor} size="20px" />
          </i>
          <div>
            <Form.Label>Carrier Name</Form.Label>
            <DropDown
              isDeleteable={true}
              values={
                this.props.filters?.['carrierName']
                  ?.map(x => 
                    ({ 
                      value: x,
                      formattedValue: FormattingUtils.formatUpperCasing(x)
                    })
                  )
              }
              onChange={values => this.updateFilters("carrierName", values)}
              options={this.getOptions('carrierName')?.map(o => ({ option: o.option, formattedOption: FormattingUtils.formatUpperCasing(o.formattedOption) })) ?? []}
              title={'Select Carrier Name(s)'}
              controlledOpen={this.getControlledOpen('EMPLOYER_CARRIER_SEARCH')}
              maxRenderedOptions={100}
            />
          </div>
        </Form.Group>

        <Form.Group className="ico-group" style={{ marginBottom: '2rem' }}>
          <i className="ico">
            <FaCalendarAlt color={terciaryColor} size="20px" />
          </i>
          <div>
            <Form.Label>Renewal Date</Form.Label>
            <DateRangePicker
              disabled={false}
              values={
                this.props.filters.renewalDateFilters
              }
              onChange={(e: any) => this.updateFilters('renewalDateFilters', e)}
            />
          </div>
        </Form.Group>        
        
      </Form>
    </Container>
    );
  };
};