import cn from 'classnames';
import { BasePill } from 'new-beginning/components/shared/Pills';
import {
	FieldLevelChangeLog,
	ListContactType,
	ValidFieldChangelogProperties,
	ContactFieldChangeLog,
} from 'common.model/src/db/model-decorators/type-extensions';
import { Tooltip } from '@material-ui/core';
import { ReactNode } from 'react';

const fieldInChangelog = (fieldName: string, changelog: ContactFieldChangeLog) => fieldName && !!changelog?.[fieldName];
export const contactFieldHasUpdate = (
	fieldNames: ValidFieldChangelogProperties[] = [],
	listContact: ListContactType
): FieldLevelChangeLog => {
	const contactChangelog = listContact?.contactDiffItem?.field_changelog;
	const fieldNameWithUpdate = fieldNames.find((contactField) => fieldInChangelog(contactField, contactChangelog));
	const fieldLevelChanges = contactChangelog?.[fieldNameWithUpdate] || null;

	return fieldLevelChanges;
};

export const getFieldChangelogProperties = (
	fieldName: string,
	updateFieldSet: ValidFieldChangelogProperties[],
	listContact: ListContactType,
	showWarning: boolean = false,
): [string, FieldLevelChangeLog] => {
	const contactDiff = listContact?.contactDiffItem;
	const contactJustSynced = contactDiff?.deprecated === true && contactDiff?.status === 'PROCESSED';
	const value = listContact?.contact?.[fieldName];

	const fieldLevelChanges = contactFieldHasUpdate(updateFieldSet, listContact);
	const fieldDisplayClassname = cn({
		'txt-sync-update': !contactJustSynced && !!fieldLevelChanges,
		'txt-sync-executed': contactJustSynced && !!fieldLevelChanges,
		'cursor-ptr': !!fieldLevelChanges,
		'txt-neutral-off': !value && !showWarning,
	});

	return [ fieldDisplayClassname, fieldLevelChanges ];
};

interface SyncFieldProps {
	label: string;
	field: string;
	listContact: ListContactType;
	updateFieldSet?: ValidFieldChangelogProperties[];
	showWarning?: boolean;
}

export const ContactSyncField = ({
  label,
  field,
  listContact,
  showWarning = false,
  updateFieldSet = [],
}: SyncFieldProps) => {
	const value = listContact?.contact?.[field];
	const [ changeLogClassName, fieldLevelChanges ] = getFieldChangelogProperties(field, updateFieldSet, listContact, showWarning);
	return (
		<Tooltip
			title={
				!!fieldLevelChanges
				? (
					<div>
						<div className={cn('bf-txt', 'txt-nowrap', 'txt-xl', 'txt-white')}>
							<div className={cn('my-2', 'px-1')}>
								<span className={cn('bf-txt', 'txt-white')}>Prev:&nbsp;</span>
								<BasePill isVisible className='pill-unchanged' label={fieldLevelChanges?.previous || 'null'} />
							</div>
							<div className={cn('my-2', 'px-1')}>
								<span className={cn('bf-txt', 'txt-white')}>New:&nbsp;</span>
								<BasePill isVisible className='pill-update' label={fieldLevelChanges?.new || 'null'} />
							</div>
						</div>
					</div>
				)
				: ''
			}
		>
			<p
				className={cn('bf-txt', 'txt-detail', changeLogClassName)}
			>
				{!!value ? value : !showWarning && 'null'}
				{!value && showWarning && <BasePill isVisible={true} className='pill-warn' label={`No ${label}`} />}
			</p>
		</Tooltip>
	);
};


interface LabeledSyncFieldProps extends SyncFieldProps {
	className?: string;
}

export const LabeledContactSyncField = ({
  label,
  field,
  listContact,
  showWarning = false,
  updateFieldSet = [],
  className,
}: LabeledSyncFieldProps) => {
	return (
		<div className={cn('pb-2', className)}>
			<p className={cn('bf-txt', 'txt-detail-header')}>{label}</p>
			<ContactSyncField
				label={label}
				field={field}
				listContact={listContact}
				updateFieldSet={updateFieldSet}
				showWarning={showWarning}
			/>
		</div>
	);
};

interface LabeledAccountField {
	label: string | ReactNode;
	value: string | number;
	className?: string;
	valueClassName?: string;
}

export const AccountField = ({
	label,
	value,
	className,
	valueClassName,
}: LabeledAccountField) => (
	<div className={cn('pb-2', className)}>
		<p className={cn('bf-txt', 'txt-detail-header')}>{label}</p>
		<p className={cn('bf-txt', 'txt-detail', valueClassName)}>
			{!!value ? value :  'null'}
		</p>
	</div>
);
