import { useListDetailsFilterStore } from '../../../ListDetails.store';

export const useToggle = () => {
	const {
		collapsible: {
			toggle: { companyName },
		},
	} = useListDetailsFilterStore();

	return companyName;
};
