import { useCallback, useContext, useMemo, useState } from 'react';
import { maxContactOperationLimit } from 'common.services/src/search/constants';
import { Box, Button, Dialog, Divider, Paper, Typography } from '@benefitflow/designsystem';

import { FormattingUtils } from 'ui-components';
import { useGetSelected, useIsDisabled } from './hooks';
import { UseMutation } from './GetSelectedButton.types';
import { ListActionUtils } from '../../../../../utilities';
import { ContactListHeaderContext } from '../../ContactListHeader.context';
import { BrokerContactFilters, EmployerContactFilters } from 'common.model/src/types/common/search/filters/ContactFilters';

export const CONTACT_PURCHASE_WARN_THRESHOLD = 1000;

export const GetSelectedButton = <FilterType extends EmployerContactFilters | BrokerContactFilters>({
	useMutation,
}: {
	useMutation: UseMutation<FilterType>;
}) => {
	const {
		context: { contacts, numberOfSelectedContacts },
		store: { addPurchasedContact, checkMode, checkedMap, filters },
		type,
		listId,
	} = useContext(ContactListHeaderContext);
	const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
	const disabled = useIsDisabled({ checkedMap, checkMode, contacts });
	const { getSelected, loading } = useGetSelected({
		closeConfirmationDialog: () => setIsConfirmDialogOpen(false),
		addPurchasedContact,
		checkedMap,
		useMutation,
		checkMode,
		filters,
		type,
		listId,
	});
	const confirm = useCallback(() => {
		setIsConfirmDialogOpen(false);
		getSelected();
	}, [getSelected]);
	const onClick = useCallback(() => {
		if (numberOfSelectedContacts >= CONTACT_PURCHASE_WARN_THRESHOLD) setIsConfirmDialogOpen(true);
		else getSelected();
	}, [setIsConfirmDialogOpen, getSelected, numberOfSelectedContacts]);
	const zIndex = useMemo(() => ListActionUtils.getDialogZIndex(contacts.length), [contacts]);
	const isOverLimit = useMemo(() => numberOfSelectedContacts >= maxContactOperationLimit, [numberOfSelectedContacts]);

	return (
		<>
			<Button disabled={disabled} variant="primary" icon="user-check" onClick={onClick} fetching={loading}>
				Get Selected
			</Button>
			<Dialog zIndex={zIndex} open={isConfirmDialogOpen}>
				<Box>
					<Paper p="none">
						<Box display="flex" flexDirection="column">
							<Box p="lg" display="flex" flexDirection="column" gap="sm">
								<Typography.Text size="lg" color="neutral-700">{`Are you sure you want to use ${FormattingUtils.formatCommas(
									numberOfSelectedContacts,
								)} contact credits?`}</Typography.Text>
								<Typography.Text size="md" color="neutral-500">
									You will not be charged for contacts without an email or contacts you have already revealed.
									{/* Only the first {FormattingUtils.formatCommas(maxContactOperationLimit)} contacts in your selection will be added. */}
								</Typography.Text>
							</Box>
							<Divider />
							{isOverLimit && (
								<Box mt="xs" mr="xs" display="flex" justifyContent="end">
									<Typography.Text size="xs" color="neutral-700">
										Only the first {FormattingUtils.formatCommas(maxContactOperationLimit)} contacts in your selection will be added
									</Typography.Text>
								</Box>
							)}
							<Box m="lg" display="flex" alignItems="center" justifyContent="end" gap="md">
								<Box cursor="pointer" onClick={() => setIsConfirmDialogOpen(false)}>
									<Typography.Text color="primary-500">Cancel</Typography.Text>
								</Box>
								<Button onClick={confirm}>{`Get ${FormattingUtils.formatCommas(numberOfSelectedContacts)} contacts`}</Button>
							</Box>
						</Box>
					</Paper>
				</Box>
			</Dialog>
		</>
	);
};
