import { create } from 'zustand';

import { EmployerProfileStore, Tabs } from './EmployerProfile.types';

export const useEmployerProfileStore = create<EmployerProfileStore>((set) => ({
	setCurrentTab: (tab) => set({ currentTab: tab }),
	setCurrentTabTo: {
		[Tabs.BENEFITS]: () => set({ currentTab: Tabs.BENEFITS }),
		[Tabs.HISTORY]: () => set({ currentTab: Tabs.HISTORY }),
		[Tabs.CONTACTS]: () => set({ currentTab: Tabs.CONTACTS }),
		[Tabs.EMPLOYEE_SENTIMENT]: () => set({ currentTab: Tabs.EMPLOYEE_SENTIMENT }),
	},
}));
