import { Box, Checkbox, Collapsible, InputLabel } from '@benefitflow/designsystem';

import { Tag } from './components';
import { IconName } from '@benefitflow/designsystem/dist/components/Icon/Icon.types';

export interface CheckboxFilterProps {
	isOpen: boolean;
	toggleOpen: () => void;

	checked: boolean;
	setChecked: (checked: boolean) => void;

	title: string;
	description: string;
	option: string;
	icon: `${IconName}`;
	testId?: string;
}

export const CheckboxFilter = ({ checked, isOpen, setChecked, toggleOpen, description, icon, option, title, testId }: CheckboxFilterProps) => {
	return (
		<Collapsible
			headerTestId={testId && `${testId}-header`}
			isOpen={isOpen}
			toggle={toggleOpen}
			title={title}
			icon={icon}
			content={checked && !isOpen && <Tag onDelete={() => setChecked(false)} />}
			openContent={
				<InputLabel label={description}>
					<Box width="full">
						<Checkbox testId={testId && `${testId}-input`} checked={checked} onChange={(e) => setChecked(e.target.checked)} label={option} />
					</Box>
				</InputLabel>
			}
		/>
	);
};
