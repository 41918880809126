import { BrokerContactsStore } from 'new-beginning/components/pages/Search/BrokerContacts/BrokerContacts.types';

/**
 * Adaptor used to transform the state into the expected API format.
 */
export const getApiPayloadFromState = ({
	checkMode,
	checkedMap,
}: Pick<BrokerContactsStore, 'checkedMap' | 'checkMode'>): {
	checkedMap: Record<string, boolean>;
	notCheckedMap: Record<string, boolean>;
} => {
	return {
		checkedMap:
			checkMode === 'single-select'
				? Object.entries(checkedMap)
						.filter(([_, value]) => value)
						.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
				: {},
		notCheckedMap:
			checkMode === 'single-select'
				? {}
				: Object.entries(checkedMap)
						.filter(([_, value]) => !value)
						.reduce((acc, [key, value]) => ({ ...acc, [key]: !value }), {}),
	};
};
