import { BrokerContactFilters } from 'common.model/src/types/common/search/filters/ContactFilters.js';
import { BrokerContactsFiltersBreakdownResponse } from 'common.model/src/types/common/search/Breakdowns';
import { ContactSelectorMethods } from 'new-beginning/utilities/StoreUtils/createContactSelectorMethods';
import { ContactDTO } from 'new-beginning/queries/useContactsQuery/useContactsQuery';
import { StringMethod } from '../../../../utilities/StoreUtils/createStringMethods';
import { IncludeExcludeMethod } from '../../../../utilities/StoreUtils/createIncludeExcludeMethods';
import { LocationMethod } from '../../../../utilities/StoreUtils/createLocationMethods';
import { RangeMethod } from '../../../../utilities/StoreUtils/createRangeMethods';

export const COLLAPSIBLE_MAP_KEYS = [
	'companyName',
	'contactLocation',
	'role',
	'jobTitle',
	'yearsAtCompany',
	'licenseTypes',
	'carrierAppointments',
	'brokerOfficeLocations',
	'brokerSize',
] as const;

export interface BrokerContactsStore extends ContactSelectorMethods {
	isAddToListDialogOpen: boolean;
	setIsAddToListDialogOpen: (isOpen: boolean) => void;
	filters: BrokerContactFilters;
	setFilters: (filters: BrokerContactFilters) => void;
	setFilter: {
		brokerSize: IncludeExcludeMethod;
		carrierAppointments: IncludeExcludeMethod;
		companyName: IncludeExcludeMethod;
		contactLocation: LocationMethod;
		brokerOfficeLocations: LocationMethod;
		jobTitles: IncludeExcludeMethod;
		licenseTypes: IncludeExcludeMethod;
		roles: IncludeExcludeMethod;
		yearsAtCompany: RangeMethod;
		nameSearch: StringMethod;
	};
	collapsible: {
		map: Record<(typeof COLLAPSIBLE_MAP_KEYS)[number], boolean>;
		toggle: Record<(typeof COLLAPSIBLE_MAP_KEYS)[number], () => void>;
		clear: () => void;
	};
	setState: (state: Partial<BrokerContactsStore>) => void;
}

export interface BrokerContactContext {
	contacts: ContactDTO[];
	filterOptions: {
		data: Partial<BrokerContactsFiltersBreakdownResponse>;
		loading?: boolean;
	};
	numberOfSelectedContacts: number;
	totalNumberOfContacts: null | number;
	loading: boolean;
	fetching: boolean;
	fetchNextPage: () => void;
	hasNextPage: boolean;
	sidebarDimensions: {
		height: number;
		width: number;
	};
}
