/**
 *
 * Asynchronously loads the component for ProviderPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ProviderPageController = lazyLoad(
  () => import('./index'),
  module => module.ProviderPageController,
);
