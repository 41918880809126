import cn from 'classnames';
import { FC, ReactNode } from 'react';
import { FaRegCheckCircle, FaExclamationTriangle } from "react-icons/fa";
import { FaRegCircleXmark, FaXmark } from "react-icons/fa6";
import { Snackbar, SnackbarContent } from '@material-ui/core';
import { useNotificationContext, NotificationType, GlobalNotification } from 'new-beginning/context/NotificationContext';

const iconBaseClass = cn('bf-icon', 'pe-2');
const notificationMeta: Record<NotificationType, [string, string, ReactNode]> = {
  [NotificationType.INFO]: ['Success', 'success', <FaRegCheckCircle className={cn(iconBaseClass, 'ic-success')} size={28} />],
  [NotificationType.WARNING]: ['Warning', 'warn', <FaExclamationTriangle className={cn(iconBaseClass, 'ic-warn')} size={28} />],
  [NotificationType.ERROR]: ['Error', 'error', <FaRegCircleXmark className={cn(iconBaseClass, 'ic-error')} size={28} />],
};

export const SyncNotification: FC = () => {
  const { clearNotification, notificationSet } = useNotificationContext();
  const currentNotification: GlobalNotification = notificationSet?.[0] || null;
  const isNotificationOpen = !!currentNotification;

  const { message, messageType } = currentNotification || {};
  const [headerText, className, Icon] = notificationMeta?.[messageType] || [];

  return (
    <Snackbar
      open={isNotificationOpen}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      autoHideDuration={null}
      onClose={clearNotification}
    >
      <SnackbarContent
        className={cn('bg-white')}
        message={
          <div style={{ maxWidth: '325px' }} className={cn('d-flex', 'align-items-center')}>
            <div className={cn('d-flex', 'flex-column', 'align-items-start', 'mx-2')}>
              <div className={cn('d-flex', 'align-items-center', 'justify-content-between', 'w-100', 'mb-1')}>
                <div className={cn('d-flex', 'align-items-center', 'bf-txt', `txt-${className}`)}>
                  {Icon}
                  <span className={cn('bf-txt', 'txt-lg', 'txt-bolder', `txt-${className}`)}>{headerText}</span>
                </div>
                <button className={cn('bf-btn', 'bf-btn-simple', 'ms-3')} onClick={clearNotification}>
                  <FaXmark className={cn('bf-icon', 'ic-action', 'ic-cancel')} size={22} />
                </button>
              </div>
              <div className={cn('bf-txt', 'txt-neutral')}>{message}</div>
            </div>
          </div>
        }
      />
    </Snackbar>
  );
};
