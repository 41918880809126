import { ContactDTO } from '../../queries';
import { ContactUtils } from '../ContactUtils/ContacUtils';

export const isGetSelectedButtonDisabled = ({
	checkedMap,
	checkMode,
	contacts,
}: {
	contacts: ContactDTO[];
	checkedMap: Record<string, boolean>;
	checkMode: 'select-all' | 'single-select';
}) => {
	if (checkMode === 'select-all') return false;
	return !Object.entries(checkedMap).some(([id, isChecked]) => {
		if (!isChecked) return false;
		const contact = contacts.find((c) => c.id === id);
		return ContactUtils.isContactPurchasable(contact) && !ContactUtils.isContactPurchased(contact);
	});
};
