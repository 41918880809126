import { useMemo, useState } from 'react';
import { AutoComplete, Box, Collapsible, InputLabel, Tag } from '@benefitflow/designsystem';

import { FiltersProps } from '../../Filters.types';
import { useFormattedOptions, useValues } from './hooks';
import { FormattingUtils } from '../../../../../../ui-components';

export interface IncludeExcludeFilterProps extends FiltersProps {
	options: { count: number; value: string }[];
	selectedOptions: { include?: string[]; exclude?: string[] };
	onDelete: (params: { value: string; type: 'include' | 'exclude' }) => void;
	onSelect: (params: { value: string; type: 'include' | 'exclude' }) => void;
}

export const IncludeExcludeFilter = ({
	title,
	description,
	isOpen,
	isLoading,
	onToggle,
	onDelete,
	onSelect,
	selectedOptions,
	options,
	icon,
	placeholder,
	testId,
}: IncludeExcludeFilterProps) => {
	const [search, setSearch] = useState('');
	const formattedOptions = useFormattedOptions({ options });
	const filteredOptions = useMemo(() => formattedOptions.filter(({ value }) => value.toLowerCase().includes(search.toLowerCase())), [search, formattedOptions]);
	const values = useValues({ formattedOptions, selectedOptions });
	const selectedValues = useMemo(() => [...selectedOptions.include, ...selectedOptions.exclude], [selectedOptions]);

	return (
		<Collapsible
			headerTestId="role-filter-header"
			isOpen={isOpen}
			toggle={onToggle}
			title={title}
			icon={icon}
			content={
				values.length && (
					<Box display="flex" gap="xxs" flexWrap="wrap">
						{values.map(({ value, type }) => (
							<Tag
								variant={type === 'include' ? 'primary' : 'danger'}
								label={FormattingUtils.formatUpperCasing(value)}
								onDelete={() => onDelete({ value, type })}
								key={value}
							/>
						))}
					</Box>
				)
			}
			openContent={
				<InputLabel label={description}>
					<AutoComplete
						removable
						onSelect={({ value }) => onSelect({ value, type: 'include' })}
						onDelete={({ value }) => onSelect({ value, type: 'exclude' })}
						inputTestId={testId}
						selectedValues={selectedValues}
						loading={isLoading && !formattedOptions.length}
						placeholder={placeholder}
						options={filteredOptions}
						onSearch={setSearch}
					/>
				</InputLabel>
			}
		/>
	);
};
