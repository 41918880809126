import { useContext, useMemo, useState } from 'react';
import { Box, Button, Dialog, Divider, Paper, Select, Typography } from '@benefitflow/designsystem';

import { ListActionUtils } from '../../../../../../../utilities';
import { ContactsListContext } from '../../../../ContactsList.context';
import { useIsDisabled, useMoveSelected } from './hooks';
import { APIContext } from 'new-beginning/services/api/Api.context';

export const MoveSelectedButton = () => {
	const { listsApi } = useContext(APIContext);
	const { contacts } = useContext(ContactsListContext);
	const [isMoveListDialogOpen, setIsMoveListDialogOpen] = useState(false);
	const [selectedListId, setSelectedListId] = useState<string>('');
	const { loading, moveSelected } = useMoveSelected({ selectedListId, closeDialog: () => setIsMoveListDialogOpen(false) });
	const zIndex = useMemo(() => ListActionUtils.getDialogZIndex(contacts?.length), [contacts]);
	const { data: lists } = listsApi.useGetAllListsForUser({
		onSuccess: (lists) => {
			if (lists && lists?.[0]) setSelectedListId(lists[0].id.toString());
		},
	});
	const isDisabled = useIsDisabled();
	return (
		<>
			<Button loading={loading} variant="outlined" disabled={isDisabled} icon="switch" onClick={() => setIsMoveListDialogOpen(true)}>
				Move List
			</Button>
			<Dialog open={isMoveListDialogOpen} zIndex={zIndex}>
				<Box>
					<Paper p="none">
						<Box display="flex" flexDirection="column">
							<Box p="lg" gap="lg" display="flex" flexDirection="column">
								<Typography.Text size="lg" color="neutral-700">
									Chose list to move to
								</Typography.Text>
								<Select value={selectedListId} onChange={(e) => setSelectedListId(e.target.value)}>
									{lists?.map(({ id, name }) => (
										<Select.Option value={id} key={id}>
											{name}
										</Select.Option>
									))}
								</Select>
							</Box>
							<Divider />
							<Box display="flex" p="lg" alignItems="center" justifyContent="end" gap="md">
								<Box cursor="pointer" onClick={() => setIsMoveListDialogOpen(false)}>
									<Typography.Text color="primary-500">Cancel</Typography.Text>
								</Box>
								<Button onClick={moveSelected} fetching={loading}>
									Move
								</Button>
							</Box>
						</Box>
					</Paper>
				</Box>
			</Dialog>
		</>
	);
};
