import cn from 'classnames';
import Skeleton from '@material-ui/lab/Skeleton';
import { IoShuffle } from 'react-icons/io5';
import { IoMdInformationCircleOutline as IoInfo } from 'react-icons/io';

export const LoadingRow = () => {
	return (
		<tr className={cn('expandable-row')}>
			<td className={cn('right-border', 'text-center')}>
				<div className={cn('d-flex', 'align-items-center', 'justify-content-between', 'flex-wrap')}>
					<input className={cn('form-check-input', 'bf-checkbox')} type='checkbox' disabled />
					<IoShuffle className={cn('bf-icon', 'ic-action', 'ic-disabled')} size={28} />
					<IoInfo className={cn('bf-icon', 'ic-action', 'ic-disabled')} size={28} />
				</div>
			</td>
			<td>
				<div className={cn('d-flex', 'flex-wrap', 'align-items-center')}>
					<div className={cn('me-2')}>
						<Skeleton animation='wave' variant='circle' className={cn('bf-skeleton-load')} width={40} height={40} />
					</div>
					<div>
						<div className={cn('d-flex', 'align-items-center')}>
							<div className={cn('me-1')}>
								<Skeleton animation='wave' variant='text' className={cn('bf-skeleton-load')} width={180} height={20} />
							</div>
						</div>
						<div className={cn('me-1')}>
							<Skeleton animation='wave' variant='text' className={cn('bf-skeleton-load')} width={210} height={26} />
						</div>
					</div>
				</div>
			</td>
			<td>
				<div className={cn('d-flex', 'justify-content-end')}>
					<Skeleton animation='wave' variant='text' className={cn('bf-skeleton-load')} width={160} height={30} />
				</div>
			</td>
			<td>
				<div className={cn('d-flex', 'align-items-center', 'flex-wrap')}>
					<div className={cn('me-3')}>
						<Skeleton animation='wave' variant='rect' className={cn('rounded', 'bf-skeleton-load')} width={40} height={40} />
					</div>
					<div>
						<div className={cn('me-1')}>
							<Skeleton animation='wave' variant='text' className={cn('bf-skeleton-load')} width={200} height={28} />
						</div>
						<div className={cn('me-1')}>
							<Skeleton animation='wave' variant='text' className={cn('bf-skeleton-load')} width={180} height={22} />
						</div>
					</div>
				</div>
			</td>
		</tr>
	);
};

export const LoadingRowSet = ({ rowCount = 25 }) => {
	const rowSet = Array(rowCount);
	return [...rowSet].map((_, idx) => <LoadingRow key={idx} />);
};
