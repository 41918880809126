export const getNumberOfSelectedContacts = ({
	totalNumberOfContacts,
	checkMode,
	checkedMap,
}: {
	totalNumberOfContacts: number;
	checkMode: 'single-select' | 'select-all';
	checkedMap: Record<string, boolean>;
}) => {
	let numberOfContacts: null | number = null;
	if (checkMode === 'single-select') numberOfContacts = Object.values(checkedMap).filter(Boolean).length;
	if (checkMode === 'select-all') numberOfContacts = totalNumberOfContacts - Object.values(checkedMap).filter((checked) => !checked).length;

	return numberOfContacts;
};
