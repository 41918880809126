import { Box, Button, Icon, Skeleton, Suspense, Typography } from '@benefitflow/designsystem';
import { useHeader, useNavigation, useOnClickBack } from './hooks';
import { useGetValueByScreenSizes } from 'new-beginning/hooks/useGetValueByScreenSizes';
import { useIsV2 } from '../hooks/useIsV2';
import useOnClickExportPDF from './hooks/useOnClickExportPDF';
import { EmployerExportPage, EmployerExportPageProps } from 'pages/EmployerPage/organisms/ExportPage';
import { useRef, useState } from 'react';

export const Header = (props: EmployerExportPageProps) => {
	const isV2 = useIsV2();
	const getValuesByScreenSize = useGetValueByScreenSizes();
	const { loading, name } = useHeader();
	const onClickBack = useOnClickBack();
	const contentRef = useRef<HTMLDivElement>(null);
	const { handlePrint, isLoading } = useOnClickExportPDF(props, contentRef);
	const { currentPage, onNextClick, onPreviousClick, totalPages } = useNavigation();

    const [isContentVisible, setContentVisible] = useState(false);

    const handleExportClick = async () => {
        setContentVisible(true);
		await new Promise(resolve => setTimeout(resolve, 3000));
        await handlePrint();
		await new Promise(resolve => setTimeout(resolve, 1000));
        setContentVisible(false);
    };

	return (
		<>
			<style>
				{`
					@media all {
						.page-break {
							display: none;
						}
					}

					@media print {
						html, body {
							height: initial !important;
							overflow: initial !important;
							-webkit-print-color-adjust: exact;
						}

						.page-break {
							margin-top: 3rem;
							display: block;
							page-break-before: always;
						}
					}

					@page {
						size: auto;
					}
				`}
			</style>				
			<div ref={contentRef} 
				style={{ 
					display: isContentVisible ? 'block' : 'none', 
					width: "900px", 
					margin: '0 auto', // Centered the div
				}}
			>
				<EmployerExportPage {...props} />
			</div>
			<Box
				style={{ borderBottom: '0.5px solid #CBCDD1' }}
				p="md"
				backgroundColor="white"
				display="flex"
				flexDirection={getValuesByScreenSize({ lg: 'row', md: 'row', sm: 'column' })}
				width="full"
				justifyContent="between"
				gap="md"
			>
				<Box display="flex" alignItems="center" gap="sm">
						<Button loading={loading} variant="outlined" onClick={onClickBack}>
							<Icon name="arrow-left" size="md" />
						</Button>
						<Suspense loading={loading} fallback={<Skeleton height="sm" width="xl6" />}>
							<Box display="flex" gap="sm" justifyContent="center" alignItems="center">
								<Typography.Text>Employer Search</Typography.Text>
								<Typography.Text>•</Typography.Text>
								<Typography.Text>{name}</Typography.Text>
							</Box>
						</Suspense>
				</Box>

				<Box display="flex" alignItems="center" gap="sm" justifyContent="center">
					<Button loading={loading} variant="outlined" onClick={handleExportClick}>
						<Box display="flex" alignItems="center" gap="sm">
							<Icon name="arrow-leave" size="sm" /> Export PDF
						</Box>
					</Button>
				</Box>

				{isV2 && (
					<Box display="flex" alignItems="center" gap="sm" justifyContent="center">
						<Suspense loading={loading} fallback={<Skeleton height="sm" width="xl3" />}>
							<Typography.Text whiteSpace="nowrap" color="neutral-500" size="sm">
								{currentPage} of {totalPages}
							</Typography.Text>
						</Suspense>
						<Button.Group>
							<Button loading={loading} variant="outlined" onClick={onPreviousClick}>
								<Icon name="chevron-left" size="lg" />
							</Button>
							<Button loading={loading} variant="outlined" onClick={onNextClick}>
								<Icon name="chevron-right" size="lg" />
							</Button>
						</Button.Group>
					</Box>
				)}
			</Box>
		</>
	);
};
