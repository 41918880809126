import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { listsDetailPageActions } from 'app/containers/ListsPage/containers/ListsDetailPage/slice';
import { selectListsDetailPage } from 'app/containers/ListsPage/containers/ListsDetailPage/selectors';

export const useOnDelete = () => {
	const dispatch = useDispatch();
	const { contactFilters } = useSelector(selectListsDetailPage);

	const onDelete = useCallback(
		() => () => {
			dispatch(
				listsDetailPageActions.updateContactFilters({
					...contactFilters,
					carrierAppointments: {
						include: [],
						exclude: [],
					},
				}),
			);
		},
		[dispatch, contactFilters],
	);

	return onDelete;
};
