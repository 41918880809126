import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { filterBarActions } from 'app/containers/FilterBar/slice';
import { listsDetailPageActions } from 'app/containers/ListsPage/containers/ListsDetailPage/slice';

export const useOnSearch = () => {
	const dispatch = useDispatch();

	const onSearch = useCallback(
		(search: string) => {
			if (!search || search.length > 1) dispatch(listsDetailPageActions.searchContactLocation(search));
		},
		[dispatch, filterBarActions]
	);

	return onSearch;
};
