import { useListDetailsFilterStore } from '../../../ListDetails.store';

export const useToggle = () => {
	const {
		collapsible: {
			toggle: { licenseTypes },
		},
	} = useListDetailsFilterStore();

	return licenseTypes;
};
