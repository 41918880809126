export type RangeMethod = {
	start: (start: string) => void;
	end: (end: string) => void;
	setRange: (start: string, end: string) => void;
};
type RangeFilter = { start: string; end: string };
type RangeFilterLegacy = [string, string];

export const createRangeMethods = <FilterType extends { filters: Record<string, any> }, K extends string & keyof FilterType['filters']>(
	filterName: K,
	set: (fn: (state: FilterType) => Partial<FilterType>) => void,
): RangeMethod => ({
	start: (start: string) =>
		set(
			(state) =>
				({
					filters: {
						...state.filters,
						[filterName]: { start, end: state.filters[filterName].end } as RangeFilter,
					},
				}) as Partial<FilterType>,
		),
	end: (end: string) =>
		set(
			(state) =>
				({
					filters: {
						...state.filters,
						[filterName]: { start: state.filters[filterName].start, end: end } as RangeFilter,
					},
				}) as Partial<FilterType>,
		),
	setRange: (start: string, end: string) =>
		set(
			(state) =>
				({
					filters: {
						...state.filters,
						[filterName]: { start, end },
					},
				}) as Partial<FilterType>,
		),
});

export const createRangeMethodsLegacy = <FilterType extends { filters: Record<string, any> }, K extends string & keyof FilterType['filters']>(
	filterName: K,
	set: (fn: (state: FilterType) => Partial<FilterType>) => void,
): RangeMethod => ({
	start: (start: string) =>
		set(
			(state) =>
				({
					filters: {
						...state.filters,
						[filterName]: [start, state.filters[filterName][1]] as RangeFilterLegacy,
					},
				}) as Partial<FilterType>,
		),
	end: (end: string) =>
		set(
			(state) =>
				({
					filters: {
						...state.filters,
						[filterName]: [state.filters[filterName][0], end],
					},
				}) as Partial<FilterType>,
		),
	setRange: (start: string, end: string) =>
		set(
			(state) =>
				({
					filters: {
						...state.filters,
						[filterName]: [start, end],
					},
				}) as Partial<FilterType>,
		),
});
