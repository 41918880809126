import { useMemo } from 'react';

import { useEmployeeSentiment } from 'new-beginning/components/pages/EmployerProfile/Tabs/components/EmployeeSentimentTab/hooks';

export const useBenefitsRatingVariationColor = (type: ReturnType<typeof useEmployeeSentiment>['type']) => {
	const color = useMemo(() => {
		if (type === 'increase') return 'success-500';
		if (type === 'decrease') return 'danger-500';

		return 'primary-500';
	}, [type]);

	return color;
};
