
export default class ListUtils {
    
    public static toMap(conflicts: any, arg1: string) {
      return conflicts.reduce((a,b) => {
        a[b[arg1]] = b;
        return a;
      }, {});
    }

    public static sampleList(items, sampleCount) {
        if (items.length < sampleCount) {
            throw `Items count (${items.length}) is less than the sampe count (${sampleCount})`;
        }
        const remainingOptions = [...items];
        const results = [];
        while (results.length < sampleCount) {
            const rand = Math.random();
            const ix = Math.floor(remainingOptions.length - 1 * rand);
            // console.log(`ix: ${ix}, option: ${remainingOptions[ix]}, len: ${remainingOptions.length}`);
            results.push(remainingOptions[ix]);
            ListUtils.removeItem(remainingOptions, ix);
        }
        return results;
    }

    public static removeItem(items, ix) {
        if (ix > -1) {
            items.splice(ix, 1);
        }
    }

    public static chunkify(array, size) {
        const result = []
        for (let i = 0; i < array.length; i += size) {
            const chunk = array.slice(i, i + size)
            result.push(chunk)
        }
        return result
    }

    public static distinct(array) {
        if (!array) {
            return array;
        }
        const map = array
            .reduce((a,b) => {
                a[b] = 1;
                return a;
            }, {});
        return Object.keys(map);
    }

    public static distinctBy(array, getKey: (b) => string) {
        if (!array) {
            return array;
        }
        const map = array
            .reduce((a,b) => {
                if (!a[getKey(b)]) {
                    a[getKey(b)] = {
                        count: 0,
                        item: b, 
                        allItems: []
                    };
                }   
                a[getKey(b)].count++;
                a[getKey(b)].allItems.push(b);
                return a;
            }, {});
        const dups = Object.keys(map)
            .filter(x => map[x].count > 1)
            .map(x => map[x].allItems);
        return Object.keys(map).map(x => map[x].item);
    }
    
}