import { useMemo } from 'react';

export const useValues = ({
	selectedOptions,
	formattedOptions,
}: {
	formattedOptions: {
		value: string;
		label: string;
	}[];
	selectedOptions: {
		include?: string[];
		exclude?: string[];
	};
}) => {
	const values = useMemo(() => {
		return [
			...selectedOptions.include.map((value) => ({
				value,
				type: 'include' as const,
				label: formattedOptions.find((option) => option.value === value)?.label,
			})),
			...selectedOptions.exclude.map((value) => ({
				value,
				type: 'exclude' as const,
				label: formattedOptions.find((option) => option.value === value)?.label,
			})),
		];
	}, [selectedOptions, formattedOptions]);

	return values;
};
