import { useListDetailsFilterStore } from '../../../ListDetails.store';

export const useToggle = () => {
	const {
		collapsible: {
			toggle: { contactLocation },
		},
	} = useListDetailsFilterStore();

	return contactLocation;
};
