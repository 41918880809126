import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectListsDetailPage } from 'app/containers/ListsPage/containers/ListsDetailPage/selectors';
import { BROKER_CONTACT_TAB } from 'pages/ListsPage/pages/ListsDetailPage/page';

export const useShowVerifyProFilters = () => {
	const { contactTypeTab } = useSelector(selectListsDetailPage);

	const showVerifyProFilters = useMemo(() => {
		if (contactTypeTab === BROKER_CONTACT_TAB) return true;
		return false;
	}, [contactTypeTab]);

	return showVerifyProFilters;
};
