import { useMemo } from 'react';

import { BenefitCategoryProps } from '../components/Benefit/Benefit.types';
import { useEmployeeSentiment } from 'new-beginning/components/pages/EmployerProfile/Tabs/components/EmployeeSentimentTab/hooks';

export const useBenefitsColumns = ({
	benefits,
	numberOfColumns,
}: {
	benefits: ReturnType<typeof useEmployeeSentiment>['benefits'];
	numberOfColumns: number;
}): BenefitCategoryProps[][] => {
	const columns = useMemo(() => {
		if (!benefits?.length) return [];
		const result: BenefitCategoryProps[][] = Array.from({ length: numberOfColumns }, () => []);
		benefits.forEach((benefit, index) => {
			const columnIndex = index % numberOfColumns;
			result[columnIndex].push(benefit);
		});

		return result;
	}, [benefits, numberOfColumns]);

	return columns;
};
