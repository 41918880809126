import { Avatar, Box, Checkbox, Description, Paper } from '@benefitflow/designsystem';

import { useHeight } from './hooks';
import { ContactDTO } from 'new-beginning/queries';
import { Card } from 'new-beginning/components/shared/Card/Card';
import { useScreenSize } from 'new-beginning/hooks/useScreenSize';
import { VerifiedProducerBadge, CarrierAppointments } from './components';
import { useGetValueByScreenSizes } from 'new-beginning/hooks/useGetValueByScreenSizes';
import { Buttons, Descriptions } from 'new-beginning/components/shared/ContactCards/components';

export const BrokerContactCardV2 = ({
	appointments,
	brokerLinkedinUrl,
	brokerName,
	email,
	hasEmail,
	hasPhone,
	imageSrc,
	isVerified,
	licenses,
	linkedinUrl,
	location,
	name,
	phone,
	timeWorkingAtCompany,
	timeWorkingInRole,
	title,
	loading,
	checked,
	toggle,
	variant = 'search',
	onBrokerClick,
	onGetContact,
	purchasable,
	purchased,
	gettingContact,
}: Partial<ContactDTO> & {
	loading?: boolean;
	checked?: boolean;
	toggle?: () => void;
	variant: 'search' | 'profile';
	onBrokerClick?: () => void;
	onGetContact?: () => void;
	purchasable?: boolean;
	purchased?: boolean;
	gettingContact?: boolean;
}) => {
	const size = useScreenSize();
	const getValueByScreenSizes = useGetValueByScreenSizes();
	const height = useHeight();

	return (
		<Paper style={{ height }}>
			<Box
				testId="card-container"
				gap="xs"
				display="grid"
				width="full"
				style={{
					gridTemplateColumns: getValueByScreenSizes({
						sm: 'repeat(1,1fr)',
						md: 'repeat(2,1fr)',
						lg: 'repeat(13,1fr)',
					}),
				}}
				overflow="hidden"
			>
				<Box
					gridSpan={getValueByScreenSizes({ md: 2, lg: 5, sm: 1 })}
					display="flex"
					justifyContent="start"
					alignItems="center"
					flexDirection={size === 'sm' ? 'column' : 'row'}
					gap="md"
					overflow="hidden"
				>
					<Checkbox testId="contact-checkbox" loading={loading} checked={checked} onChange={toggle} />
					<Avatar loading={loading} src={imageSrc} fallbackIcon="user" variant="circle" />
					<Box overflow="hidden" display="flex" width="full" flexDirection="column" justifyContent="start" alignItems="start" gap="xxs">
						<Card.Title loading={loading} linkedinUrl={linkedinUrl}>
							{name}
						</Card.Title>
						<Card.Label weight="regular" size="sm" loading={loading}>
							{title}
						</Card.Label>
						<Card.Label weight="regular" size="sm" color="neutral-500" loading={loading}>
							{location}
						</Card.Label>
						<VerifiedProducerBadge loading={loading} isVerified={isVerified} licenses={licenses} />
					</Box>
				</Box>

				<Box gridSpan={getValueByScreenSizes({ md: 1, lg: 3, sm: 1 })} display="flex" overflow="hidden" flexDirection="column" justifyContent="center" gap="xs">
					{variant === 'search' ? (
						<Card.Title onClick={onBrokerClick} linkedinUrl={brokerLinkedinUrl}>
							{brokerName}
						</Card.Title>
					) : (
						<Card.Label color="neutral-400" weight="medium" loading={loading}>
							Experience
						</Card.Label>
					)}
					<CarrierAppointments loading={loading} appointments={appointments} />
					{timeWorkingInRole?.value && <Description icon="briefcase" loading={loading} label={timeWorkingInRole?.label} value={timeWorkingInRole?.value} />}
					{timeWorkingAtCompany?.value && (
						<Description icon="business" loading={loading} label={timeWorkingAtCompany?.label} value={timeWorkingAtCompany?.value} />
					)}
				</Box>
				<Box
					gridSpan={getValueByScreenSizes({ md: 1, lg: 3, sm: 1 })}
					overflow="hidden"
					display="flex"
					width="full"
					flexDirection="column"
					justifyContent="center"
					alignItems="start"
					gap="xs"
				>
					{(hasEmail || hasPhone) && (
						<Card.Label color="neutral-400" weight="medium" loading={loading}>
							Contact Info
						</Card.Label>
					)}
					<Descriptions.Email loading={loading} hasEmail={hasEmail} email={email} />
					<Descriptions.MobilePhones loading={loading} hasPhone={hasPhone} phone={phone} />
				</Box>
				<Box gridSpan={getValueByScreenSizes({ md: 2, lg: 2, sm: 1 })} display="flex" justifyContent={size === 'lg' ? 'end' : 'center'} alignItems="center">
					<Buttons.GetContacts
						loading={loading}
						gettingContact={gettingContact}
						purchaseContact={onGetContact}
						isPurchasable={purchasable}
						isPurchased={purchased}
					/>
				</Box>
			</Box>
		</Paper>
	);
};
