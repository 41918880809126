import { create } from 'zustand';
import { BrokerProfileStore } from './BrokerProfile.types';

export const useBrokerProfileStore = create<BrokerProfileStore>((set) => ({
	clients: {
		rowExpandMap: {
			map: {},
			set: (rowId: string, value: boolean) => {
				set((state) => ({
					clients: {
						rowExpandMap: {
							...state.clients.rowExpandMap,
							map: {
								...state.clients.rowExpandMap.map,
								[rowId]: value,
							},
						},
					},
				}));
			},
			toggle: (rowId: string) => {
				set((state) => ({
					clients: {
						rowExpandMap: {
							...state.clients.rowExpandMap,
							map: {
								...state.clients.rowExpandMap.map,
								[rowId]: !state.clients.rowExpandMap.map[rowId],
							},
						},
					},
				}));
			},
			clear: () => {
				set((state) => ({
					clients: {
						rowExpandMap: {
							...state.clients.rowExpandMap,
							map: {},
						},
					},
				}));
			},
		},
	},
}));
