import cn from 'classnames';
import { ListContactType } from 'common.model/src/db/model-decorators/type-extensions';
import { openLinkInTab } from 'new-beginning/utilities';
import { IoShuffle, IoWarning, IoReturnDownForward as ReturnRight } from 'react-icons/io5';
import { FaChevronRight as ChevronRight, FaSalesforce } from 'react-icons/fa';
import { ContactSyncField } from 'new-beginning/components/pages/ListSync/SyncTable/TableRows/SyncFieldStatus';
import { BasePill, SuccessPill, ContactStatusPill, UpdatePill } from 'new-beginning/components/shared/Pills';
import { personPlaceHolder } from 'designTokens/assets/images';
import unknownBusinessLogo from 'assets/unknown-business-logo.png';
import { Tooltip } from '@material-ui/core';

const icSize = 28;
const SKIPPED_TOOLTIP_MESSAGE = 'There is not enough information to push this contact to your CRM.';
const STANDARD_USER_UPDATE_TOOLTIP_MESSAGE =
	'Your permissions are restricted to syncing net-new contacts only. Contact your BenefitFlow account administrator to upgrade.';

interface ContactRowProps {
	listContact: ListContactType;
	salesforceContactOwner: string | null;
	directSyncContact: (listItemId: string) => void;
	selectRow: (rowId: string, selected: boolean) => void;
	expandRow: (rowId: string) => void;
	isSelected: boolean;
	isExpanded: boolean;
	allSelected: boolean;
	loading: boolean;
	syncInProgress: boolean;
	isAdmin: boolean;
}

export const ContactRow = ({
	listContact,
	salesforceContactOwner,
	directSyncContact,
	selectRow,
	expandRow,
	isSelected,
	isExpanded,
	allSelected,
	syncInProgress,
	loading,
	isAdmin,
}: ContactRowProps) => {
	const { contact, account } = listContact || {};
	const contactId = contact?.id;
	const isSynced = listContact?.salesforce_contact_uid;
	const contactType = listContact?.item_type;
	const isEmployerContact = contactType === 'employer';

	const accountDiff = listContact?.accountDiffItem;
	const accountSync = listContact?.accountSyncItem;
	const contactDiff = listContact?.contactDiffItem;
	const contactSync = listContact?.contactSyncItem;

	const isUpdate = contactDiff?.action_type === 'UPDATE';
	const isInsert = contactDiff?.action_type === 'INSERT';
	const contactUnchanged = contactDiff?.action_type === 'UNCHANGED';
	const contactJustSynced = contactSync?.status === 'SYNCED';
	const contactSyncFailed = contactSync?.status === 'FAILED';
	const accountSyncFailed = accountSync?.status === 'FAILED';

	const contactSyncError = contactSync?.error_message?.split(' | ')?.[0] || null;
	const accountSyncError = accountSync?.error_message?.split(' | ')?.[0] || null;

	const hasOfficeLocation = !!account?.office?.location;
	const hasAccountInsert = accountDiff?.deprecated === false && accountDiff?.action_type === 'INSERT';
	const missingAccount = !!contactDiff && !contactDiff?.salesforce_account_uid && !hasAccountInsert;

	const contactUnsyncable = !contactDiff || contactDiff?.deprecated || contactUnchanged || contactJustSynced || (missingAccount && !isSynced);
	const hasAccountReassign = !!listContact?.salesforce_staged_account_url && isUpdate && !contactJustSynced && !accountSyncFailed;
	const disabledFromSync = (syncInProgress && contactUnsyncable) || (!isAdmin && !isInsert) || loading;
	const showSalesforceLink = isSynced && contactUnsyncable;

	// Note: Matched inserts will have salesforce_account_uid set on the Contact Diff, but we need to reference the salesforce_account_uid
	//       on the listContact Object for already-persisted Contacts because during account-reassignment the ContactDiff contains the new AccountId.
	const insertContactOfficeLink = (!contactJustSynced && listContact?.salesforce_staged_account_url) ? listContact?.salesforce_staged_account_url : null;
	const salesforceOfficeAccountLink = insertContactOfficeLink || listContact?.salesforce_account_url || null;

	const expandCurrentRow = () => expandRow(contactId);

	const isSkipped = !listContact?.contactDiffItem?.action_type ? SKIPPED_TOOLTIP_MESSAGE : null;
	const lacksPermissions = !isAdmin && isUpdate ? STANDARD_USER_UPDATE_TOOLTIP_MESSAGE : null;
	const tooltipMessage = isSkipped || lacksPermissions || '';

	return (
		<tr onClick={expandCurrentRow} className={cn('expandable-row', { expanded: isExpanded })}>
			<td
				className={cn('right-border', 'text-center')}
				onClick={(e) => {
					e.stopPropagation();
				}}
			>
				<div className={cn('d-flex', 'align-items-center', 'justify-content-between', 'flex-wrap')}>
					<input
						className={cn('form-check-input', 'cursor-ptr', 'bf-checkbox', { locked: disabledFromSync })}
						type='checkbox'
						disabled={allSelected || disabledFromSync}
						checked={!disabledFromSync && (allSelected || isSelected)}
						onClick={(e) => {
							e.stopPropagation();
						}}
						onChange={() => selectRow(contactId, !isSelected)}
					/>
					{showSalesforceLink ? (
						<FaSalesforce
							className={cn('bf-icon', 'ic-action', 'ic-salesforce', { 'ic-disabled': !isSynced })}
							size={icSize}
							onClick={openLinkInTab(listContact?.salesforce_contact_url)}
						/>
					)
					: (
						<Tooltip title={tooltipMessage} placement='right-end'>
							<div>
								<IoShuffle
									onClick={(e) => {
										e?.stopPropagation();
										directSyncContact(contactId);
									}}
									className={cn('bf-icon', 'ic-action', {
										'ic-disabled': loading || !syncInProgress || disabledFromSync,
									})}
									size={icSize}
								/>
							</div>
						</Tooltip>
					)}
					{contactSyncFailed ? (
						<div className={cn('bf-icon-error-container', 'pulse-warn')} data-tooltip-content={contactSyncError}>
							<IoWarning size={icSize} className={cn('bf-icon', 'ic-action', 'ic-warn')} />
						</div>
					) : (
						<ChevronRight
							onClick={expandCurrentRow}
							className={cn('bf-icon', 'ic-action', 'ic-parent-hover', { 'ic-focused': isExpanded })}
							size={16}
						/>
					)}
				</div>
			</td>
			<td>
				<div className={cn('d-flex', 'align-items-center', 'position-relative')}>
					<img
						src={contact?.avatarImgUrl || personPlaceHolder}
						className={cn('img-sm', 'img-hide-sm', 'img-rounded', 'me-2')}
						onError={({ currentTarget }) => {
							currentTarget.onerror = null;
							currentTarget.src = personPlaceHolder;
						}}
					/>
					<div className={cn('d-flex', 'flex-column', 'align-items-start', 'flex-wrap')}>
						<div className={cn('d-flex', 'flex-wrap', 'align-items-center')}>
							<p className={cn('bf-txt', 'txt-lg', 'txt-bolder', 'txt-dark', 'me-1')}>{contact.name || '[Missing Name]'}</p>
							{!showSalesforceLink && isSynced && (
								<FaSalesforce
									className={cn('bf-icon', 'ic-action', 'ic-salesforce', 'me-1')}
									size={22}
									onClick={openLinkInTab(listContact?.salesforce_contact_url)}
								/>
							)}
							<ContactStatusPill isVisible={syncInProgress} contact={listContact} className={'me-1'} />
							<BasePill isVisible={syncInProgress && missingAccount} label='No Account' />
						</div>
						<ContactSyncField
							label='Job Title'
							field='jobTitle'
							listContact={listContact}
							updateFieldSet={['experience_title']}
						/>
					</div>
				</div>
			</td>
			<td className={cn('bf-txt', 'txt-nowrap', 'text-end', 'right-border')}>{salesforceContactOwner || contact?.contactOwner}</td>
			<td>
				<div className={cn('d-flex', 'align-items-center')}>
					<div>
						<img
							className={cn('img-logo', 'img-hide-sm', 'img-shadow', 'me-3')}
							src={account?.company?.logoUrl}
							onError={(event) => {
								const currentTarget = event?.currentTarget;
								// Note this isn't working for Google S2 Fallbacks
								currentTarget.onerror = null;
								currentTarget.src = unknownBusinessLogo;
							}}
						/>
					</div>
					<div className={cn('d-flex', 'flex-fill', { 'flex-column': !isEmployerContact })}>
						<div>
							<span className={cn('bf-txt', 'txt-proper', 'txt-lg', 'txt-bolder', 'txt-dark', 'align-middle')}>
								{account?.company?.companyName}
							</span>
						</div>
						<div className={cn('d-flex', 'align-items-center', 'flex-wrap')}>
							<div>
								{isEmployerContact ? (
									<span className={cn('bf-txt', 'txt-sm', 'txt-lighter', 'ms-1', 'align-middle')}>
										<FaSalesforce
											onClick={openLinkInTab(salesforceOfficeAccountLink)}
											className={cn('bf-icon', 'ic-action', 'ic-salesforce', 'me-1', {
												'ic-disabled': loading,
												hidden: !salesforceOfficeAccountLink,
											})}
											size={22}
										/>
										<SuccessPill isVisible={syncInProgress && hasAccountInsert} label='New' className='me-1' />
									</span>
								) : (
									<span className={cn('bf-txt', 'txt-sm', 'txt-lighter', 'me-1', 'align-middle')}>
										{hasOfficeLocation && (
											<>
												<ReturnRight className={cn('bf-icon', 'me-1')} size={24} />
												<span className={cn('me-1')}>{`${account?.office?.location} Office`}</span>
											</>
										)}
										{(hasOfficeLocation) && (
											<>
												<FaSalesforce
													onClick={openLinkInTab(salesforceOfficeAccountLink)}
													className={cn('bf-icon', 'ic-action', 'ic-salesforce', 'me-1', {
														'ic-disabled': loading,
														hidden: !salesforceOfficeAccountLink,
													})}
													size={22}
												/>
												<SuccessPill isVisible={syncInProgress && hasAccountInsert} label='New' className='me-1' />
											</>
										)}
										{accountSyncFailed && (
											<Tooltip title={accountSyncError ? (
												<div className={cn('bf-txt', 'txt-xl', 'txt-white')}>
													{accountSyncError}
												</div>
											) : ''} placement='top-start'>
												<div className={cn('d-inline-block')} >
													<IoWarning size={22} className={cn('bf-icon', 'ic-action', 'ic-warn', 'me-1')} />
												</div>
											</Tooltip>
										)}
										<BasePill
											isVisible={syncInProgress && accountSyncFailed}
											label={`Account ${accountSync?.action_type === 'INSERT' ? 'Insert' : 'Update'} Failed`}
											className={cn('pill-danger', 'me-1')}
										/>
										<UpdatePill
											isVisible={syncInProgress && hasAccountReassign}
											label='Account Change'
											className={cn('me-1')}
										/>
										<BasePill isVisible={!hasOfficeLocation && !isEmployerContact} className='pill-warn' label='No Office Location' />
									</span>
								)}
							</div>
						</div>
					</div>
				</div>
			</td>
		</tr>
	);
};
