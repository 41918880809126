import React from 'react';
import styled from 'styled-components';
import { Container } from 'react-bootstrap';
import { UsersTab, UsersTabsProps } from '../organisms/UsersTab';
import { ClientsTab, ClientsTabProps } from '../organisms/ClientsTab';
import Title from 'atoms/Title';
import { Tabs, Tab, TabPanel } from 'molecules/Tabs';


export interface ClientsPageProps {
    selectedTab: number;
    setSelectedTab: (tab: number) => void;
    usersTab: UsersTabsProps;
    clientsTab: ClientsTabProps;
}

const ContentDiv = styled.div`
  padding: 25px 0;
`;

export const ClientsPage = (props: ClientsPageProps) => {

  return (
    <>
      <ContentDiv>
        <Container>
            <Title text={'Clients'} count={
                props.selectedTab == 0 ?
                (props?.clientsTab?.table?.rows ? `(${props?.clientsTab?.table?.rows?.length})` : '')  :
                (props?.usersTab?.table?.rows ? `(${props?.usersTab?.table?.rows?.length})` : '') 
            } />
            <Tabs value={props.selectedTab} onChange={(e, v) => props.setSelectedTab(v)}>
                <Tab label="Clients" />
                <Tab label="Users" />
            </Tabs>
            <TabPanel value={props.selectedTab} index={0}>
                <ClientsTab
                    {...props.clientsTab}
                />
            </TabPanel>
            <TabPanel value={props.selectedTab} index={1}>
                <UsersTab 
                    {...props.usersTab}
                />                
            </TabPanel>
        </Container>
      </ContentDiv>
    </>
  );

};
