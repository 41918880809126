import React, { useMemo } from 'react';
import { Box, Description, Skeleton, Tooltip, Typography } from '@benefitflow/designsystem';
import { ContactDTO } from 'new-beginning/queries';

export const CarrierAppointments = ({ appointments, loading }: Pick<ContactDTO, 'appointments'> & { loading?: boolean }) => {
	const content = useMemo(
		() => (
			<Box overflow="y-auto" style={{ maxHeight: '20rem' }} p="md" display="flex" flexDirection="column" gap="sm">
				<Typography.Text whiteSpace="nowrap" size="md" weight="medium">
					Carrier Appointments
				</Typography.Text>

				{appointments?.map(({ carrier, since }, index) => (
					<Box key={index} display="flex" gap="sm" justifyContent="between">
						<Box style={{ maxWidth: '18rem' }}>
							<Typography.Text weight="regular" size="sm" ellipsis whiteSpace="nowrap">
								{carrier}
							</Typography.Text>
						</Box>
						<Typography.Text weight="regular" size="sm" whiteSpace="nowrap" color="neutral-400">
							{since}
						</Typography.Text>
					</Box>
				))}
			</Box>
		),
		[appointments],
	);
	if (loading) return <Skeleton height="sm" width="xl5" />;
	if (!appointments || appointments.length === 0) return null;

	return (
		<Description
			icon="shield"
			label={
				<span>
					{appointments.length > 0 ? (
						<Tooltip preventAutoPositioning behavior="click" strategy="fixed" placement="bottom-start" backgroundColor="white" content={content}>
							{(ref) => (
								<Box containerRef={ref} display="flex" gap="xxs" style={{ cursor: 'pointer' }}>
									<Typography.Text decoration="underline" decorationStyle="dotted" decorationColor="secondary-500" size="sm" whiteSpace="nowrap">
										{appointments?.length}
									</Typography.Text>
									<Typography.Text
										size="sm"
										whiteSpace="nowrap"
										decoration="underline"
										decorationStyle="dotted"
										decorationColor="secondary-500"
										weight="regular"
										color="neutral-500"
									>
										Carrier Appointments
									</Typography.Text>
								</Box>
							)}
						</Tooltip>
					) : null}
				</span>
			}
		/>
	);
};
