import { useEffect, useRef } from 'react';

export const useNameInput = ({ name, setHasError, hasError }: { name: string; hasError: boolean; setHasError: (hasError: boolean) => void }) => {
	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		setHasError(false);
	}, [setHasError, name]);

	useEffect(() => {
		if (hasError) inputRef?.current?.focus();
	}, [hasError]);

	return {
		inputRef,
	};
};
