import { Box, Collapsible, InputLabel, Tag, Typography } from '@benefitflow/designsystem';

import { RangeFilterProps } from '../RangeFilter/RangeFilter';
import { NumericAutoComplete } from '../../../SidebarFilters/components';

/**
 * Similar to RangeFilter, but it accepts a custom numeric option for the range options.
 */
export const CustomRangeFilter = ({
	description,
	testId,
	icon,
	isOpen,
	onToggle,
	title,
	onSelect,
	options,
	selectedRange,
	defaultOption,
}: Omit<RangeFilterProps, 'placeholder'>) => {
	return (
		<Collapsible
			headerTestId={testId}
			isOpen={isOpen}
			toggle={onToggle}
			title={title}
			icon={icon}
			content={
				!isOpen &&
				(selectedRange.start != defaultOption.start || selectedRange.end != defaultOption.end) && (
					<Tag
						variant={'primary'}
						label={(() => {
							const start = options.start.find((option) => option.value == selectedRange.start)?.label ?? selectedRange.start;
							const end = options.end.find((option) => option.value == selectedRange.end)?.label ?? selectedRange.end;
							return `${start} - ${end}`;
						})()}
						onDelete={() => {
							onSelect.start(defaultOption.start);
							onSelect.end(defaultOption.end);
						}}
					/>
				)
			}
			openContent={
				<InputLabel label={description}>
					<Box width="full" display="flex" alignItems="center" justifyContent="between" gap="xs">
						<NumericAutoComplete defaultOptions={options.start} setValue={onSelect.start} value={selectedRange.start} />
						<Typography.Text whiteSpace="nowrap">to</Typography.Text>
						<NumericAutoComplete defaultOptions={options.end} setValue={onSelect.end} value={selectedRange.end} />
					</Box>
				</InputLabel>
			}
		/>
	);
};
