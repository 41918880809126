import { Box, Collapsible, InputLabel, Select, Tag, Typography } from '@benefitflow/designsystem';
import { FiltersProps } from '../../Filters.types';

export interface RangeFilterProps extends Omit<FiltersProps, 'placeholder'> {
	selectedRange: {
		start: string | number;
		end: string | number;
	};
	options: {
		start: Option[];
		end: Option[];
	};
	onSelect: {
		start: (value: string) => void;
		end: (value: string) => void;
	};
	defaultOption: {
		start: string;
		end: string;
	};
}

export interface Option {
	label: string;
	value: string | number;
}

const Input = ({ options, onChange, value }: { options: Option[]; value: string; onChange: (value: string) => void }) => {
	return (
		<Select testId="years-at-company-filter-start-input" value={value} onChange={(e) => onChange(e.target.value)}>
			{options.map(({ label, value }) => (
				<Select.Option key={value} value={value}>
					{label}
				</Select.Option>
			))}
		</Select>
	);
};

export const RangeFilter = ({ onToggle, defaultOption, description, selectedRange, isOpen, options, title, onSelect }: RangeFilterProps) => {
	return (
		<Collapsible
			headerTestId="years-at-company-filter-header"
			isOpen={isOpen}
			toggle={onToggle}
			title={title}
			icon="calendar"
			content={
				!isOpen &&
				(selectedRange.start != defaultOption.start || selectedRange.end != defaultOption.end) && (
					<Tag
						variant={'primary'}
						label={(() => {
							const start = options.start.find((option) => option.value == selectedRange.start)?.label;
							const end = options.end.find((option) => option.value == selectedRange.end)?.label;

							return `${start} - ${end}`;
						})()}
						onDelete={() => {
							onSelect.start(defaultOption.start);
							onSelect.end(defaultOption.end);
						}}
					/>
				)
			}
			openContent={
				<InputLabel label={description}>
					<Box width="full" display="flex" alignItems="center" justifyContent="between" gap="xs">
						<Input onChange={onSelect.start} options={options.start} value={String(selectedRange.start)} />
						<Typography.Text whiteSpace="nowrap">to</Typography.Text>
						<Input onChange={onSelect.end} options={options.end} value={String(selectedRange.end)} />
					</Box>
				</InputLabel>
			}
		/>
	);
};
