import { Box, Paper } from '@benefitflow/designsystem';

import { BenefitCategoryProps } from './Benefit.types';
import { Insight, Loading, Title } from './components';

export const Benefit = ({ iconBackgroundColor, icon, insights, title }: BenefitCategoryProps) => {
	return (
		<Paper p="lg">
			<Box display="flex" flexDirection="column" gap="lg">
				<Title icon={icon} title={title} iconBackgroundColor={iconBackgroundColor} />
				{insights
					.filter(({ text }) => !!text)
					.map((props, index) => (
						<Insight key={index} {...props} />
					))}
			</Box>
		</Paper>
	);
};

Benefit.Loading = Loading;
