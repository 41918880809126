import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SyncAccountsRequiringParent } from 'common.model/src/db/model-decorators/type-extensions';
import { useAccountsRequiringParent } from 'new-beginning/hooks/salesforce/useAccountsRequiringParent';

type ToggleTypes = 'resolveOrphanedAccounts' | 'assignAccountInsertParent' | 'assignContactOwner';
interface ToggleConfig {
	toggleModal: () => void;
	isOpen: boolean;
  isDisabled: boolean;
}
export type SyncToggleInterface = Record<ToggleTypes, ToggleConfig>;
export interface SyncModalConfiguration {
	contactRefetchTrigger: number;
	triggerContactRefetch: () => void;
	modalConfig: SyncToggleInterface;
	accountResolveInstance: SyncAccountsRequiringParent;
	fetchAccountsRequiringParent: (accountDiffIdsToFetch?: number[], callback?: VoidFunction) => Promise<void>;
}

export const useSyncModalConfig = (
	syncInProgress: boolean,
): SyncModalConfiguration => {
	// Simple State to indicate the ListContactContainer to refetch the Contact List after changes
	const [contactRefetchTrigger, setContactRefetchTrigger] = useState<number>(null);
	const triggerContactRefetch = () => setContactRefetchTrigger(Date.now());

	const [accountInsertOpen, setAccountInsert] = useState<boolean>(false);
	const toggleAccountInsert = () => setAccountInsert(!accountInsertOpen);

	const [orphanAccountOpen, setOrphanAccount] = useState<boolean>(false);
	const toggleOrphanAccount = () => setOrphanAccount(!orphanAccountOpen);

	const [assignOwnerOpen, setAssignOwner] = useState<boolean>(false);
	const toggleAssignOwner = () => setAssignOwner(!assignOwnerOpen);

	const { listId } = useParams();
	const { fetchAccountsRequiringParent, accountResolveInstance } = useAccountsRequiringParent(listId);

	const hasAccountInsertsToAssign = !!accountResolveInstance?.accountInserts?.length;
  const hasExistingAccountsToAssign = !!accountResolveInstance?.existingAccounts?.length;
	useEffect(() => {
		if (!hasAccountInsertsToAssign && accountInsertOpen) {
			toggleAccountInsert();
		}
	}, [hasAccountInsertsToAssign, accountInsertOpen]);

	const modalConfig: SyncToggleInterface = {
		assignAccountInsertParent: {
			isOpen: accountInsertOpen,
			toggleModal: toggleAccountInsert,
      isDisabled: !hasAccountInsertsToAssign,
		},
		resolveOrphanedAccounts: {
			isOpen: orphanAccountOpen,
			toggleModal: toggleOrphanAccount,
      isDisabled: !hasExistingAccountsToAssign,
		},
		assignContactOwner: {
			isOpen: assignOwnerOpen,
			toggleModal: toggleAssignOwner,
      isDisabled: false,
		}
	};

	return {
		modalConfig,
		accountResolveInstance,
		fetchAccountsRequiringParent,
		contactRefetchTrigger,
		triggerContactRefetch,
	};
};
