import { useContext, useMemo } from 'react';

import { ListDetailsContext } from '../../../ListDetails.context';

export const useOptions = ({ search }: { search: string }) => {
	const {
		options: { roles },
	} = useContext(ListDetailsContext);
	const options = useMemo(
		() =>
			[...(roles ?? [])]
				.filter(({ option }) => {
					if (!search) return true;
					return option.toLocaleLowerCase().includes(search.toLocaleLowerCase());
				})
				.map((data) => {
					return { value: data.option, label: data.formattedOption, data };
				}) ?? [],
		[roles, search]
	);

	return options;
};
