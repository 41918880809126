import { Box, Tabs as DSTabs } from '@benefitflow/designsystem';

import { Tabs as TabsEnum } from '../EmployerProfile.types';
import { useTabs } from './hooks';
import { EmployeeSentimentTab } from './components';

export const Tabs = () => {
	const [currentTab, setCurrentTab] = useTabs();

	return (
		<Box mx="sm">
			<DSTabs align="start" value={currentTab} onChange={setCurrentTab}>
				<DSTabs.Tab label="Benefits" icon="heart" value={TabsEnum.BENEFITS}>
					Benefits Content
				</DSTabs.Tab>
				<DSTabs.Tab label="History" icon="clock" value={TabsEnum.HISTORY}>
					History Content
				</DSTabs.Tab>
				<DSTabs.Tab label="Contacts" icon="contact-book" value={TabsEnum.CONTACTS}>
					Contacts Content
				</DSTabs.Tab>
				<DSTabs.Tab label="Employee Sentiment" icon="message" value={TabsEnum.EMPLOYEE_SENTIMENT}>
					<Box pt="xl">
						<EmployeeSentimentTab />
					</Box>
				</DSTabs.Tab>
			</DSTabs>
		</Box>
	);
};
