import { Box, Typography } from '@benefitflow/designsystem';

export const Empty = () => {
	return (
		<Box display="flex" justifyContent="center" alignItems="center">
			<Typography.Display color="neutral-400" size="md">
				Employee Sentiment data is not available for this employer
			</Typography.Display>
		</Box>
	);
};
