import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { MaxYears, MinYears } from '../../../YearsAtCompany.types';

import { selectListsDetailPage } from 'app/containers/ListsPage/containers/ListsDetailPage/selectors';

export const useLabel = (): string => {
	const {
		contactFilters: { yearsAtCompany },
	} = useSelector(selectListsDetailPage);

	const label = useMemo(() => {
		if (yearsAtCompany.length < 2) return '';
		const min = MinYears.find(({ value }) => value == yearsAtCompany[0])?.label;
		const max = MaxYears.find(({ value }) => value == yearsAtCompany[1])?.label;

		return `${min} - ${max}`;
	}, [yearsAtCompany]);

	return label;
};
