
export default class ControlledOpenUtils { 

    public static getControlledOpen(key: string, filtersOpenMap, onChange) {
        return {
          isOpen: filtersOpenMap[key],
          onChangeIsOpen: (isOpen: boolean) => { 
            const updated = {
              ...filtersOpenMap,
              [key]: isOpen,
            };
            onChange(updated);
          }
        };
      }
    
}