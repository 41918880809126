
export default class DateUtils {

    public static isWeekDay(d: Date) {
        const day = d.getDay()
        return day !== 6 && day !== 0;
    }

    public static addDays(startDate, days) {
        if (!startDate || !startDate.getTime()) {
            return startDate;
        }
        const copiedDate = new Date(startDate.getTime());
        copiedDate.setDate(copiedDate.getDate() + days);
        return copiedDate;
    }

    public static addMonths(date: Date, numMonths: number) {
        const copy = new Date(date.getTime());
        return new Date(copy.setMonth(copy.getMonth()+numMonths));
    }

    public static getFormattedDateString(date) {
        const year = date.getFullYear();
        const month = (1 + date.getMonth()).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return month + '/' + day + '/' + year;
    }

    public static getFormattedTimeStampString(date: Date) {
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${year}-${month}-${day}___${hours}_${minutes}_${seconds}`;
    } 

    public static getFirstOfMonth(date) {
        const year = date.getFullYear();
        const month = 1 + date.getMonth();
        const day = 1;
        const strDate = `${month}/${day}/${year}`;
        return new Date(strDate);
    }

    public static numWeekDaysBetween(startDate: Date, endDate: Date) : number {
        let curDate = startDate;
        let numWeekDays = 0;
        while (curDate <= endDate) {
            if (DateUtils.isWeekDay(curDate)) {
                numWeekDays += 1;
            }
            curDate = DateUtils.addDays(curDate, 1);
        }
        return numWeekDays;
    }

    public static getWeekDaysBetween(startDate: Date, endDate: Date) {
        let curDate = startDate;
        const weekDays = [];
        while (curDate <= endDate) {
            if (DateUtils.isWeekDay(curDate)) {
                weekDays.push(curDate);
            }
            curDate = DateUtils.addDays(curDate, 1);
        }
        return weekDays;
    }

    public static getNumDaysBetween(startDate: Date, endDate: Date) {
        let curDate = startDate;
        const days = [];
        while (curDate <= endDate) {
            days.push(curDate);
            curDate = DateUtils.addDays(curDate, 1);
        }
        return days.length;        
    }

    public static generateMonthsBetween(startDate, endDate) {
        const results = [];
        let curDate = new Date(startDate.getTime());
        while (curDate < endDate) {
            results.push(curDate);
            curDate = DateUtils.addMonths(curDate, 1); 
        }
        return results;
    }

    public static getNumMonthsBetween(startDate: Date, endDate: Date) : number {
        return this.generateMonthsBetween(startDate, endDate).length;
    }

    public static beginningOfMonth(date: Date) : Date {
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        return new Date(`${month}/01/${year}`);
    }

    public static formatLongMonthDay(dateStr: string) : string {
        if (dateStr == null) {
            return null;
        }
        const utcDate = new Date(dateStr); //Date object a day behind
        const localDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000) //local Date

        const month = localDate.toLocaleString('en-us', { month: 'short' });
        const day = localDate.getDate();
        return `${month} ${day}`;
    }

    public static getTimeDiff(startDate, endDate) {
        const msInMinute = 60 * 1000;
      
        const numMinutes = Math.round(
          Math.abs(endDate - startDate) / msInMinute
        );
        if (numMinutes < 60) {
            return `${numMinutes} minutes`;
        }
        const numHours = Math.round(numMinutes / 60);
        if (numHours < 24) {
            return `${numHours} hours`;
        }
        const numDays = Math.round(numHours / 24);
        return `${numDays} days`;
    }


}