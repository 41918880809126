import React from 'react';
import './style.scss';

export interface PanelProps {
  children?: React.ReactNode;
  className?: any;
}

export const Panel = ({ children, className }: PanelProps) => {
  return (
    <div className={`dev-panel ${className ?? ''}`}>
      <div className="panel-content">{children}</div>
    </div>
  );
};