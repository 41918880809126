import { useEffect, useMemo, useState } from 'react';
import { AutoComplete, Box, Collapsible, InputLabel, Tag } from '@benefitflow/designsystem';

import { RadiusSearchSelect } from './components';
import { FiltersProps } from '../../Filters.types';
import { useRadiusSearch, useValues } from './hooks';
import { EntityLocation, LocationSelection } from 'common.model/src/types/master/location';

export interface LocationFilterProps extends FiltersProps {
	options: { value: string; label: string; data: EntityLocation }[];
	setRadiusMiles: (radius: number) => void;
	selectedOptions: { include?: LocationSelection[]; exclude?: LocationSelection[] };
	onDelete: (params: { value: string; type: 'include' | 'exclude' }) => void;
	onSelect: (params: {
		value: {
			location: EntityLocation;
			radiusMiles: number;
		};
		type: 'include' | 'exclude';
	}) => void;
	onSearch: (search: string) => void;
}

export const LocationFilter = ({
	isOpen,
	onToggle,
	description,
	icon,
	isLoading,
	onDelete,
	onSelect,
	onSearch,
	options,
	selectedOptions,
	title,
	testId,
	placeholder,
	setRadiusMiles,
}: LocationFilterProps) => {
	const [search, setSearch] = useState('');
	useEffect(() => {
		setSearch(search);
	}, [search]);
	const { onSelectRadius, radius, shouldShowRadiusSearch } = useRadiusSearch({ setRadiusMiles, selectedOptions });
	const values = useValues({ selectedOptions });
	const selectedValues = useMemo(() => values.map(({ value }) => value), [values]);

	return (
		<Collapsible
			headerTestId={testId}
			isOpen={isOpen}
			toggle={onToggle}
			title={title}
			icon={icon}
			content={
				values.length && (
					<Box display="flex" gap="xxs" flexWrap="wrap">
						{values.map(({ label, value, type }) => (
							<Tag variant={type === 'include' ? 'primary' : 'danger'} label={label} onDelete={() => onDelete({ value, type })} key={value} />
						))}
					</Box>
				)
			}
			openContent={
				<>
					<InputLabel label={description}>
						<AutoComplete
							removable
							onDelete={({ data }) =>
								onSelect({
									type: 'exclude',
									value: {
										location: data,
										radiusMiles: radius,
									},
								})
							}
							inputTestId="hq-location-filter-input"
							selectedValues={selectedValues}
							loading={isLoading}
							placeholder={placeholder}
							onSelect={({ data }) =>
								onSelect({
									type: 'include',
									value: {
										location: data,
										radiusMiles: radius,
									},
								})
							}
							options={options}
							onSearch={onSearch}
						/>
					</InputLabel>
					{shouldShowRadiusSearch && (
						<Box mt="sm">
							<RadiusSearchSelect radius={radius} onSelectRadius={onSelectRadius} />
						</Box>
					)}
				</>
			}
		/>
	);
};
