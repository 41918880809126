import { Description, Skeleton, Typography } from '@benefitflow/designsystem';

import CarrierAppointments, { Appointment } from 'atoms/CarrierAppointments';
import { useDurationLabel } from './hooks';
import PhoneNumber from 'awesome-phonenumber';
import { BrokerContactCardProps } from '../../../../pages/Search/BrokerContactCard/BrokerContactCard.types';
import { TextOverflowTooltip } from 'new-beginning/components/shared/TextOverflowTooltip';

export const Descriptions = {
	Appointments: ({ appointments, loading }: { appointments?: Appointment[]; loading?: boolean }) => {
		if (loading) return <Skeleton height="sm" width="xl5" />;
		if (!appointments || appointments.length === 0) return null;
		return <Description icon="shield" label={<CarrierAppointments appointments={appointments} />} />;
	},
	YearsInRole: ({ months, loading }: { months?: number; loading?: boolean }) => {
		const duration = useDurationLabel({ months, sufix: 'in role' });
		if (loading) return <Skeleton height="sm" width="xl4" />;
		if (!duration?.value || !duration?.label) return null;
		return <Description icon="briefcase" value={duration.value} label={duration.label} />;
	},
	YearsAtCompany: ({ months, loading }: { months?: number; loading?: boolean }) => {
		const duration = useDurationLabel({ months, sufix: 'at company' });
		if (loading) return <Skeleton height="sm" width="xl4" />;
		if (!duration?.value || !duration?.label) return null;
		return <Description icon="business" value={duration.value} label={duration.label} />;
	},
	Email: ({ hasEmail, email, loading }: Pick<BrokerContactCardProps, 'hasEmail' | 'email' | 'loading'>) => {
		if (loading) return <Skeleton height="sm" width="xl5" />;
		if (!hasEmail) return null;
		if (!email) return <Description icon="email" label={email ? email : '********@******.com'} />;
		return (
			<Description containerProps={{ overflow: 'hidden', width: 'full' }} icon="email">
				<TextOverflowTooltip label={email}>
					{(overflowCallback) => (
						<Typography.Text
							data-testid="visible-email"
							ellipsedCallback={overflowCallback}
							ellipsis
							whiteSpace="nowrap"
							size="sm"
							weight="regular"
							color="neutral-500"
						>
							{email}
						</Typography.Text>
					)}
				</TextOverflowTooltip>
			</Description>
		);
	},
	MobilePhones: ({ hasPhone, phone, loading }: { hasPhone: boolean; phone?: string; loading: boolean }) => {
		if (loading) return <Skeleton height="sm" width="xl4" />;
		if (!hasPhone) return null;
		if (!phone) return <Description icon="call" label="(***) ***-****" />;
		return (
			<Description containerProps={{ overflow: 'hidden', width: 'full' }} icon="call">
				<TextOverflowTooltip label={PhoneNumber(phone, 'US').getNumber('national')}>
					{(overflowCallback) => (
						<Typography.Text
							data-testid="visible-phone"
							ellipsedCallback={overflowCallback}
							ellipsis
							whiteSpace="nowrap"
							size="sm"
							weight="regular"
							color="neutral-500"
						>
							{PhoneNumber(phone, 'US').getNumber('national')}
						</Typography.Text>
					)}
				</TextOverflowTooltip>
			</Description>
		);
	},
};
