import { createContext, useState, FC, PropsWithChildren, useContext } from 'react';

export enum NotificationType {
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export interface GlobalNotification {
  message: string;
  messageType: NotificationType;
}

export interface NotificationContextValue {
  notificationSet: GlobalNotification[];
  setNotification: (message: string, messageType: NotificationType) => void;
  clearNotification: () => void;
}

export const GlobalNotificationContext = createContext<NotificationContextValue>({
  notificationSet: [],
  clearNotification: () => null,
  setNotification: () => null,
});

export const useNotificationContext = () => useContext(GlobalNotificationContext);

export const WithNotificationContext: FC<PropsWithChildren> = ({ children }) => {
  const [notificationSet, setNotificationSet] = useState<GlobalNotification[]>(() => []);

  const setNotification = (message: string, messageType: NotificationType) => {
    setNotificationSet([ ...notificationSet, { message, messageType } ]);
  };

  const clearNotification = () => {
    const notificationQueue = notificationSet || [];
    setNotificationSet(notificationQueue.slice(1));
  };

  return (
    <GlobalNotificationContext.Provider
      value={{ clearNotification, setNotification, notificationSet }}
    >
      {children}
    </GlobalNotificationContext.Provider>
  );
};
