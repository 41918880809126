import { FC } from 'react';
import { Header } from './header';
import { ListTable } from './ListTable';
import cn from 'classnames';
interface ListPageProps {
	isSalesforceVisible: boolean;
}

export const ListPage: FC<ListPageProps> = ({ isSalesforceVisible }) => {
	return (
		<div className={cn('d-flex', 'flex-column', 'align-items-center', 'justify-content-center', 'w-100')}>
			<Header />
			<ListTable isSalesforceVisible={isSalesforceVisible} />
		</div>
	);
};
