import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { listsDetailPageActions } from 'app/containers/ListsPage/containers/ListsDetailPage/slice';
import { selectListsDetailPage } from 'app/containers/ListsPage/containers/ListsDetailPage/selectors';

export const useOnSelect = () => {
	const dispatch = useDispatch();
	const { contactFilters } = useSelector(selectListsDetailPage);

	const onSelect = useCallback(
		({ value }: { value: string }) => {
			dispatch(
				listsDetailPageActions.updateContactFilters({
					...contactFilters,
					carrierAppointments: {
						include: [value],
						exclude: contactFilters.carrierAppointments.exclude,
					},
				}),
			);
		},
		[dispatch, contactFilters],
	);

	return onSelect;
};
