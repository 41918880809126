import { useEffect } from 'react';

export const useSelectDefaultTabWhenClosed = ({
	isAddToListDialogOpen,
	setTab,
}: {
	isAddToListDialogOpen: boolean;
	setTab: (tab: 'add' | 'create') => void;
}) => {
	useEffect(() => {
		if (!isAddToListDialogOpen) setTab('add');
	}, [isAddToListDialogOpen, setTab]);
};
