import { RADIUS_SEARCH_DEFAULT } from 'app/constants';
import { BrokerContactsStore } from '../../components/pages/Search/BrokerContacts/BrokerContacts.types';
import { EntityLocation } from 'common.model/src/types/master/location';

type LocationFilter = BrokerContactsStore['filters']['contactLocation'] | BrokerContactsStore['filters']['brokerOfficeLocations'];

export type LocationMethod = Record<
	'include' | 'exclude',
	{
		add: (args: { location: EntityLocation; radiusMiles?: number }) => void;
		remove: (args: { searchValue: string }) => void;
	}
> & {
	setRadiusMiles: (radiusMiles: number) => void;
};

export const createLocationMethods = <Store extends { filters: any }>(
	filterName: string,
	set: (fn: (state: Store) => Partial<Store>) => void,
): LocationMethod => ({
	include: {
		add: ({ location, radiusMiles }: { location: EntityLocation; radiusMiles?: number }) =>
			set(
				(state) =>
					({
						filters: {
							...state.filters,
							[filterName]: {
								...state.filters[filterName],
								include: [
									...state.filters[filterName]['include'],
									{
										location,
										radiusMiles: radiusMiles ?? RADIUS_SEARCH_DEFAULT,
									},
								],
							},
						},
					}) as Store,
			),
		remove: ({ searchValue }: { searchValue: string }) =>
			set(
				(state) =>
					({
						filters: {
							...state.filters,
							[filterName]: {
								...state.filters[filterName],
								include: state.filters[filterName]['include'].filter((loc) => loc.location.searchValue !== searchValue),
							},
						},
					}) as Store,
			),
	},
	exclude: {
		add: ({ location, radiusMiles }: { location: LocationFilter['exclude'][number]['location']; radiusMiles?: number }) =>
			set(
				(state) =>
					({
						filters: {
							...state.filters,
							[filterName]: {
								...state.filters[filterName],
								exclude: [
									...state.filters[filterName]['exclude'],
									{
										location,
										radiusMiles: radiusMiles ?? RADIUS_SEARCH_DEFAULT,
									},
								],
							},
						},
					}) as Store,
			),
		remove: ({ searchValue }: { searchValue: string }) =>
			set(
				(state) =>
					({
						filters: {
							...state.filters,
							[filterName]: {
								...state.filters[filterName],
								exclude: state.filters[filterName]['exclude'].filter((loc) => loc.location.searchValue !== searchValue),
							},
						},
					}) as Store,
			),
	},
	setRadiusMiles: (radiusMiles: number) =>
		set(
			(state) =>
				({
					filters: {
						...state.filters,
						[filterName]: {
							...state.filters[filterName],
							include: state.filters[filterName]['include'].map((loc) => ({ ...loc, radiusMiles })),
							exclude: state.filters[filterName]['exclude'].map((loc) => ({ ...loc, radiusMiles })),
						},
					},
				}) as Store,
		),
});
