import { useMemo } from 'react';

import { useGetValueByScreenSizes } from '../../../../../hooks/useGetValueByScreenSizes';

export const useHeight = () => {
	const getValueByScreenSizes = useGetValueByScreenSizes();

	const height = useMemo(
		() =>
			getValueByScreenSizes({
				lg: 123,
				md: 250,
				sm: 410,
			}),
		[getValueByScreenSizes],
	);

	return height;
};
