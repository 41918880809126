import { create } from 'zustand';

import { StoreUtils } from '../../../../utilities';
import { BrokerContactsStore, COLLAPSIBLE_MAP_KEYS } from './BrokerContacts.types';
import { getDefaultBrokerContactFilters } from 'common.model/src/types/common/search/filters/ContactFilters';
import { BrokerContactFilters } from 'common.model/src/types/common/search/filters/ContactFilters';

type IncludeExcludeFilters = Omit<BrokerContactsStore['filters'], 'yearsAtCompany' | 'search'>;

export const useBrokerContactsStore = create<BrokerContactsStore>((set, get) => ({
	...StoreUtils.createContactSelectorMethods(set),
	isAddToListDialogOpen: false,
	setIsAddToListDialogOpen: (isOpen: boolean) => set({ isAddToListDialogOpen: isOpen }),
	filters: getDefaultBrokerContactFilters(),
	setFilter: {
		brokerSize: StoreUtils.createIncludeExcludeMethods<IncludeExcludeFilters, string>('brokerSize', set),
		carrierAppointments: StoreUtils.createIncludeExcludeMethods<IncludeExcludeFilters, string>('carrierAppointments', set),
		companyName: StoreUtils.createIncludeExcludeMethods<IncludeExcludeFilters, string>('companyName', set),
		contactLocation: StoreUtils.createLocationMethods('contactLocation', set),
		brokerOfficeLocations: StoreUtils.createLocationMethods('brokerOfficeLocations', set),
		jobTitles: StoreUtils.createIncludeExcludeMethods<IncludeExcludeFilters, string>('jobTitles', set),
		licenseTypes: StoreUtils.createIncludeExcludeMethods<IncludeExcludeFilters, string>('licenseTypes', set),
		roles: StoreUtils.createIncludeExcludeMethods<IncludeExcludeFilters, string>('roles', set),
		yearsAtCompany: StoreUtils.createRangeMethodsLegacy('yearsAtCompany', set),
		nameSearch: (value: string) =>
			set((state) => {
				const updated: BrokerContactFilters = {
					...state.filters,
					nameSearch: value, // this should probably be nameSearch
				};
				return { filters: updated };
			}),
	},
	setFilters: (filters) => set({ filters }),
	clearCheckedMap: () => set({ checkedMap: {}, checkMode: 'single-select' }),
	/**
	 * Used mainly for testing. Avoid using it in production code in favor of more specific methods.
	 */
	setState: (state) => set(state),
	collapsible: {
		map: COLLAPSIBLE_MAP_KEYS.reduce(
			(acc, key) => {
				acc[key] = false;
				return acc;
			},
			{} as Record<(typeof COLLAPSIBLE_MAP_KEYS)[number], boolean>,
		),
		toggle: COLLAPSIBLE_MAP_KEYS.reduce(
			(acc, key) => {
				acc[key] = () =>
					set((state) => ({
						collapsible: {
							...state.collapsible,
							map: {
								...Object.keys(state.collapsible.map).reduce(
									(acc, cur) => ({ ...acc, [cur]: false }),
									{} as Record<(typeof COLLAPSIBLE_MAP_KEYS)[number], boolean>,
								),
								[key]: !state.collapsible.map[key],
							},
						},
					}));
				return acc;
			},
			{} as Record<(typeof COLLAPSIBLE_MAP_KEYS)[number], () => void>,
		),
		clear: () =>
			set((state) => ({
				collapsible: {
					...state.collapsible,
					map: COLLAPSIBLE_MAP_KEYS.reduce((acc, cur) => ({ ...acc, [cur]: false }), {} as Record<(typeof COLLAPSIBLE_MAP_KEYS)[number], boolean>),
				},
			})),
	},
}));
