import { useContext, useMemo } from 'react';
import { APIContext } from 'new-beginning/services/api/Api.context';

export const useOptions = ({ employerMasterId }: { employerMasterId: string }) => {
	const { employerApi } = useContext(APIContext);
	const { data } = employerApi.useGetEmployerFilingYears(
        { employer_master_id: employerMasterId }, 
    );

	const options = useMemo<{ option: string }[]>(
		() =>
            data
                ?.sort((a, b) => b - a)
                .map((data) => ({ option: data.toString() })) ?? [],
		[data],
	);

	return options;
};
