import { useMemo } from 'react';
import FormattingUtils from 'utils/FormattingUtils';

export const useAppointments = (raw: { company_name: string; first_appointment_date: string }[] = []): [string, string][] => {
	const appointments = useMemo(() => {
		const appointmentsMap = new Map<string, number>();
		raw.forEach(({ company_name, first_appointment_date }) => {
			const companyName = FormattingUtils.formatUpperCasing(company_name);
			const year = new Date(first_appointment_date).getFullYear();
			const previous = appointmentsMap.get(companyName);
			if (!previous) appointmentsMap.set(companyName, year);
			else if (year < previous) appointmentsMap.set(companyName, year);
		});
		const appointments: [string, string][] = [];
		appointmentsMap.forEach((year, companyName) => {
			appointments.push([companyName, `Since ${year}`]);
		});
		appointments.sort(([a], [b]) => a.localeCompare(b));

		return appointments;
	}, [raw]);

	return appointments;
};
