import cn from 'classnames';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { SortKey, SortOrder } from 'common.model/src/types/Pagination';
import { SetSortParams } from 'new-beginning/hooks/salesforce/useListContacts';
import { FC } from 'react';

export interface SortableHeaderProps {
	header: string;
	sortKey: SortKey;
	setSort: (args: SetSortParams) => void;
	activeSortDirection: SortOrder | null;
	disabled: boolean;
	alignRight?: boolean;
	hasSoftText?: boolean;
	iconSize?: number;
}

enum OrderUnset {
	DEFAULT = 'UNSET',
}

const getNextSortOrder = (currentOrder: SortOrder | null, currentKey: SortKey): SetSortParams => {
	const nextOrder = {
		[OrderUnset.DEFAULT]: SortOrder.DESC,
		[SortOrder.DESC]: SortOrder.ASC,
		[SortOrder.ASC]: null,
	};

	const sortOrderKey = currentOrder || OrderUnset.DEFAULT;
	const sortOrder = nextOrder?.[sortOrderKey];
	const sortKey = sortOrder ? currentKey : null;

	return { sortKey, sortOrder };
};

const resetSortConfig: SetSortParams = {
	sortKey: null,
	sortOrder: null,
};

export const SortableHeader: FC<SortableHeaderProps> = ({
	header,
	sortKey,
	setSort,
	activeSortDirection,
	disabled,
	alignRight = false,
	hasSoftText = true,
	iconSize = 16,
}) => {
	const ascActive = activeSortDirection === SortOrder.ASC;
	const descActive = activeSortDirection === SortOrder.DESC;
	const ascArgs = !ascActive ? { sortKey, sortOrder: SortOrder.ASC } : resetSortConfig;
	const descArgs = !descActive ? { sortKey, sortOrder: SortOrder.DESC } : resetSortConfig;

	return (
		<div
			className={cn(
				'bf-th-sortable',
				{
					faded: disabled,
					'justify-content-end': alignRight,
				},
				'd-flex',
				'align-items-center',
				'me-1'
			)}
		>
			<div
				className={cn({ 'bf-txt': hasSoftText }, 'txt-clickable')}
				onClick={() => setSort(getNextSortOrder(activeSortDirection, sortKey))}
			>
				{header}
			</div>
			<div className={cn('bf-icon-group', 'd-flex', 'flex-column', 'ms-1')}>
				<FaCaretUp
					className={cn('bf-icon', 'sort-icon', { 'sort-active': ascActive })}
					onClick={() => setSort(ascArgs)}
					viewBox='0 -120 320 512'
					size={iconSize}
				/>
				<FaCaretDown
					className={cn('bf-icon', 'sort-icon', { 'sort-active': descActive })}
					onClick={() => setSort(descArgs)}
					viewBox='0 120 320 512'
					size={iconSize}
				/>
			</div>
		</div>
	);
};
