import { useContext, useMemo } from 'react';

import { ListDetailsContext } from '../../../ListDetails.context';

export const useLoading = () => {
	const {
		loading: filtersAreLoading,
		options: { jobTitles },
	} = useContext(ListDetailsContext);
	const loading = useMemo(() => filtersAreLoading && !jobTitles?.length, [filtersAreLoading, jobTitles]);

	return loading;
};
