// @ts-nocheck
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MSearchBar from 'material-ui-search-bar';
import { FaSearch } from 'react-icons/fa';

const StyledSearchBar = withStyles(theme => ({
  root: {
    borderRadius: '8px',
    boxShadow: '2px 4px 11px rgba(228, 228, 228, 0.25)',
    background: '#FFFFFF',
  },
  iconButton: {
    padding: '10px',
  },
}))(MSearchBar);

const WAIT_INTERVAL = 400;

export interface SearchBarProps {
  value: string;
  placeHolder?: string;
  onChange: (val) => any;
  onSearch: (val) => any;
}

class SearchBarState {
  onChangedCalled: boolean;
  // isLoaded: boolean;
  // isClear: boolean;
}

export class SearchBar extends Component<
  SearchBarProps,
  SearchBarState
> {
  constructor(props) {
    super(props);
    this.state = {
      onChangedCalled: false,
      // isLoaded: false,
      // isClear: false
    };

    this.triggerChange = this.triggerChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  timer = null;

  componentWillMount() {
    this.timer = null;
  }

  handleChange(value) {
    clearTimeout(this.timer);

    this.setState({
      onChangedCalled: false,
    });
    this.props.onChange(value);
    this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL);
  }

  cancel() {
    clearTimeout(this.timer);
    this.setState({
      onChangedCalled: false,
    });
    this.props.onChange('');
    this.timer = setTimeout(this.triggerChange, 100);
    setTimeout(() => this.triggerChange(), 0);
    // this.triggerChange();
  }

  triggerChange() {
    const { onChangedCalled } = this.state;
    if (!onChangedCalled) {
      this.props.onSearch(this.props.value);
      this.setState({ onChangedCalled: true });
    }
  }

  render() {
    return (
      <StyledSearchBar
        value={this.props.value}
        onChange={this.handleChange}
        onRequestSearch={this.triggerChange}
        onCancelSearch={this.cancel}
        placeholder={this.props.placeHolder}
        searchIcon={<FaSearch size={16} />}
      />
    );
  }
}
