
import React from 'react';
import styled from 'styled-components';
import DropDownPopover from 'atoms/filters/drop/DropDownPopover';


const StyledNavButton = styled.button<{ open: boolean }>`
  height: 40px;
  padding: 0 10px 0 1rem;
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${props => (props.open ? '#185adb' : 'transparent')};
  color: #185adb;
  background-color: ${props =>
    props.open ? 'rgba(24, 90, 219, 0.09)' : 'transparent'};
  font-family: 'Roboto';
  font-weight: 600;
  font-size: 17px;

  svg {
    margin-left: 0.25rem;
  }

  &:hover {
    // background-color: rgba(24, 90, 219, 0.09);
    border: 1px solid #185adb;
    // backgroundColor: "rgba(24, 90, 219, 0.09) !important" 
  }
`;

type NavDropDownProps = {
    getNavButtonEl: (isMenuOpen) => any;
    getPopOverChildren: (closeMenu: () => void) => any[];
};

const NavDropDown = ({
    getNavButtonEl,
    getPopOverChildren
}: NavDropDownProps) => {

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
  };
  
  const handleMenuClose = () => {
      setAnchorEl(null);
  };

  const isMenuOpen = Boolean(anchorEl);

  return (
    <div>
        <StyledNavButton
            open={isMenuOpen}
            aria-describedby="account-menu"
            onClick={handleProfileMenuOpen}
            >
            <div
                style={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                }}
            >
                { getNavButtonEl(isMenuOpen) }
            </div>
        </StyledNavButton>
        <DropDownPopover 
            anchorEl={anchorEl}
            handleMenuClose={handleMenuClose} 
            children={getPopOverChildren(handleMenuClose)} 
        />
    </div>
  );
};

export default NavDropDown;
