import { Box } from '@benefitflow/designsystem';
import { InsightsAcrossBenefits, Info, Empty } from './components';
import { useIsEmpty } from './hooks';

export const EmployeeSentimentTab = () => {
	const isEmpty = useIsEmpty();

	if (isEmpty) return <Empty />;

	return (
		<Box display="flex" flexDirection="column" gap="lg">
			<Info />
			<InsightsAcrossBenefits />
		</Box>
	);
};
