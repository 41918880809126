import cn from 'classnames';
import { PaginationParams } from 'common.model/src/types/Pagination';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa6';

interface SimplePaginationProps {
	paginationState: PaginationParams;
	setPage: (page: number) => void;
	isLoading: boolean;
}

export const PaginationControlSimple = ({ paginationState, setPage, isLoading }: SimplePaginationProps) => {
	const resultStartIndex = paginationState?.offset * paginationState?.limit;
	const resultEndIndex = Math.min(resultStartIndex + paginationState?.limit, paginationState?.count);
	const hasMorePages = paginationState?.offset < paginationState?.numPages - 1;

	const noResults = (paginationState?.count || 0) === 0;
	const displayStartIndex = noResults ? 0 : resultStartIndex + 1;

	return (
		<div className={cn('bf-paginate', 'd-flex', 'align-items-center', 'ps-1')}>
			<div className={cn('pagination-meta', { faded: isLoading }, 'd-flex', 'justify-content-center', 'me-2')}>
				<p className={cn('bf-txt', 'txt-sm', 'txt-lighter')}>
					{`${displayStartIndex} - ${resultEndIndex} of ${paginationState?.count}`}
				</p>
			</div>
			<div className={cn('bf-btn', 'btn-group', 'ms-1')}>
				<button
					onClick={() => setPage(paginationState?.offset - 1)}
					className={cn('bf-btn', 'btn-icon-grouped', 'left')}
					disabled={isLoading || resultStartIndex <= 0}
				>
					<FaChevronLeft className={'bf-icon'} size={18} />
				</button>
				<button
					onClick={() => setPage(paginationState?.offset + 1)}
					className={cn('bf-btn', 'btn-icon-grouped', 'right')}
					disabled={isLoading || !hasMorePages}
				>
					<FaChevronRight className={'bf-icon'} size={18} />
				</button>
			</div>
		</div>
	);
};
