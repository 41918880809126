import { useListDetailsFilterStore } from '../../../ListDetails.store';

export const useToggle = () => {
	const {
		collapsible: {
			toggle: { yearsAtCompany },
		},
	} = useListDetailsFilterStore();

	return yearsAtCompany;
};
