import { useListDetailsFilterStore } from '../../../ListDetails.store';

export const useIsOpen = () => {
	const {
		collapsible: {
			map: { contactLocation },
		},
	} = useListDetailsFilterStore();

	return contactLocation;
};
