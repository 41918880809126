import { Box, Paper, Skeleton, Typography } from '@benefitflow/designsystem';
import { useEmployeeSentiment } from 'new-beginning/components/pages/EmployerProfile/Tabs/components/EmployeeSentimentTab/hooks';

export const Summary = () => {
	const { loading, summary } = useEmployeeSentiment();

	if (loading)
		return (
			<Paper p="lg" style={{ height: '100%' }}>
				<Box height="full" display="flex" flexDirection="column" gap="sm">
					<Skeleton height="md" width="xl6" />
					{new Array(3).fill(null).map(() => (
						<Skeleton height="sm" width="full" />
					))}
				</Box>
			</Paper>
		);

	if (!summary) return null;

	return (
		<Paper p="lg" style={{ height: '100%' }}>
			<Box height="full" display="flex" flexDirection="column" gap="xxs">
				<Typography.Text size="lg" color="neutral-600">
					Summary
				</Typography.Text>
				<Typography.Text size="md" weight="regular" color="neutral-600">
					{summary}
				</Typography.Text>
			</Box>
		</Paper>
	);
};
