import { Range } from 'common.model/src/types/common/search/filters/filters';

export const pageSize: number = 25;
export const maxPageSize: number = 10_000;
export const maxAggregationSize: number = 2_500;
export const cardinalityPrecision: number = 40_000;
export const maxEmployeeCountRange: Range = { start: 0, end: 3_000_000 };
export const maxRenewalDateRange: Range = { start: 1, end: 12 };
export const maxYearsAtCompanyRange: Range = { start: 0, end: 21 };
export const synchronousContactOperationLimit: number = 5_000;
export const maxContactOperationLimit: number = 30_000;
