import cn from 'classnames';
import { FaLinkedin, FaMapMarkerAlt } from 'react-icons/fa';
import { TbLink } from "react-icons/tb";
import { AccountField, LabeledContactSyncField } from 'new-beginning/components/pages/ListSync/SyncTable/TableRows/SyncFieldStatus';
import { ListContactType } from 'common.model/src/db/model-decorators/type-extensions';
import { openLinkInTab } from 'new-beginning/utilities';

interface RowDetailProps {
	listContact: ListContactType;
}

export const RowDetail = ({ listContact }: RowDetailProps) => {
	const companyWebsite = listContact?.account?.company?.website;
	const addressFull = listContact?.account?.office?.streetAddress;
	const streetAddressOnly = addressFull?.split(', ')?.[0]?.toLowerCase() || null;

	return (
		<tr className={cn('expanding-content')}>
			<td />
			<td className={cn('ps-0')} colSpan={2}>
				<div className={cn('d-flex', 'align-items-center', 'pb-2', 'contact-detail-header')}>
					<p className={cn('bf-txt', 'txt-md', 'txt-lighter', 'txt-bolder')}>Contact Info</p>
					<FaLinkedin
						onClick={openLinkInTab(listContact?.contact?.linkedinUrl)}
						className={cn('bf-icon', 'ic-action', 'ic-linkedin', 'ms-1')}
						size={20}
					/>
				</div>
				<div className={cn('d-flex', 'align-items-end', 'justify-content-start', 'flex-wrap')}>
					<div className={cn('d-flex', 'flex-column', 'me-5')}>
						<LabeledContactSyncField
							label='Mobile Phone'
							field='cellNumber'
							listContact={listContact}
							updateFieldSet={['mobile_phone_1', 'mobile_phone_2', 'mobile_phone_3']}
							showWarning
						/>
						<LabeledContactSyncField label='City' field='city' listContact={listContact} updateFieldSet={['employee_city']} />
					</div>
					<div className={cn('d-flex', 'flex-column', 'me-1')}>
						<LabeledContactSyncField
							label='Email Address'
							field='emailAddress'
							listContact={listContact}
							updateFieldSet={['work_email']}
							showWarning
						/>
						<LabeledContactSyncField
							label='State'
							field='state'
							listContact={listContact}
							updateFieldSet={['employee_state', 'employee_state_code']}
						/>
					</div>
				</div>
			</td>
			<td>
				<div className={cn('d-flex', 'align-items-center', 'pb-2', 'contact-detail-header')}>
					<p className={cn('bf-txt', 'txt-md', 'txt-lighter', 'txt-bolder')}>Account Info</p>
					<FaLinkedin
						onClick={openLinkInTab(listContact?.account?.company?.companyProfileUrl)}
						className={cn('bf-icon', 'ic-action', 'ic-linkedin', 'ms-1')}
						size={20}
					/>
					<TbLink
						onClick={openLinkInTab(`https://${companyWebsite}`)}
						className={cn('bf-icon', 'ic-action', 'ic-link', 'ms-1', {
							hidden: !companyWebsite,
						})}
						size={22}
					/>
					<FaMapMarkerAlt
						size={18}
						className={cn('bf-icon', 'ic-action', 'ic-base', 'ms-1', { hidden: !streetAddressOnly })}
						onClick={openLinkInTab(`http://maps.google.com/?q=${addressFull}`)}
					/>
				</div>
				<div className={cn('d-flex', 'align-items-end', 'justify-content-start', 'flex-wrap')}>
					<div className={cn('d-flex', 'flex-column', 'me-5')}>
						<AccountField
							label='Website'
							value={companyWebsite}
						/>
						<AccountField label='City' value={listContact?.account?.office?.city} />
					</div>
					<div className={cn('d-flex', 'flex-column', 'me-1')}>
						<AccountField
							label='Zip Code'
							value={listContact?.account?.office?.zipCode}
						/>
						<AccountField
							label='State'
							value={listContact?.account?.office?.state}
						/>
					</div>
				</div>
			</td>
		</tr>
	);
};
