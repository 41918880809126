import { Button } from '@benefitflow/designsystem';
import { useGetSelected, useIsDisabled } from './hooks';

export const GetSelectedButton = () => {
	const { getSelected, loading } = useGetSelected();
	const isDisabled = useIsDisabled();

	return (
		<Button disabled={isDisabled} icon="user-check" fetching={loading} onClick={getSelected}>
			Get Selected
		</Button>
	);
};
