import { useMemo } from 'react';
import { useEmployeeSentiment } from 'new-beginning/components/pages/EmployerProfile/Tabs/components/EmployeeSentimentTab/hooks';

export const useIcon = (type: ReturnType<typeof useEmployeeSentiment>['type']) => {
	const icon = useMemo(() => {
		if (type === 'increase') return 'arrow-up';
		if (type === 'decrease') return 'arrow-down';

		return 'remove';
	}, [type]);

	return icon;
};
