import { create } from 'zustand';

export interface BrokerAccountsFilterStore {
	collapsible: {
		map: Record<(typeof COLLAPSIBLE_MAP_KEYS)[number], boolean>;
		toggle: Record<(typeof COLLAPSIBLE_MAP_KEYS)[number], () => void>;
		clear: () => void;
	};
}

export const COLLAPSIBLE_MAP_KEYS = [
	'companyName',
	'officeLocation',
	'role',
	'jobTitle',
	'yearsAtCompany',
	'primaryBroker',
	'employerTotalEmployees',
	'hqLocation',
	'employerHeadquarters',
	'employerIndustries',
	'employerEntityType',
	'employerPlanType',
	'employerLineOfBusiness',
	'employerCarrierName',
	'employerRenewalDatesRange',
] as const;

export const useEmployerDetailsFilterStore = create<BrokerAccountsFilterStore>((set) => ({
	collapsible: {
		map: COLLAPSIBLE_MAP_KEYS.reduce(
			(acc, key) => {
				acc[key] = false;
				return acc;
			},
			{} as Record<(typeof COLLAPSIBLE_MAP_KEYS)[number], boolean>,
		),
		toggle: COLLAPSIBLE_MAP_KEYS.reduce(
			(acc, key) => {
				acc[key] = () =>
					set((state) => ({
						collapsible: {
							...state.collapsible,
							map: {
								...Object.keys(state.collapsible.map).reduce(
									(acc, cur) => ({ ...acc, [cur]: false }),
									{} as Record<(typeof COLLAPSIBLE_MAP_KEYS)[number], boolean>,
								),
								[key]: !state.collapsible.map[key],
							},
						},
					}));
				return acc;
			},
			{} as Record<(typeof COLLAPSIBLE_MAP_KEYS)[number], () => void>,
		),
		clear: () =>
			set((state) => ({
				collapsible: {
					...state.collapsible,
					map: COLLAPSIBLE_MAP_KEYS.reduce((acc, cur) => ({ ...acc, [cur]: false }), {} as Record<(typeof COLLAPSIBLE_MAP_KEYS)[number], boolean>),
				},
			})),
	},
}));
