import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.employerPage || initialState;

export const selectEmployerPage = createSelector(
  [selectDomain],
  employerPageState => employerPageState,
);

export const selectEmployerContactFilters = createSelector(
  [selectDomain],
  employerPageState => employerPageState.contactFilters,
);