import { useMemo, useState } from 'react';
import { AutoComplete, Collapsible, InputLabel } from '@benefitflow/designsystem';

import { useIsOpen, useLoading, useOnDelete, useOnExclude, useOnSearch, useOnSelect, useOptions, useToggle, useValues } from './hooks';
import { Tags } from 'new-beginning/components/shared/SidebarFilters/components/Tags/Tags';

export const CompanyName = () => {
	const [search, setSearch] = useState('');
	const values = useValues();
	const loading = useLoading();
	const onSelect = useOnSelect();
	const onDelete = useOnDelete();
	const options = useOptions({ search });
	const onSearch = useOnSearch({ setSearch });
	const selectedValues = useMemo(() => values.map(({ value }) => value), [values]);
	const isOpen = useIsOpen();
	const toggle = useToggle();
	const onExclude = useOnExclude();

	return (
		<Collapsible
			headerTestId='company-name-filter-header'
			isOpen={isOpen}
			toggle={toggle}
			title='Company Name'
			icon='briefcase'
			content={values.length && <Tags onDelete={onDelete} values={values} />}
			openContent={
				<InputLabel label='Contacts employed at [Mercer]'>
					<AutoComplete
						removable
						onDelete={onExclude}
						inputTestId='company-name-filter-input'
						selectedValues={selectedValues}
						loading={loading}
						placeholder='e.g. Mercer'
						onSelect={onSelect}
						options={options}
						onSearch={onSearch}
					/>
				</InputLabel>
			}
		/>
	);
};
