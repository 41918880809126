import serviceUrl from 'utils/serviceUrl';
import axios from 'axios';
import {TenantUser} from './useTenantTeamInfo';
import { useBasicApiRequestState, BasicApiRquestState, BaseApiReponse } from './useBasicApiRequestState';

interface Updates {
    creditAllocationAmount: number | null;
    resetLimitlessAllocation: boolean;
    permissionLevel?: string;
}
interface EditUserPayload {
    userToEdit: TenantUser,
    updates: Updates;
}

export interface UpdateUserAttribueResponse extends BaseApiReponse {
    updateUserAttributes: (user: TenantUser, updates: Updates) => Promise<void>;
}

const updateUserCreditsUrl = `${serviceUrl}/user/updateUserAttributes`;
export const useUpdateUserAttributes = (): UpdateUserAttribueResponse => {
    const {
        baseHeaders,
        loading, setLoading,
        requestApiSuccess, setApiRequestSuccess,
        requestErrorMessage, setRequestErrorMessage,
    }: BasicApiRquestState = useBasicApiRequestState();

    const updateUserAttributes = (userToEdit: TenantUser, updates: Updates) => {
        if (!userToEdit) {
            console.error("User required to Edit User Attributes.");
        }
        setLoading(true);
        setRequestErrorMessage(null);
        setApiRequestSuccess(null);
        const payload: EditUserPayload = { userToEdit, updates };
        return axios.post(
            updateUserCreditsUrl,
            payload,
            { headers: {...baseHeaders} },
        ).then((res) => {
            setApiRequestSuccess(true);
        }).catch((err: Error) => {
            setRequestErrorMessage(err.message);
            setApiRequestSuccess(false);
        }).finally(() => {
            setLoading(false);
        });
    };

    return {
        loading,
        requestErrorMessage,
        requestApiSuccess,
        updateUserAttributes,
    }
};