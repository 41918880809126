import { Button } from '@benefitflow/designsystem';

import { useExportSelected } from './hooks/useExportSelected';
import { useIsDisabled } from './hooks';

export const ExportSelectedButton = () => {
	const { exportSelected, isExporting } = useExportSelected();
	const isDisabled = useIsDisabled();

	return (
		<Button variant="outlined" icon="download" disabled={isDisabled} fetching={isExporting} onClick={exportSelected}>
			Export Selected
		</Button>
	);
};
