import { useContext, useMemo, useState } from 'react';
import { Box, Paper, Dialog } from '@benefitflow/designsystem';

import { Add, Create } from './components';
import { useSelectDefaultTabWhenClosed } from './hooks';
import { ListActionUtils } from '../../../../../../../utilities';
import { AddToListButtonProps } from '../../AddToListButton.types';
import { ContactListHeaderContext } from '../../../../ContactListHeader.context';

export const AddToListDialog = ({
	isAddToListDialogOpen,
	setIsAddToListDialogOpen,
	useAddToExistingListMutation,
	useAddToNewListMutation,
}: {
	isAddToListDialogOpen: boolean;
	setIsAddToListDialogOpen: (isOpen: boolean) => void;
	useAddToExistingListMutation: AddToListButtonProps['useAddToExistingListMutation'];
	useAddToNewListMutation: AddToListButtonProps['useAddToNewListMutation'];
}) => {
	const {
		context: { contacts },
	} = useContext(ContactListHeaderContext);
	const [tab, setTab] = useState<'add' | 'create'>('add');
	const zIndex = useMemo(() => ListActionUtils.getDialogZIndex(contacts.length), [contacts]);
	useSelectDefaultTabWhenClosed({ isAddToListDialogOpen, setTab });

	return (
		<Dialog zIndex={zIndex} open={isAddToListDialogOpen}>
			<Box style={{ width: '25rem' }}>
				<Paper p="none">
					{tab === 'add' && (
						<Add useAddToExistingListMutation={useAddToExistingListMutation} setTab={setTab} setIsAddToListDialogOpen={setIsAddToListDialogOpen} />
					)}
					{tab === 'create' && <Create useAddToNewListMutation={useAddToNewListMutation} setIsAddToListDialogOpen={setIsAddToListDialogOpen} setTab={setTab} />}
				</Paper>
			</Box>
		</Dialog>
	);
};
