import { Card, Space, Typography } from '@benefitflow/designsystem';

import { ClearButton, ContactLocation, JobTitle, Role, YearsAtCompany, CompanyName, CarrierAppointments, LicenseType } from './components';
import { Section } from 'new-beginning/components/shared/SidebarFilters/components/Section/Section';
import { useShowVerifyProFilters } from './hooks';

export const ListDetails = () => {
	const showVerifyProFilters = useShowVerifyProFilters();

	return (
		<Card
			defaultPadding={false}
			title={
				<Space direction="horizontal" size="sm">
					<Typography.Text size="lg" weight="semi-bold" color="neutral-900">
						Filters
					</Typography.Text>
				</Space>
			}
			extra={<ClearButton />}
		>
			<Section title="Contact Attributes" />
			<CompanyName />
			<ContactLocation />
			<Role />
			<JobTitle />
			<YearsAtCompany />
			{showVerifyProFilters && <LicenseType />}
			{showVerifyProFilters && <CarrierAppointments />}
		</Card>
	);
};
