import cn from 'classnames';
import { FC } from 'react';

interface EmptyResultRowProps {
	message?: string;
	colSpan: number;
}
export const EmptyResultRow: FC<EmptyResultRowProps> = ({ message = 'No Contacts Found', colSpan }) => (
	<tr>
		<td colSpan={colSpan} className={cn('text-center')}>
			<div className={cn('d-flex', 'align-items-center', 'justify-content-center', 'py-3')}>
				<p className={cn('bf-txt', 'txt-xl', 'txt-bolder', 'txt-lighter')}>{message}</p>
			</div>
		</td>
	</tr>
);
