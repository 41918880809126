import { createContext } from 'react';
import { BrokerContactContext as BrokerContactContextType } from './BrokerContacts.types';

export const BrokerContactsContext = createContext({
	filterOptions: {
		data: {},
		loading: false,
	},
	totalNumberOfContacts: null,
	loading: false,
	fetching: false,
	fetchNextPage: () => {},
	contacts: [],
	numberOfSelectedContacts: 0,
	sidebarDimensions: { width: 0, height: 0 },
	hasNextPage: true,
} as BrokerContactContextType);
