import { Button } from '@benefitflow/designsystem';
import { useContext, useMemo, useState } from 'react';

import { AddToListDialog } from './components';
import { ListActionUtils } from '../../../../../utilities';
import { AddToListButtonProps } from './AddToListButton.types';
import { ContactListHeaderContext } from '../../ContactListHeader.context';

export const AddToListButton = ({ useAddToExistingListMutation, useAddToNewListMutation }: AddToListButtonProps) => {
	const {
		store: { checkMode, checkedMap },
	} = useContext(ContactListHeaderContext);
	const [isAddToListDialogOpen, setIsAddToListDialogOpen] = useState(false);
	const isDisabled = useMemo(() => ListActionUtils.isGenericActionDisabled({ checkMode, checkedMap }), [checkMode, checkedMap]);

	return (
		<>
			<Button variant="outlined" disabled={isDisabled} icon="add" onClick={() => setIsAddToListDialogOpen(true)}>
				Add To List
			</Button>
			<AddToListDialog
				useAddToExistingListMutation={useAddToExistingListMutation}
				useAddToNewListMutation={useAddToNewListMutation}
				isAddToListDialogOpen={isAddToListDialogOpen}
				setIsAddToListDialogOpen={setIsAddToListDialogOpen}
			/>
		</>
	);
};
