import { useMemo } from 'react';

import { ListActionUtils } from '../../../../../../../../utilities';
import { useContactsListStore } from '../../../../../ContactsList.store';

export const useIsDisabled = () => {
	const { checkMode, checkedMap } = useContactsListStore();
	const isDisabled = useMemo(() => ListActionUtils.isGenericActionDisabled({ checkedMap, checkMode }), [checkedMap, checkMode]);

	return isDisabled;
};
