import "./style.scss"
import React, { useMemo } from 'react';
import Skeleton from "@material-ui/lab/Skeleton";
import { throttle } from "lodash-es";
import { Table as MTable, TableBody, TableHead, TableCell, TableRow, TableContainer, Paper, withStyles } from "@material-ui/core";
import styled from 'styled-components';


const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundColor: "#F7F7F7"
    },
    border: "none"// "1px solid #e0e0e0"
  }
}))(TableRow);


interface TableColumnProps {
  name?: any;
  width?: string;
  cell?: (row: any, index: number) => any;
  selector?: (row: any) => any;
  noWrap?: boolean;
}

export interface TableProps {
  className?: any
  columns?: TableColumnProps[];
  rows?: any[],
  isLoading?: boolean,
  isInfiniteLoading?: boolean,
  totalRows?: number,
  searchInfinite: () => void,
  rowKey?: string,
  highlightedRowMap?: { [key: string]: boolean },
  containerStyle?: any,
}


export const Table = (props: TableProps) => {

  React.useEffect(() => {
    // Infinite Scroll
    const isBottom = el => {
      const scrollableHeight = el.scrollHeight;
      const scrolledHeight =
        document.documentElement.scrollTop + el.getBoundingClientRect().height;
      return scrolledHeight >= scrollableHeight * 0.95;
    };

    const trackScrolling = () => {
      const el = document.getElementsByTagName('body')[0];
      if (isBottom(el)) { 
        const notEndOfResults = props.rows?.length != props.totalRows;
        if (!props.isInfiniteLoading && notEndOfResults) {
          props.searchInfinite();
        }
      }
    };

    const debounced = throttle(trackScrolling, 200)

    document.addEventListener('scroll', debounced);

    return () => {
      document.removeEventListener('scroll', debounced);
    };
  }, [props.rows, props.isLoading, props.isInfiniteLoading]);

  const columns = useMemo(() => {
    return props?.columns?.slice().map(x => {
      if (x.cell) {
        const oldCell = x.cell;
        x.cell = (row, index) => {
          if (props.isInfiniteLoading && index == props?.rows?.length) {
            return (
              <Skeleton width="100%" height={40} />
            );
          } else {
            return oldCell(row, index);
          }
        }
      }
      return x;
    })
  }, [props.columns, props.isInfiniteLoading, props.rows])

  const rows = useMemo(() => {
    const internalRows = props.rows ? props.rows : [];
    if (props.isInfiniteLoading) {
      const cpy = internalRows.slice();
      cpy.push({});
      return cpy;
    }
    return internalRows;
  }, [props.isInfiniteLoading, props.rows])

  return (
    <div>
    {
      !props.isLoading && (!props.rows || props.rows.length == 0) ?
        <div style={{ textAlign: "center", fontSize: "24px", padding: "20px", backgroundColor: "white", borderRadius: "10px" }}>No Data</div> :
        props.isLoading ? 
          <div style={{ textAlign: "center", fontSize: "24px", padding: "20px", backgroundColor: "white", borderRadius: "10px" }}>Loading...</div> :
          <TableContainer component={Paper} style={props?.containerStyle}>
            <MTable  
              size="small" 
              aria-label="a dense table">
              <TableHead>
                <TableRow>
                  {
                    columns?.map((column, index) => {
                      return (
                        <TableCell width={column.width} align={"left"} key={index}><strong>{column.name}</strong></TableCell>
                      )
                    })
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, rowIndex) => { 
                  let style = {};
                  if (props.highlightedRowMap?.[row?.[props?.rowKey]]) {
                    style = { backgroundColor: "lightyellow" };
                  }
                  return (
                    <StyledTableRow
                      key={props.rowKey ? row[props.rowKey] : row.id}
                      // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      style={style}
                    >
                      {
                        columns?.map((column, index) => {
                          let contents: any = '';
                          if (column.cell) {
                            contents = column.cell(row, rowIndex);
                          } else if (column.selector) {
                            contents = column.selector(row)
                          }
                          return (
                            <TableCell align={"left"} key={index}>{
                              column.noWrap ? 
                                <CellDiv>{contents}</CellDiv> :
                                contents
                            }</TableCell>
                          )
                        })
                      }
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </MTable>
          </TableContainer>
    }
    </div>
  );
}

const CellDiv = styled.div`
  display: block;
  // width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;