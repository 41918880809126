import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useListDetailsFilterStore } from '../../../ListDetails.store';
import { listsDetailPageActions } from 'app/containers/ListsPage/containers/ListsDetailPage/slice'
import { getDefaultBrokerContactFilters } from 'common.model/src/types/common/search/filters/ContactFilters';

export const useOnClick = () => {
	const dispatch = useDispatch();
	const {
		collapsible: { clear },
	} = useListDetailsFilterStore();
	const onClick = useCallback(() => {
		dispatch(listsDetailPageActions.updateContactFilters(getDefaultBrokerContactFilters()));
		clear();
	}, [dispatch, listsDetailPageActions, clear]);

	return onClick;
};
