import { Box, Typography } from '@benefitflow/designsystem';
import { BenefitCategoryProps } from '../Benefit.types';

export const Title = ({ title, icon, iconBackgroundColor }: Pick<BenefitCategoryProps, 'title' | 'icon' | 'iconBackgroundColor'>) => {
	return (
		<Box display="flex" justifyContent="center" alignItems="center" gap="xs">
			<div
				style={{
					backgroundColor: iconBackgroundColor,
					borderRadius: '50%',
					padding: '0.5rem',
					width: '2rem',
					height: '2rem',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				{icon}
			</div>
			<Typography.Text weight="bold">{title}</Typography.Text>
		</Box>
	);
};
