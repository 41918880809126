
import "./style.scss"
import React from 'react';
import DataTable from 'react-data-table-component';
import { terciaryColor } from "designTokens/colors";

export interface ServiceProviderTableProps {
  className?: any
  columns?: any
  data?: any
}

export const ServiceProviderTable = ({ className, columns, data }: ServiceProviderTableProps) => {

  return (
    <div className={`dev-table ${className}`}>
      <DataTable
        columns={columns}
        data={data}
        noDataComponent={<div style={{ padding: "30px", color: terciaryColor }}>No Service Provider Info</div>}
      />
    </div>
  );
}