import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { QueryPath } from 'new-beginning/constants/path';
import { QueryKey } from 'new-beginning/constants/queryKey';
import {
	BenefitCategoryProps,
	EmployeeSentimentProps,
	InsightDirectionTypeEnum,
	InsightSentimentTypeEnum,
} from 'new-beginning/components/pages/EmployerProfile/Tabs/components/EmployeeSentimentTab/components/InsightsAcrossBenefits/components/Benefit/Benefit.types';
import { WithAuthenticatedContext } from 'new-beginning/context/AuthenticatedContext';

type BenefitInsightDTO = {
	comment: string;
	companies: string[];
};

type BenefitCategoryDTO = {
	section_name: string;
	insights: BenefitInsightDTO[];
	pros: BenefitInsightDTO[];
	cons: BenefitInsightDTO[];
};
type EmployeeSentimentInsightsDTO = {
	summary: string[];
	sections: BenefitCategoryDTO[];
};

type EmployeeSentimentDTO = {
	monthlySentiment: {
		employer_master_id: string;
		created_month: string;
		benefit_reviews_avg_monthly_rating: string;
		benefit_reviews_num_reviews: string;
	}[];
	insights: EmployeeSentimentInsightsDTO;
	benefitsRating: number;
	benefitsRatingVariation: number;
};

export interface EmployeeSentimentResponse extends EmployeeSentimentProps {
	loading: boolean;
}

const transform = (dto: EmployeeSentimentDTO): EmployeeSentimentProps => {
	const getType = (getYearlyVariation: number): InsightDirectionTypeEnum => {
		if (!getYearlyVariation) return null;
		if (getYearlyVariation > 0) return InsightDirectionTypeEnum.INCREASE as const;
		if (getYearlyVariation < 0) return InsightDirectionTypeEnum.DECREASE as const;

		return InsightDirectionTypeEnum.NEUTRAL as const;
	};

	const order = [
		'health_insurance',
		'voluntary_benefits',
		'health_and_wellness',
		'financial_security',
		'financial_perks',
		'family_and_caregiving',
		'professional_development',
		'work_life_balance',
	];

	const benefitTypeToInfo = {
		health_insurance: {
			title: 'Health Insurance',
			icon: '❤️‍🩹',
			iconBackgroundColor: '#FCEDEF',
		},
		financial_security: {
			title: 'Financial Security',
			icon: '👴',
			iconBackgroundColor: '#EDF2FC',
		},
		work_life_balance: {
			title: 'Work - Life Balance',
			icon: '🏝️',
			iconBackgroundColor: '#EBFBFF',
		},
		professional_development: {
			title: 'Professional Development',
			icon: '🎓',
			iconBackgroundColor: '#FEF3DE',
		},
		health_and_wellness: {
			title: 'Health and Wellness',
			icon: '🌿',
			iconBackgroundColor: '#DEF7ED',
		},
		financial_perks: {
			title: 'Financial Perks',
			icon: '💵',
			iconBackgroundColor: '#FEFBDE',
		},
		family_and_caregiving: {
			title: 'Family & Caregiving',
			icon: '👪',
			iconBackgroundColor: '#F0FADF',
		},
		voluntary_benefits: {
			title: 'Voluntary Benefits',
			icon: '🛡️',
			iconBackgroundColor: '#EBFBFF',
		},
	};

	const getBenefits = (benefits: BenefitCategoryDTO[]): BenefitCategoryProps[] => {
		return benefits
			?.filter(({ insights }) => insights.length)
			.sort((a, b) => order.indexOf(a.section_name) - order.indexOf(b.section_name))
			.map((benefit) => {
				const info = benefitTypeToInfo[benefit?.section_name];
				return {
					title: info?.title,
					icon: info?.icon,
					iconBackgroundColor: info?.iconBackgroundColor,
					insights: benefit?.insights
						.map((i) => ({ type: InsightSentimentTypeEnum.INFO, text: i.comment, boldWords: i.companies })) // TODO: Add companies bolding from companies list
						.concat(
							benefit?.pros.map((i) => ({ type: InsightSentimentTypeEnum.POSITIVE, text: i.comment, boldWords: i.companies })), // TODO: Add companies bolding from companies list
						)
						.concat(
							benefit?.cons.map((i) => ({ type: InsightSentimentTypeEnum.NEGATIVE, text: i.comment, boldWords: i.companies })), // TODO: Add companies bolding from companies list
						),
				};
			});
	};

	const nextEmployeeSentiment: EmployeeSentimentProps = {
		benefits: getBenefits(dto?.insights?.sections),
		evaluation: dto.benefitsRating,
		yearlyVariation: dto.benefitsRatingVariation,
		type: getType(dto.benefitsRatingVariation),
		summary: dto?.insights?.summary?.reduce((acc, curr) => acc + ' ' + curr, ''),
	};

	return nextEmployeeSentiment;
};

export const useEmployeeSentiment = (): EmployeeSentimentResponse => {
	const { httpClient } = useContext(WithAuthenticatedContext);
	const { employer_master_id, employerId } = useParams();

	const { data, isLoading } = useQuery({
		queryKey: [QueryKey.EmployeeSentiment, employer_master_id || employerId],
		queryFn: () =>
			httpClient.post<{ res: { data: EmployeeSentimentDTO } }>(QueryPath.EmployeeSentiment, { employer_master_id: employer_master_id || employerId }),
		select: (response) => transform(response?.data?.res?.data),
		refetchInterval: Infinity,
		refetchOnWindowFocus: true,
	});

	return {
		...data,
		loading: isLoading,
	};
};
