import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { useEmployerProfileStore } from '../../EmployerProfile.store';
import { employerPageActions } from 'app/containers/EmployerPage/slice';
import { useIsV2 } from '../../hooks/useIsV2';

export const useOnBenefitsRatingClick = () => {
	const isV2 = useIsV2();
	const dispatch = useDispatch();
	const { setCurrentTabTo } = useEmployerProfileStore();
	const onBenefitsRatingClick = useCallback(() => setCurrentTabTo.employeeSentiment(), [setCurrentTabTo]);
	const onLegacyBenefitsRatingClick = useCallback(() => dispatch(employerPageActions.setSelectedTab(2)), [dispatch]);
	const callback = useMemo(() => (isV2 ? onBenefitsRatingClick : onLegacyBenefitsRatingClick), [isV2, onBenefitsRatingClick, onLegacyBenefitsRatingClick]);

	return callback;
};
