import { BrokerFilters } from 'common.model/src/types/common/search/filters/CompanyFilters';
import { ApiBase, IApiResponse } from '../ApiBase';
import { APIClient } from '../types';
import {
	GetEmployerPoliciesRequest,
	GetHistoricalEmployerPoliciesRequest,
	Policy,
	SearchBrokerHqRequest,
	SearchCarriersRequest,
} from 'common.model/src/types/services/broker-service/BrokerService';
import { IBrokerClient } from 'common.model/src/types/services/broker-service/IBrokerClient';
import { BrokerSearchResult } from 'common.model/src/types/common/search/BrokerSearchResult';
import { EntityLocation } from 'common.model/src/types/master/location';
import { BreakdownResponse } from 'common.model/src/types/common/search/Breakdowns';
import { BrokerOfficeResult } from 'common.services/src/search/BrokerElasticSearchCache';
import { QueryObserverOptions, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

export interface IBrokerApi {
	useSearch: (req: BrokerFilters) => Promise<BrokerSearchResult[]>;
	useSearchEmployerHq: (req: SearchBrokerHqRequest) => IApiResponse<EntityLocation[]>;
	useSearchBrokerOffices: (req: BrokerFilters) => IApiResponse<EntityLocation[]>;
	useSearchCarriers: (req: SearchCarriersRequest) => IApiResponse<string[]>;
	useSearchResultsBreakdown: (req: BrokerFilters) => IApiResponse<BreakdownResponse>;
	useGetEmployerPolicies: (req: GetEmployerPoliciesRequest, options?: QueryObserverOptions<Policy[]>) => IApiResponse<Policy[]>;
	useGetHistoricalEmployerPolicies: (req: GetHistoricalEmployerPoliciesRequest) => IApiResponse<Policy[]>;
	useGetBrokerOffices: <TParsed = BrokerOfficeResult[]>(
		req: BrokerFilters,
		options?: UseQueryOptions<BrokerOfficeResult[], unknown, TParsed>,
	) => UseQueryResult<TParsed, unknown>;
	useGetBrokerEmployers: <TParsed = IBrokerClient[]>(
		req: BrokerFilters,
		options: UseQueryOptions<IBrokerClient[], unknown, TParsed>,
	) => UseQueryResult<TParsed, unknown>;
	useGetBrokerEmployersBreakDown: (req: BrokerFilters) => IApiResponse<BreakdownResponse>;
}

export class BrokerApi extends ApiBase implements IBrokerApi {
	constructor(
		readonly client: APIClient,
		loggedInUser: string,
	) {
		super(client, loggedInUser, 'broker');
	}

	useSearch = (req: BrokerFilters): Promise<BrokerSearchResult[]> => this.post('search', req);

	useSearchEmployerHq = (req: SearchBrokerHqRequest): IApiResponse<EntityLocation[]> => this.callAuthenticated('searchEmployerHq', req);

	useSearchBrokerOffices = (req: BrokerFilters): IApiResponse<EntityLocation[]> => this.callAuthenticated('searchBrokerOffices', req);

	useSearchCarriers = (req: SearchCarriersRequest): IApiResponse<string[]> => this.callAuthenticated('searchCarriers', req);

	useSearchResultsBreakdown = (req: BrokerFilters): IApiResponse<BreakdownResponse> => this.callAuthenticated('searchResultsBreakdown', req);

	useGetEmployerPolicies = (req: GetEmployerPoliciesRequest, options?: QueryObserverOptions<Policy[]>): IApiResponse<Policy[]> =>
		this.callAuthenticated('getEmployerPolicies', req, options);

	useGetHistoricalEmployerPolicies = (req: GetHistoricalEmployerPoliciesRequest): IApiResponse<Policy[]> =>
		this.callAuthenticated('getHistoricalEmployerPolicies', req);

	useGetBrokerOffices = <TParsed = BrokerOfficeResult[]>(req: BrokerFilters, options?: UseQueryOptions<BrokerOfficeResult[], unknown, TParsed>) =>
		this.query('getBrokerOffices', req, options);

	useGetBrokerEmployers = <TParsed = IBrokerClient[]>(req: BrokerFilters, options: UseQueryOptions<IBrokerClient[], unknown, TParsed>) =>
		this.query('getBrokerEmployers', req, options);

	useGetBrokerEmployersBreakDown = (req: BrokerFilters): IApiResponse<BreakdownResponse> => this.callAuthenticated('getBrokerEmployersBreakDown', req);
}
