import { Avatar, Box, Button, Description, Icon, Skeleton, Suspense, Typography } from '@benefitflow/designsystem';
import { useOnBenefitsRatingClick, useOnContactsClick, useSummary } from './hooks';
import { BenefitsRating, Broker, NumberOfContacts } from './components';
import { useGetValueByScreenSizes } from 'new-beginning/hooks/useGetValueByScreenSizes';
import { useIsV2 } from '../hooks/useIsV2';

export const Summary = () => {
	const isV2 = useIsV2();
	const {
		loading,
		broker,
		renewalDate,
		numberOfContacts,
		imageSrcs,
		totalEmployees,
		fundingType,
		description,
		name,
		linkedinUrl,
		websiteUrl,
		address,
		benefitsRating,
	} = useSummary();
	const onBenefitsRatingClick = useOnBenefitsRatingClick();
	const onContactsClick = useOnContactsClick();
	const getValueByScreenSizes = useGetValueByScreenSizes();

	return (
		<Box gap="md" style={{ borderBottom: '0.5px solid #CBCDD1' }} backgroundColor="white" display="grid">
			<Box
				gridSpan={getValueByScreenSizes({ sm: 12, md: 12, lg: 6 })}
				display="flex"
				width="full"
				gap="lg"
				m="md"
				flexDirection={getValueByScreenSizes({ lg: 'row', md: 'row', sm: 'column' })}
			>
				<Avatar.Multiple loading={loading} srcs={imageSrcs} variant="square" fallbackIcon="business" size="extra-large" />
				<Box mr="xl" display="flex" width="full" flexDirection="column" justifyContent="center" alignItems="start">
					<Suspense loading={loading} fallback={<Skeleton height="md" width="xl6" />}>
						<Box
							display="flex"
							width="full"
							justifyContent="between"
							alignItems="center"
							gap="xs"
							flexDirection={getValueByScreenSizes({ lg: 'row', md: 'row', sm: 'column' })}
						>
							<Typography.Display size="md" whiteSpace={getValueByScreenSizes({ lg: 'nowrap', md: 'normal', sm: 'normal' })}>
								{name}
							</Typography.Display>
							<Box display="flex" gap="sm">
								{linkedinUrl && (
									<a href={`https://${linkedinUrl}`} style={{ color: '#007EBB' }} target="_blank" rel="noopener noreferrer">
										<Button variant="outlined">
											<div style={{ color: '#007EBB' }}>
												<Icon name="linkedin" size="md" />
											</div>
										</Button>
									</a>
								)}
								{websiteUrl && (
									<a href={`https://${websiteUrl}`} target="_blank" rel="noopener noreferrer">
										<Button variant="outlined">
											<div style={{ rotate: '-45deg' }}>
												<Icon name="link" size="md" color="primary-500" />
											</div>
										</Button>
									</a>
								)}
							</Box>
						</Box>
					</Suspense>
					<Box mt="xxs" width="full" style={{ gridTemplateColumns: 'repeat(12,1fr)' }} backgroundColor="white" gap="md" display="grid">
						<Box display="flex" gap="xs" flexDirection="column" justifyContent="start" alignItems="start" gridSpan={6}>
							<Suspense loading={loading} fallback={<Skeleton height="md" width="xl5" />}>
								{address && (
									<Typography.Text color="neutral-700" weight="regular">
										{address}
									</Typography.Text>
								)}
							</Suspense>
							<Suspense loading={loading} fallback={<Skeleton height="md" width="xl7" />}>
								<Typography.Text color="neutral-500" weight="regular">
									{description}
								</Typography.Text>
							</Suspense>
							<Suspense loading={loading} fallback={<Skeleton height="md" width="xl5" />}>
								<Box display="flex" gap="xs">
									{isV2 && (
										<>
											<NumberOfContacts value={numberOfContacts} onClick={onContactsClick} />
											{!!numberOfContacts && !!benefitsRating && (
												<Typography.Text weight="regular" color="neutral-700">
													•
												</Typography.Text>
											)}
										</>
									)}
									<BenefitsRating value={benefitsRating} onClick={onBenefitsRatingClick} />
								</Box>
							</Suspense>
						</Box>
						<Box justifyContent="start" gridSpan={getValueByScreenSizes({ sm: 12, md: 12, lg: 6 })} display="flex" gap="xs" alignItems="start">
							<Box display="grid" style={{ gridTemplateColumns: 'repeat(12,1fr)' }} gap="xs">
								<Box gridSpan={getValueByScreenSizes({ lg: 6, md: 12, sm: 12 })}>
									<Suspense loading={loading} fallback={<Skeleton height="sm" width="xl4" />}>
										{renewalDate && <Description size="lg" icon="calendar-check" value={renewalDate} label="Renewal" />}
									</Suspense>
								</Box>
								<Box gridSpan={getValueByScreenSizes({ lg: 6, md: 12, sm: 12 })}>
									<Suspense loading={loading} fallback={<Skeleton height="sm" width="xl4" />}>
										{!!totalEmployees && <Description size="lg" icon="users" value={totalEmployees} label="Employees" />}
									</Suspense>
								</Box>
								<Box gridSpan={getValueByScreenSizes({ lg: 6, md: 12, sm: 12 })}>
									<Suspense loading={loading} fallback={<Skeleton height="sm" width="xl4" />}>
										{fundingType && <Description size="lg" icon="bills" value={fundingType} />}
									</Suspense>
								</Box>
								<Box gridSpan={getValueByScreenSizes({ lg: 6, md: 12, sm: 12 })}>
									<Suspense loading={loading} fallback={<Skeleton height="sm" width="xl4" />}>
										{broker && <Broker broker={broker} />}
									</Suspense>
								</Box>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};
