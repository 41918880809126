import { useMemo } from 'react';

import { ContactDTO } from '../../../../../../../queries';
import { ListActionUtils } from '../../../../../../../utilities';

export const useIsDisabled = ({
	contacts,
	checkMode,
	checkedMap,
}: {
	contacts: ContactDTO[];
	checkMode: 'single-select' | 'select-all';
	checkedMap: Record<string, boolean>;
}) => {
	const isDisabled = useMemo(
		() =>
			ListActionUtils.isGetSelectedButtonDisabled({
				checkedMap,
				checkMode,
				contacts,
			}),
		[checkMode, checkedMap, contacts],
	);

	return isDisabled;
};
