import { ReactNode } from 'react';
import { Box } from '@benefitflow/designsystem';

import { ContactListHeaderContext } from './ContactListHeader.context';
import { useGetValueByScreenSizes } from '../../../hooks/useGetValueByScreenSizes';
import { GetSelectedButton, RecordsCounter, AddToListButton, ExportButton } from './components';
import { ContactListHeaderContext as ContactListHeaderContextType } from './ContactListHeader.types';

export const ContactListHeader = ({
	children,
	...contextValue
}: {
	children: ReactNode;
} & ContactListHeaderContextType) => {
	const getValuesByScreenSize = useGetValueByScreenSizes();

	return (
		<ContactListHeaderContext.Provider value={contextValue}>
			<Box
				mb="sm"
				gap="sm"
				display="flex"
				justifyContent="between"
				alignItems="center"
				flexDirection={getValuesByScreenSize({ lg: 'row', md: 'column', sm: 'column' })}
			>
				<RecordsCounter />
				<Box display="flex" gap="sm">
					{children}
				</Box>
			</Box>
		</ContactListHeaderContext.Provider>
	);
};

ContactListHeader.GetSelectButton = GetSelectedButton;
ContactListHeader.AddToListButton = AddToListButton;
ContactListHeader.ExportButton = ExportButton;
