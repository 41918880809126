import React, { useMemo } from 'react';
import isEmpty from 'lodash-es/isEmpty';
import RangePicker from '../RangePicker';
import { FormattingUtils } from "utils/index";

interface IProps {
  disabled?: boolean;
  values: number[];
  onChange: (e: any) => void;
}

const MIN_POSITION = 0;
const MAX_POSITION = 10;

const checkPoints = [
  0,
  100,
  200,
  300,
  500,
  1000,
  2000,
  3000,
  5000,
  10000,
  3000000,
];

const PlanParticipantsRangePicker = ({
  disabled,
  values,
  onChange,
}: IProps) => {
  const innerValues = useMemo(() => {
    if (!isEmpty(values)) {
      const innerVal = [
        checkPoints.findIndex(checkPoint => checkPoint === values[0]),
        checkPoints.findIndex(checkPoint => checkPoint === values[1]),
      ];
      return innerVal;
    }

    return [];
  }, [values]);

  const onChangeValues = (e: number[]) =>
    onChange((e || []).map(v => checkPoints[v]));

  const valueFormatter = (v: number) =>
    checkPoints[v] > 10000
      ? `${FormattingUtils.formatNumberAbbrev(10000)}+`
      : checkPoints[v] >= 1000
      ? FormattingUtils.formatNumberAbbrev(checkPoints[v], 0, false)
      : checkPoints[v];

  return (
    <RangePicker
      step={1}
      disabled={disabled}
      min={MIN_POSITION}
      max={MAX_POSITION}
      values={innerValues}
      onChange={onChangeValues}
      valueFormatter={valueFormatter}
    />
  );
};

export default PlanParticipantsRangePicker;
