import { IconProps } from '@benefitflow/designsystem/dist/components/Icon/Icon.types';

export const getRatingColor = (
	/**
	 * Number from `0` to `5`
	 */
	rating: number,
): IconProps['color'] => {
	const colors = ['red-500', 'orange-500', 'yellow-500', 'lime-500', 'green-500'] as const;
	if (rating <= 1) return colors[0];
	if (rating <= 2) return colors[1];
	if (rating <= 3) return colors[2];
	if (rating <= 4) return colors[3];
	return colors[4];
};
