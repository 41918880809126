import { useMemo } from 'react';
import { useEmployeeSentiment } from 'new-beginning/components/pages/EmployerProfile/Tabs/components/EmployeeSentimentTab/hooks';

export const useBackgroundColor = (type: ReturnType<typeof useEmployeeSentiment>['type']) => {
	const backgroundColor = useMemo(() => {
		if (type === 'increase') return 'success-100';
		if (type === 'decrease') return 'danger-100';

		return 'primary-100';
	}, [type]);

	return backgroundColor;
};
