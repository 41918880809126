import { useState, useEffect } from 'react';
import serviceUrl from 'utils/serviceUrl';
import axios from 'axios';
import { useBasicApiRequestState, BasicApiRquestState, BaseApiReponse } from './useBasicApiRequestState';
import { ObjectMappingByRuleType, ObjectMappingTargetType } from 'common.model/src/db/model-decorators/type-extensions';

type SalesforceMappingsCache = Record<ObjectMappingTargetType, ObjectMappingByRuleType>;

export interface SalesForceMappingResponse extends BaseApiReponse {
	fieldMappings: SalesforceMappingsCache;
	clearCache: () => void;
}

const fieldMappingsUrl = `${serviceUrl}/crm-integration/getCrmObjectFieldMappings`;
export const useSalesforceFieldMappings = (mappingType: ObjectMappingTargetType): SalesForceMappingResponse => {
	const {
		baseHeaders,
		loading,
		tokenLoading,
		setLoading,
		requestApiSuccess,
		setApiRequestSuccess,
		requestErrorMessage,
		setRequestErrorMessage,
	}: BasicApiRquestState = useBasicApiRequestState();
	const [fieldMappings, setFieldMappings] = useState<SalesforceMappingsCache>({} as SalesforceMappingsCache);
	const setCacheKey = (objecType, data) =>
		setFieldMappings(
			Object.assign({}, fieldMappings, {
				[objecType]: data,
			})
		);

	const fetchFieldMappings = () => {
		setLoading(true);
		return axios.get(
				`${fieldMappingsUrl}/${mappingType}`,
				{ headers: { ...baseHeaders } }
			)
			.then((res) => {
				setApiRequestSuccess(true);
				setCacheKey(
					mappingType,
					res?.data?.res?.data || []
				);
			})
			.catch((err: Error) => {
				setRequestErrorMessage(err.message);
				setApiRequestSuccess(false);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const clearFieldMapping = () => {
		setFieldMappings({} as SalesforceMappingsCache);
		fetchFieldMappings();	
	};

	useEffect(() => {
		if (baseHeaders?.Authorization && !fieldMappings?.[mappingType]) {
			fetchFieldMappings();
		} else if (!tokenLoading && !fieldMappings?.[mappingType]) {
			console.warn('Cannot Fetch Salesforce Field Mappings without Token');
		}
	}, [mappingType, baseHeaders?.Authorization]);

	return {
		fieldMappings,
		clearCache: clearFieldMapping,
		loading,
		requestApiSuccess,
		requestErrorMessage,
	};
};
