import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
  }

  html {
    background-color: #F9FAFA;
  }

  body {
    // font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  #root {
    height: 100%;
    width: 100%;
    min-height: 100%;
    min-width: 100%;
  }

  p,
  label {
    // font-family: Georgia, Times, 'Times New Roman', serif;
    line-height: 1.5em;
  }

  input, select {
    font-family: inherit;
    font-size: inherit;
  }

  // Joy Ride
  .__floater__open {
    z-index: 10001 !important;
  }

  .react-joyride__overlay {
    z-index: 10000 !important;
  }

  // SplitPane (in ProviderMapping)
  [data-type="SplitPane"] {
    height: 100vh !important;
    width: 100% !important;
    position: absolute !important;
    top: 0;
    left: 0;
  }

  [data-type="SplitPane"] > div:nth-of-type(3) {
    overflow: scroll !important;
  }

  .react-multiple-carousel__arrow {
    background-color: rgba(0,0,0,0.1) !important;
  }

  .react-multiple-carousel__arrow:hover {
    background-color: rgba(0,0,0,0.5) !important;
  }

  .react-multi-carousel-item {
    // max-width: 377px !important;
    // min-width: 377px !important;
  }

  .membership-trend   {
    height: 390px !important;
  }

  #search-bar, .MuiIconButton-root:hover {
    background-color: transparent !important;
  }

  #map {
    grid-column: 1/-1;
    height: 100% !important;
    position: relative;
    canvas, .mapboxgl-canvas {
      height: 100% !important;
    }
  }

`;
