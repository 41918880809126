import { useCallback } from 'react';
import { useScreenSize } from './useScreenSize';

export const useGetValueByScreenSizes = () => {
	const size = useScreenSize();

	const getValueByScreenSizes = useCallback(
		<T>({ sm, lg, md }: Record<'sm' | 'md' | 'lg', T>) => {
			if (size === 'sm') return sm;
			if (size === 'md') return md;
			if (size === 'lg') return lg;
		},
		[size],
	);

	return getValueByScreenSizes;
};
