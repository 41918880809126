import { Box, Icon, Typography } from '@benefitflow/designsystem';
import { useMemo, useState } from 'react';
import { getRatingColor } from '../../helpers';

export const BenefitsRating = ({ onClick, value }: { value: string; onClick: () => void }) => {
	const iconColor = useMemo(() => getRatingColor(Number(value)), [value]);
	const [hovered, setHovered] = useState(false);
	if (!value) return null;

	return (
		<Box display="flex" justifyContent="center" alignItems="center" gap="xxs">
			<Typography.Text whiteSpace="nowrap" weight="regular" color="neutral-700">
				{value}
			</Typography.Text>
			<Icon color={iconColor} name="favorite" size="md" />
			<Typography.Text
				onMouseEnter={() => setHovered(true)}
				onMouseLeave={() => setHovered(false)}
				decoration={hovered ? 'underline' : undefined}
				onClick={onClick}
				style={{ cursor: 'pointer' }}
				weight="regular"
				color="primary-500"
			>
				Benefits Rating
			</Typography.Text>
		</Box>
	);
};
