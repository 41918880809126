// @ts-nocheck
import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import debounce from 'lodash-es/debounce';
import { greyColor, greyLightColor } from 'designTokens/colors';
import { DropDownRangeSelector } from 'atoms/filters/dropdownrange/DropDownRangeSelector';

interface Props {
  values?: number[];
  disabled?: boolean;
  onChange?: any;
}

const TrackDiv = styled.div`
  height: 20px;
  display: flex;
  width: 100%;
  padding: 0px 5px;
`;

const TrackInnerDiv = styled.div<{ background: string }>`
  height: 5px;
  width: 100%;
  border-radius: 4px;
  align-self: center;
  background: ${props => props.background};
`;

const ThumbDiv = styled.div<{ disabled: boolean }>`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: ${props => (props.disabled ? greyLightColor : greyColor)};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ThumbInnerDiv = styled.div<{ left: string; right: string }>`
  position: absolute;
  top: 100%;
  left: ${props => props.left};
  right: ${props => props.right};
  color: #b7c2cf;
  font-size: 10px;
  margin-top: 7px;
  white-space: nowrap;
`;

const DateRangePicker = ({ values, disabled, onChange }: Props) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const step = 1;
  const min = 1;
  const max = 12;

  const [innerValues, setInnerValues] = useState([min, max]);

  const debounceFn: any =  useRef();

  useEffect(() => {
    setInnerValues(values);
  }, [values]);

  const updateValues = useCallback(
    (v: any) => {
      if (!disabled) {
        onChange(v);
      }
    },
    [disabled, onChange],
  );

  const onChangeValue = useCallback(
    (v: any) => {
      setInnerValues(v);

      if (!debounceFn || !debounceFn.current) { 
        debounceFn.current = debounce(v => updateValues(v), 1000);
      }

      if (debounceFn.current) {
        (debounceFn.current as any)(v);
      }
    },
    [updateValues],
  );

  return (
    <DropDownRangeSelector
      spacerLabel='to'
      lower={{
        title: 'From',
        value: { value: innerValues[0], formattedValue: months[innerValues[0] - 1] },
        options: months.map((m, i) => ({ option: i + 1, formattedOption: m })),
        onValueChange: newValue => onChangeValue([newValue, innerValues[1]]),
      }}
      upper={{
        title: 'To',
        value: { value: innerValues[1], formattedValue: months[innerValues[1] - 1] },
        options: months.map((m, i) => ({ option: i + 1, formattedOption: m })),
        onValueChange: newValue => onChangeValue([innerValues[0], newValue]),
      }}
    />
  );
};

export default DateRangePicker;
