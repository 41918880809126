import { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import moment, { Moment } from 'moment';
import { WithNotificationContext } from 'new-beginning/context/NotificationContext';
import { SyncNotification } from './SubComponents/SyncNotification';
import { SyncHeader } from 'new-beginning/components/pages/ListSync/SyncListHeader';
import { TabHeader } from 'new-beginning/components/shared/Tabs';
import { ListContactContainer } from 'new-beginning/components/pages/ListSync/ListContactContainer';
import { ContactListFilter } from 'common.model/src/db/model-decorators/type-extensions';
import { useParams, useSearchParams } from 'react-router-dom';
import { useListDetails } from 'new-beginning/hooks/salesforce/useListInfo';
import { useRefreshListItems } from 'new-beginning/hooks/salesforce/useRefreshListItems';
import { useSyncListContacts } from 'new-beginning/hooks/salesforce/useSyncListContacts';
import { useSyncModalConfig } from 'new-beginning/hooks/salesforce/useSyncModalActions';
import { useFetchContactOwners } from 'new-beginning/hooks/salesforce/useContactOwner';
import { AccountResolveBaseModal, AssignContactOwnerModal, ListJobLoader } from 'new-beginning/components/pages/ListSync/SubComponents';

const tabSet: ContactListFilter[] = Object.values(ContactListFilter);
const defaultContactFilter = ContactListFilter.ALL;
type SyncStateMap = Record<ContactListFilter, boolean>;

interface SalesforceSyncPageProps {
	isAdmin: boolean;
}

export const SalesforceSyncContainer: FC<SalesforceSyncPageProps> = ({ isAdmin }) => {
	const { listId } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const [autoRefreshAttempts, setAutoRefreshAttempts] = useState<number>(0);

	const { listDetails, refreshListDetails, loading: detailsLoading } = useListDetails(listId);
	const { generateRefreshItems, loading: refreshLoading } = useRefreshListItems(listId, refreshListDetails);
	const { syncListContacts, contactSyncResult, loading: syncLoading } = useSyncListContacts(listId);

	const listJobActionLoading = refreshLoading || syncLoading;
	const syncInProgress = listDetails?.syncJobStatus?.syncInProgress || false;
	const refreshJobId = listDetails?.syncJobStatus?.parentJob?.id;

	const { salesforceUsers, salesforceUsersById, loading: usersLoading } = useFetchContactOwners(listId);
	const { modalConfig, fetchAccountsRequiringParent, accountResolveInstance, contactRefetchTrigger, triggerContactRefetch } = useSyncModalConfig(syncInProgress);

	const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>({});
	const [allContactSelected, setAllContactSelected] = useState<boolean>(false);
	const resetContactSelection = () => {
		setAllContactSelected(false);
		setSelectedRows({});
	};

	const contactsSelected = allContactSelected ? { ALL: true } : selectedRows;

	const setContactTab = (contactFilter: ContactListFilter) => {
		searchParams.set('contactFilter', contactFilter);
		resetContactSelection();
		setSearchParams(searchParams);
	};
	const contactFilter = searchParams.get('contactFilter') as ContactListFilter;

	// Automatically switch the Query Param in the URL if it's Unset or if the Current Tab is Disabled
	useEffect(() => {
		const missingContactFilter = !contactFilter;
		const switchContactFilter = !syncInProgress && !listJobActionLoading && missingContactFilter;
		if (switchContactFilter) {
			setContactTab(defaultContactFilter);
		}
	}, [contactFilter, listJobActionLoading, syncInProgress, refreshJobId]);

	// Auto-Refresh the List if latestRefresh >2 days ago or if the List has never been Refreshed
	useEffect(() => {
		const listLoading = listJobActionLoading || detailsLoading;
		const lastRefreshDate = listDetails?.syncJobStatus?.parentJob?.createdAt;
		const listNeverRefreshed = !!listDetails && !lastRefreshDate && !listLoading;

		const now: Moment = moment();
		const timeSinceRefresh = moment.duration(now.diff(moment(lastRefreshDate)))?.asHours();
		const latestRefreshStale = !!lastRefreshDate && timeSinceRefresh >= 48;

		if ((listNeverRefreshed || latestRefreshStale) && !listLoading && autoRefreshAttempts <= 2) {
			setAutoRefreshAttempts(autoRefreshAttempts + 1);
			generateRefreshItems();
		}

	}, [listDetails, detailsLoading, listJobActionLoading]);

	const syncContactsCallback = async () => {
		try {
			await refreshListDetails();
		} catch (err) {
			console.error('Failed to refetch List Details after Sync: ', err);
		} finally {
			resetContactSelection();
		}
	};

	const accountDiffIdsToFetch = contactSyncResult?.accountDiffIdsToAssign;
	useEffect(() => {
		const fetchAccountAssign = async () => {
			const callback = () => setTimeout(modalConfig.assignAccountInsertParent?.toggleModal, 1250);
			if (syncInProgress && accountDiffIdsToFetch?.length) {
				await fetchAccountsRequiringParent(accountDiffIdsToFetch, callback);
			}
		};

		fetchAccountAssign();
	}, [accountDiffIdsToFetch]);

	const setAccountCallback = async () => {
		try {
			await refreshListDetails();
			await fetchAccountsRequiringParent(accountDiffIdsToFetch);
		} catch(err) {
			console.error('Failure encountered in Assign Parent Account Callback: ', err);
		}
		finally {
			triggerContactRefetch();
		}
	};

	// New and Update Tabs are only logically relevant after a RefreshJob has been run.
	// 		-> Otherwise, a Contact's BF vs Salesforce state (new, updated) is Unknowable
	const tabsEnabled: SyncStateMap = {
		[ContactListFilter.ALL]: true,
		[ContactListFilter.NEW]: syncInProgress,
		[ContactListFilter.UPDATES]: syncInProgress,
		[ContactListFilter.SYNCED]: true,
	};

	return (
		<>
			<div className={cn('mx-2', 'pb-2')}>
				<SyncHeader
					generateRefreshItems={generateRefreshItems}
					jobLoading={listJobActionLoading}
					listDetails={listDetails}
					detailsLoading={detailsLoading}
					isAdmin={isAdmin}
				>
				<TabHeader
					tabList={tabSet}
					tabCounts={listDetails?.contactCountsByType}
					currentTab={contactFilter}
					setCurrentTab={setContactTab}
					tabEnabledState={tabsEnabled}
				/>
				</SyncHeader>
				<div className={cn('px-1')}>
					<ListContactContainer
						syncListContacts={(refetchCallback: () => Promise<void>, singleSyncOverride: Record<string, boolean> = null) => {
							syncListContacts(contactFilter, singleSyncOverride || contactsSelected, syncContactsCallback).finally(
								() => refetchCallback && refetchCallback()
							);
						}}
						contactRefetchTrigger={contactRefetchTrigger}
						listJobLoading={listJobActionLoading}
						listDetails={listDetails}
						salesforceUsersById={salesforceUsersById}
						selectedRows={selectedRows}
						setSelectedRows={setSelectedRows}
						allContactSelected={allContactSelected}
						setAllContactSelected={setAllContactSelected}
						modalConfig={modalConfig}
						isAdmin={isAdmin}
						currentTab={contactFilter}
					/>
				</div>
			</div>
			<SyncNotification />
			<ListJobLoader
				contactFilter={contactFilter}
				contactsSelected={contactsSelected}
				jobLoading={listJobActionLoading}
				actionType={listJobActionLoading ? (syncLoading ? 'SYNC' : null) || (refreshLoading ? 'REFRESH' : null) : null}
			/>
			<AccountResolveBaseModal
				childAccountType='UPDATE'
				accountReassignSet={accountResolveInstance?.existingAccounts}
				isVisible={modalConfig?.resolveOrphanedAccounts?.isOpen}
				cancelAction={modalConfig?.resolveOrphanedAccounts?.toggleModal}
				assignAccountCallback={setAccountCallback}
			/>
			<AccountResolveBaseModal
				childAccountType='INSERT'
				accountReassignSet={accountResolveInstance?.accountInserts}
				isVisible={modalConfig?.assignAccountInsertParent?.isOpen}
				cancelAction={modalConfig?.assignAccountInsertParent?.toggleModal}
				assignAccountCallback={setAccountCallback}
			/>
			<AssignContactOwnerModal
				salesforceUsers={salesforceUsers}
				usersLoading={usersLoading}
				isVisible={modalConfig?.assignContactOwner?.isOpen}
				cancelAction={modalConfig?.assignContactOwner?.toggleModal}
				allContactsSelected={allContactSelected}
				selectedContacts={selectedRows}
				triggerContactRefetch={triggerContactRefetch}
			/>
		</>
	);
};


export const SalesforceSyncPage: FC<SalesforceSyncPageProps> = ({ isAdmin }) => {
	return (
		<WithNotificationContext>
			<SalesforceSyncContainer isAdmin={isAdmin} />
		</WithNotificationContext>
	);
}
