import { create } from 'zustand';

import { ContactsListStore, Tabs } from './ContactsList.types';
import { StoreUtils } from '../../../utilities';

export const useContactsListStore = create<ContactsListStore>((set) => ({
	...StoreUtils.createContactSelectorMethods(set),
	tab: Tabs.BROKER_CONTACTS,
	setTab: (tab: `${Tabs}`) => set({ tab }),
	setState: (state) => set(state),
}));
