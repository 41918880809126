import { useCallback, useMemo, useState } from 'react';

import { RADIUS_SEARCH_DEFAULT } from 'app/constants';
import { LocationFilterProps } from '../LocationFilter';
import { LocationType } from 'common.model/src/types/master/location';

export const useRadiusSearch = ({ setRadiusMiles, selectedOptions }: Pick<LocationFilterProps, 'setRadiusMiles' | 'selectedOptions'>) => {
	const [radius, setRadius] = useState<number>(RADIUS_SEARCH_DEFAULT);
	const onSelectRadius = useCallback(
		(rawRadius: string) => {
			const newRadius = parseInt(rawRadius);
			setRadius(newRadius);
			setRadiusMiles(newRadius);
		},
		[setRadius, setRadiusMiles],
	);
	const shouldShowRadiusSearch = useMemo(
		() =>
			(selectedOptions?.include?.length > 0 && selectedOptions?.include?.some((location) => location.location.locationType === LocationType.Zip)) ||
			(selectedOptions?.exclude?.length > 0 && selectedOptions?.exclude?.some((location) => location.location.locationType === LocationType.Zip)),
		[selectedOptions],
	);

	return {
		shouldShowRadiusSearch,
		radius,
		onSelectRadius,
	};
};
