import { Box, Icon, Typography } from '@benefitflow/designsystem';
import { InsightProps as InsightProps } from '../../Benefit.types';
import { useFormattedText, useIconProps } from './hooks';

export const Insight = ({ text, boldWords, type }: InsightProps) => {
	const { color, name, backgroundColor } = useIconProps(type);
	const formattedText = useFormattedText({ text, boldWords });

	return (
		<Box display="flex" justifyContent="start" gap="sm">
			<Box
				height="xl"
				minHeight="xl"
				minWidth="xl"
				width="xl"
				display="flex"
				justifyContent="center"
				alignItems="center"
				backgroundColor={backgroundColor}
				borderRadius="round"
			>
				<Icon name={name} color={color} size="md" />
			</Box>
			<Typography.Text color="neutral-600" weight="regular">
				<div dangerouslySetInnerHTML={{ __html: formattedText }} />
			</Typography.Text>
		</Box>
	);
};
