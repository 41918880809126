import { ListAttributesWithCounts } from "common.model/src/db/models/List";

export interface ListApiResponse {
	id: number;
	name: string;
	description: string;
	created_at?: string;
	num_broker_contacts: string;
	num_employer_contacts: string;
	created_by: string;
}

export enum ListType {
	Empty = 'Empty',
	Mixed = 'Mixed',
	Broker = 'Broker Contacts',
	Employer = 'Employer Contacts',
}

export class List {
	id: number;
	name: string;
	createdAt?: Date | null;
	brokerContacts: number;
	employerContacts: number;
	contacts: number;
	createdBy: string;

	constructor(data: ListAttributesWithCounts) {
		this.id = data.id;
		this.name = data.name;
		this.brokerContacts = Number(data.num_broker_contacts || 0);
		this.employerContacts = Number(data.num_employer_contacts || 0);
		this.contacts = this.brokerContacts + this.employerContacts;
		this.createdAt = data.created_at ? new Date(data.created_at) : null;
		this.createdBy = data.created_by;
	}

	get getListType() {
		if (this.contacts === 0) {
			return ListType.Empty;
		}
		if (this.brokerContacts > 0 && this.employerContacts > 0) {
			return ListType.Mixed;
		}
		if (this.brokerContacts > 0) {
			return ListType.Broker;
		}
		return ListType.Employer;
	}

	get getLocaleCreatedDate() {
		if (this.createdAt) {
			return this.createdAt.toLocaleDateString();
		}
		return '-';
	}

	get getCuratedName() {
		if (this.id < 0) {
			return 'All Contacts';
		}
		return this.name.replace(/\[|\]/g, '');
	}

	get getFormatedContacts() {
		return this.contacts.toLocaleString('es-US');
	}
}
