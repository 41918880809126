import { create } from 'zustand';
import { BrokerFilters, getEmptyBrokerFilters } from 'common.model/src/types/common/search/filters/CompanyFilters';

import { BrokersStore, Filters } from './Brokers.types';
import { StoreUtils } from '../../../../utilities';

export const FILTERS: Filters[] = [
	'brokerOfficeLocations',
	'participantRanges',
	'employerHQLocations',
	'entityTypes',
	'planTypes',
	'lineOfBusiness',
	'participantRanges',
	'renewalDatesRanges',
	'industries',
	'carrierName',
	'filterPrimaryBroker',
	'brokerSize',
];

export const useBrokersStore = create<BrokersStore>((set) => ({
	...StoreUtils.createFilterVisibilityMethods(FILTERS, set),
	filters: getEmptyBrokerFilters(),
	clearFilters: () =>
		set({
			filters: getEmptyBrokerFilters(),
		}),
	setFilter: {
		masterBrokerNameSearch: StoreUtils.createStringMethods('masterBrokerNameSearch', set),
		filterPrimaryBroker: StoreUtils.createCheckboxMethods('filterPrimaryBroker', set),
		brokerOfficeLocations: StoreUtils.createLocationMethods('brokerOfficeLocations', set),
		employerHQLocations: StoreUtils.createLocationMethods('employerHQLocations', set),
		carrierName: StoreUtils.createIncludeExcludeMethods<BrokerFilters, string>('carrierName', set),
		entityTypes: StoreUtils.createIncludeExcludeMethods<BrokerFilters, string>('entityTypes', set),
		industries: StoreUtils.createIncludeExcludeMethods<BrokerFilters, string>('industries', set),
		lineOfBusiness: StoreUtils.createIncludeExcludeMethods<BrokerFilters, string>('lineOfBusiness', set),
		participantRanges: StoreUtils.createRangeMethods('participantRanges', set),
		planTypes: StoreUtils.createIncludeExcludeMethods<BrokerFilters, string>('planTypes', set),
		renewalDatesRanges: StoreUtils.createRangeMethods('renewalDatesRanges', set),
		groupBy: StoreUtils.createStringMethods('groupBy', set),
		brokerSize: StoreUtils.createIncludeExcludeMethods<BrokerFilters, string>('brokerSize', set),
	},
}));
