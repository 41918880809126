import { useState } from 'react';

export const useDisclosure = (initialState = false) => {
	const [isOpen, setIsOpen] = useState(initialState);

	const onOpen = (callback?: VoidFunction) => {
		setIsOpen(true);
		if (callback) {
			callback();
		}
	};

	const onClose = (callback?: VoidFunction) => {
		setIsOpen(false);
		if (callback) {
			callback();
		}
	};

	const toggle = () => {
		setIsOpen((oldValue) => !oldValue);
	};

	return { isOpen, onOpen, onClose, toggle };
};
