import cn from 'classnames';
import { SalesforceSobjectAccountInstance } from 'common.model/src/db/models';
import { openLinkInTab } from 'new-beginning/utilities';
import { FaSalesforce } from 'react-icons/fa';

interface ExistingAccountParentAssignRowSetProps {
	salesforceAccounts: SalesforceSobjectAccountInstance[];
	salesforceInstanceUrl: string;
}

export const ExistingAccountParentAssignRowSet = ({
	salesforceAccounts,
	salesforceInstanceUrl,
}: ExistingAccountParentAssignRowSetProps) => {
	return (
		<>
			{salesforceAccounts?.length &&
				salesforceAccounts?.map((salesforceAccount) => (
					<div
						className={cn('affected-account-row', 'd-flex', 'align-items-center')}
						key={salesforceAccount?.salesforce_record_uid}
					>
						<div className={cn('d-flex', 'justify-content-between', 'align-items-center', 'px-3', 'flex-fill')}>
							<div className={cn('d-flex', 'flex-fill', 'align-items-center')}>
								<div className={cn('d-flex', 'align-items-center')}>
									<FaSalesforce
										className={cn('bf-icon', 'ic-action', 'ic-salesforce', 'me-2')}
										size={24}
										onClick={openLinkInTab(`${salesforceInstanceUrl}/${salesforceAccount.salesforce_record_uid}`)}
									/>
									<p className={cn('bf-txt', 'txt-dark', 'txt-bolder')}>
										{salesforceAccount?.Name || '[Account Missing Name]'}
									</p>
								</div>
							</div>
						</div>
					</div>
				))}
		</>
	);
};
