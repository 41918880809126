import { isContactPurchasable } from './isContactPurchasable';
import { isContactPurchased } from './isContactPurchased';
import { onBrokerClickCallback } from './onBrokerClickCallback';
import { onEmployerClickCallback } from './onEmployerClickCallback';

export const ContactUtils = {
	isContactPurchasable,
	isContactPurchased,
	onBrokerClickCallback,
	onEmployerClickCallback,
};
