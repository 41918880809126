import { OrderItem } from 'sequelize';

// Common Types for Model Pagination
export enum SortOrder {
	ASC = 'ASC',
	DESC = 'DESC',
}

export enum SortKey {
	id = 'id',
	contact_name = 'contact_name',
	created_at = 'created_at',
	account_name = 'account_name',
	contact_owner = 'contact_owner',
	// List Page
	ListName = 'name',
	ListType = 'type',
	ListContacts = 'contacts',
	ListCreatedBy = 'createdBy',
	ListCreatedAt = 'createdAt',
}

interface SortMapType {
	[key: string]: (sortOrder: SortOrder) => OrderItem;
}

export const ListItemSortMapping: SortMapType = {
	[SortKey.id]: (sortOrder) => ['list_item_id', sortOrder],
	[SortKey.contact_name]: (sortOrder) => ['ContactInfo', 'last_name', sortOrder],
	[SortKey.account_name]: (sortOrder) => ['ContactInfo', 'company_name', sortOrder],
	// NOTE: This ListItem field might not be accurate for CONTACT OWNER
	[SortKey.contact_owner]: (sortOrder) => ['created_by', sortOrder],
};

export const getSortParams = (sortKey: SortKey, sortOrder: SortOrder): OrderItem => {
	const sortParamGetter = ListItemSortMapping?.[sortKey];

	return sortParamGetter ? sortParamGetter(sortOrder) : null;
};

export interface LimitOffset {
	offset: number;
	limit: number;
}

export const defaultLimitOffset: LimitOffset = {
	offset: 0,
	limit: 50,
};

export interface PaginationParams extends LimitOffset {
	sortOrder?: SortOrder;
	sortKey?: SortKey;
	count?: number;
	numPages?: number;
}

export interface PaginationOptions extends PaginationParams {
	useDefaultSort: boolean;
}

export interface PaginatedResponse {
	count: number;
	numPages: number;
	limit: number;
	offset: number;
}
