import { Box, Icon, Skeleton, Typography } from '@benefitflow/designsystem';
import { TextOverflowTooltip } from 'new-beginning/components/shared/TextOverflowTooltip';
import { useState } from 'react';

export const Title = ({
	children,
	linkedinUrl,
	loading,
	website,
	onClick,
	testId = 'card-title',
}: {
	children: string;
	loading?: boolean;
	linkedinUrl?: string;
	website?: string;
	onClick?: () => void;
	testId?: string;
}) => {
	const [hovered, setHovered] = useState(false);
	if (loading) return <Skeleton height="sm" width="xl4" />;
	if (!children) return null;
	return (
		<TextOverflowTooltip label={children}>
			{(overflowCallback) => (
				<Box display="flex" gap="xxs" justifyContent="start" alignItems="center" overflow="hidden">
					<Typography.Text
						data-testid={testId}
						onClick={onClick}
						style={{ cursor: hovered && onClick ? 'pointer' : 'default' }}
						decoration={hovered && onClick ? 'underline' : undefined}
						onMouseEnter={() => setHovered(true)}
						onMouseLeave={() => setHovered(false)}
						ellipsis
						ellipsedCallback={overflowCallback}
						whiteSpace="nowrap"
						weight="bold"
						size="md"
					>
						{children}
					</Typography.Text>
					{linkedinUrl && (
						<a href={`https://${linkedinUrl}`} style={{ color: '#007EBB' }} target="_blank" rel="noopener noreferrer">
							<Icon name="linkedin" size="sm" />
						</a>
					)}
					{website && (
						<a href={`https://${website}`} target="_blank" rel="noopener noreferrer">
							<div style={{ rotate: '-45deg' }}>
								<Icon name="link" color="primary-500" size="md" />
							</div>
						</a>
					)}
				</Box>
			)}
		</TextOverflowTooltip>
	);
};
