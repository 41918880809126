import { useCallback } from 'react';
import { addNotification } from '@benefitflow/designsystem';
import { BaseContactFilters } from 'common.model/src/types/common/search/filters/ContactFilters';

import { ContactDTO } from '../../../../../../../queries';
import { Notifications } from '../../../../../../../constants/notifications';
import { getApiPayloadFromState } from '../../../../../../pages/Search/BrokerContacts/components/List/components/Header/helpers/getApiPayloadFromState';
import { UseMutation } from '../../GetSelectedButton.types';

export const useGetSelected = <FilterType extends BaseContactFilters>({
	closeConfirmationDialog,
	addPurchasedContact,
	checkMode,
	checkedMap,
	filters,
	useMutation,
}: {
	closeConfirmationDialog: () => void;
	addPurchasedContact: (...contact: Pick<ContactDTO, 'id' | 'email' | 'phone'>[]) => void;
	filters: FilterType;
	checkedMap: Record<string, boolean>;
	checkMode: 'single-select' | 'select-all';
	type: 'broker' | 'employer';
	listId?: string;
	useMutation: UseMutation<FilterType>;
}) => {
	const onSuccess = useCallback(
		(data) => {
			addPurchasedContact(...data.res.map(({ mobile_phone_1, profile_url, work_email }) => ({ email: work_email, phone: mobile_phone_1, id: profile_url })));
			addNotification(data?.consumption ? Notifications.ContactPurchase.Success(data.consumption) : Notifications.ContactPurchase.NoCharge);
			closeConfirmationDialog();
		},
		[addPurchasedContact, closeConfirmationDialog],
	);
	const onError = () => addNotification(Notifications.ContactPurchase.Error);
	const { mutate, isLoading } = useMutation({ onSuccess, onError });
	const getSelected = useCallback(() => {
		mutate({
			...getApiPayloadFromState({ checkedMap, checkMode }),
			filters,
		});
	}, [mutate, checkedMap, checkMode, filters]);

	return { getSelected, loading: isLoading };
};
