import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectListsDetailPage } from 'app/containers/ListsPage/containers/ListsDetailPage/selectors';
import { listsDetailPageActions } from 'app/containers/ListsPage/containers/ListsDetailPage/slice';

export const useOnDelete = () => {
	const dispatch = useDispatch();
	const { contactFilters } = useSelector(selectListsDetailPage);

	const onDelete = useCallback(
		({ type, value }: { value: string; type: 'include' | 'exclude' }) =>
			() => {
				dispatch(
					listsDetailPageActions.updateContactFilters({
						...contactFilters,
						roles: {
							include:
								type === 'include'
									? contactFilters.roles.include.filter((role) => role != value)
									: contactFilters.roles.include,

							exclude:
								type === 'exclude'
									? contactFilters.roles.exclude.filter((role) => role != value)
									: contactFilters.roles.exclude,
						},
					})
				);
			},
		[dispatch, contactFilters, listsDetailPageActions]
	);

	return onDelete;
};
