import { InputLabel, Select } from '@benefitflow/designsystem';
import { RADIUS_SEARCH_DEFAULT } from 'app/constants';

export const RadiusSearchSelect = ({ radius, onSelectRadius }) => {
	return (
		<InputLabel label="Search radius">
			<Select value={radius} onChange={(e) => onSelectRadius(e.target.value)}>
				<Select.Option value={RADIUS_SEARCH_DEFAULT}>Exact location</Select.Option>
				<Select.Option value={5}>5 miles</Select.Option>
				<Select.Option value={10}>10 miles</Select.Option>
				<Select.Option value={25}>25 miles</Select.Option>
				<Select.Option value={50}>50 miles</Select.Option>
				<Select.Option value={100}>100 miles</Select.Option>
			</Select>
		</InputLabel>
	);
};
