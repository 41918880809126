import { createContext } from 'react';
import { IEmployerApi } from './clients/EmployerApi';
import { IBrokerContactApi } from './clients/BrokerContactApi';
import { IBrokerApi } from './clients/BrokerApi';
import { IProviderApi } from './clients/ProviderApi';
import { IDataExportApi } from './clients/DataExportApi';
import { IListApi } from './clients/ListsApi';
import { ITenantApi } from './clients/TenantApi';
import { IUserApi } from './clients/UserApi';
import { ExportApi } from './clients/ExportApi';

export interface APIContextType {
	brokerApi: IBrokerApi;
	brokerContactApi: IBrokerContactApi;
	dataExportApi: IDataExportApi;
	employerApi: IEmployerApi;
	listsApi: IListApi;
	providerApi: IProviderApi;
	tenantApi: ITenantApi;
	userApi: IUserApi;
	exportApi: ExportApi;
}

export const APIContext = createContext<APIContextType>({} as APIContextType);
