import Skeleton, { SkeletonProps } from '@material-ui/lab/Skeleton';
import { PropsWithChildren } from 'react';

interface SkeletonLoaderProps extends PropsWithChildren {
	loading: boolean;
	loaderProps: SkeletonProps;
}

export const SkeletonLoader = ({ loading, loaderProps, children }: SkeletonLoaderProps) => {
	const { animation = 'wave', variant = 'rect', className, width = 200, height = 25 } = loaderProps;
	return loading ? <Skeleton animation={animation} variant={variant} className={className} width={width} height={height} /> : children;
};
