import { useCallback, useEffect, useState } from 'react';
import { create } from 'zustand';

const DEFAULT_LARGE_SCREEN = 1000;
const DEFAULT_SMALL_SCREEN = 600;
type ScreenSize = 'sm' | 'md' | 'lg';

const useScreenSizeStore = create<{ screenSize: ScreenSize; setScreenSize: (screenSize: ScreenSize) => void }>((set) => ({
	screenSize: 'lg',
	setScreenSize: (screenSize) => set({ screenSize }),
}));

export const useScreenSize = () => {
	const { screenSize } = useScreenSizeStore();

	return screenSize;
};

export const useSetScreenSize = () => {
	const [width, setWidth] = useState(window.innerWidth);
	const { setScreenSize } = useScreenSizeStore();

	useEffect(() => {
		if (width >= DEFAULT_LARGE_SCREEN) {
			setScreenSize('lg');
		} else if (width <= DEFAULT_SMALL_SCREEN) {
			setScreenSize('sm');
		} else {
			setScreenSize('md');
		}
	}, [width, setScreenSize]);

	const handleResize = useCallback(() => {
		setWidth(window.innerWidth);
	}, [setWidth]);

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [handleResize]);
};
