import serviceUrl from 'utils/serviceUrl';
import axios from 'axios';
import { NewUser } from 'new-beginning/components/pages/Team/InviteUserModal';
import { useBasicApiRequestState, BasicApiRquestState, BaseApiReponse } from './useBasicApiRequestState';

export interface InviteUserResponse extends BaseApiReponse {
    addUserToTeam: (user: NewUser) => Promise<void>;
}

const addUserUrl: string = `${serviceUrl}/user/createUser`;

export const useInviteUser = (): InviteUserResponse => {
    const {
        baseHeaders,
        loading, setLoading,
        requestApiSuccess, setApiRequestSuccess,
        requestErrorMessage, setRequestErrorMessage,
    }: BasicApiRquestState = useBasicApiRequestState();

    const addUserToTeam = (user: NewUser) => {
        if (!user) {
            console.error("User required to Execute Request.");
        }
        setLoading(true);
        setRequestErrorMessage(null);
        setApiRequestSuccess(null);
        const {fullName, email, permissionLevel} = user;

        return axios.post(
            addUserUrl,
            {
                fullName,
                email,
                isAdmin: permissionLevel === "admin",
                isTrial: permissionLevel === "trial"
            },
            { headers: {...baseHeaders} },
        ).then((res) => {
            setApiRequestSuccess(true);
        }).catch((err: Error) => {
            setRequestErrorMessage(err.message);
            setApiRequestSuccess(false);
        }).finally(() => {
            setLoading(false);
        });
    };

    return {
        loading,
        requestErrorMessage,
        requestApiSuccess,
        addUserToTeam,
    };
};
