import { useMemo } from 'react';

import { getTagLabel } from 'new-beginning/components/shared/SidebarFilters/components/OfficeLocation/helpers';
import { LocationFilterProps } from '../LocationFilter';

export const useValues = ({ selectedOptions }: Pick<LocationFilterProps, 'selectedOptions'>) => {
	const values = useMemo(
		() => [
			...(selectedOptions.include.map((loc) => {
				const label = getTagLabel(loc);
				return { label, value: loc.location.searchValue, type: 'include' as const };
			}) ?? []),
			...(selectedOptions.exclude.map((loc) => {
				const label = getTagLabel(loc);
				return { label, value: loc.location.searchValue, type: 'exclude' as const };
			}) ?? []),
		],
		[selectedOptions],
	);

	return values;
};
