import { Skeleton, Typography } from '@benefitflow/designsystem';
import { TextProps } from '@benefitflow/designsystem/dist/components/Typography/components/Text/Text.types';
import { TextOverflowTooltip } from '../../TextOverflowTooltip';

export const Label = ({ loading, ...props }: { loading?: boolean } & TextProps) => {
	if (loading) return <Skeleton height="sm" width="xl4" />;
	if (!props.children) return null;
	return (
		<TextOverflowTooltip label={props.children}>
			{(overflowCallback) => <Typography.Text whiteSpace="nowrap" ellipsis ellipsedCallback={overflowCallback} {...props} />}
		</TextOverflowTooltip>
	);
};
